// core
import React from 'react';
import { Link } from 'react-router-dom';

// settings + utils
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';

import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';

// lib
import moment from 'moment';
import { DataTable, Column } from 'primereact/datatable';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova/theme.css';
import 'primeicons/primeicons.css';

// shared
import BaseListPage from '../../pages/lib/BaseListPage';
import Spinner from '../../components/lib/thss/Spinner';

import ButtonsToolbar from '../../components/shared/ButtonsToolbar';
import FiltersToolbar from '../../components/shared/filters/FiltersToolbar';
import MainHeading from '../../components/shared/MainHeading';
import FilterRestoreNotice from '../../components/shared/filters/FilterRestoreNotice';
import ListPageWrapper from '../../components/shared/ListPageWrapper';

// page-specific/etc.
import DataGrid from '../../components/lib/react-data-grid/lib/DataGrid';
import '../../components/lib/react-data-grid/dist/react-data-grid.css';
import NoRecordsMessage from '../../components/shared/NoRecordsMessage';

import StatesFilter from '../../components/shared/filters/StatesFilter';
import ProductTypesFilter from '../../components/shared/filters/ProductTypesFilter';

class AgenciesListPage extends BaseListPage {
  constructor(props) {
    super(props);

    this.FILTERS_PRESELECT_ALL = false;

    this.DEFAULT_SORT_BY = 'id';
    this.DEFAULT_SORT_DIR = 'asc';

    this.state = {
      ...this.state
    };
  }

  __init = (props, callback) => {
    // set backup key for filters
    this.__setFiltersBackupKey('agencies-overview');

    // define defaults
    this.__setPageSize(this.DEFAULT_PAGINATION_SIZE);
    this.__setCurrPage(this.DEFAULT_PAGINATION_START);
    this.__setSortBy(this.DEFAULT_SORT_BY);
    this.__setSortDir(this.DEFAULT_SORT_DIR);

    this.__setStatus(this.STATUS_FETCHING);

    window.setTimeout(() => {// TODO: remove timeout hack to make sort by work in agency list
      this.fetchAgencies()
      .then(() => {
        if (this.FILTERS_PRESELECT_ALL) {
          this.__setFilters(this.props.appData.states.map(state => state.code), false, 'state_codes');
          this.__setFilters(this.props.appData.productTypes.map(type => type.id), false, 'product_types');
        }

        this.__setStatus(this.STATUS_READY);

        if (callback) callback();
      })
      .catch((error) => {
        this.__setStatus(this.STATUS_ERROR);
        this.__setErrors([...this.__getErrors(), error]);

        if (callback) callback();
      });
    }, 100);
  }

  fetchAgencies = () => {
    return new Promise((resolve, reject) => {
      this.__fetch('GET', Settings.API_URL + '/api/v1/agencies', {
        state_codes: this.__getFilters('state_codes') ? this.__getFilters('state_codes').join(',') : '',
        product_types: this.__getFilters('product_types') ? this.__getFilters('product_types').join(',') : '',
        q: this.__getSearchKey() ? this.__getSearchKey() : '',
        offset: this.__getOffset() !== undefined ? this.__getOffset() : this.DEFAULT_OFFSET,
        limit: this.__getLimit() !== undefined ? this.__getLimit() : this.DEFAULT_LIMIT,
        sort_by: this.__getSortBy() !== undefined ? this.__getSortBy() : this.DEFAULT_SORT_BY,
        sort_dir: this.__getSortDir() || this.DEFAULT_SORT_DIR
      }, {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken()
        }
      })
        .then((response) => {
          // save items
          this.__setTotalCount(response.total_count);
          this.__setPageData(response.data.agencies, 'agencies', () => {
            resolve();
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  __refreshData = () => {
    this.__abortAll();

    this.__setStatus(this.STATUS_FETCHING);

    this.fetchAgencies()
      .then(this.__setStatus(this.STATUS_READY))
  }

  render() {
    const items = this.__getPageData('agencies');

    return (
      <>
        <ListPageWrapper
          header={
            <MainHeading
              icon={Settings.icons.objectTypes.agency}
              title="Applications"
              descr=""
            >
              {/* <ButtonsToolbar
                related={[
                  {
                    label: 'New application',
                    href: '/bonds/find/',
                    type: 'add'
                  }
                ]}
              /> */}
            </MainHeading>
          }
          status={{
            fetching: this.__getStatus() == this.STATUS_FETCHING,
            errors: this.__getErrors()
          }}
          searchKey={this.__getSearchKey()}
          search={{
            label: 'Search results:',
            placeholder: '',

            onChange: (searchKey) => this.__handleSearchChange(searchKey),
            onSubmit: (searchKey) => this.__handleSearchChange(searchKey),
          }}
          filters={[
            // <StatesFilter
            //   label="Filter by state:"
            //   allLabel="All states"
            //   states={this.props.appData.states}
            //   selected={this.__getFilters('state_codes')}

            //   onChange={(states) => this.__handleFilterChange(states, 'state_codes')}
            // />,
            // <ProductTypesFilter
            //   label="Filter by product type:"
            //   allLabel="All types"
            //   types={this.props.appData.productTypes}
            //   selected={this.__getFilters('product_types')}

            //   onChange={(product_types) => this.__handleFilterChange(product_types, 'product_types')}
            // />,
          ]}
          pagination={{
            totalCount: this.__getTotalCount(),
            paginationSize: this.__getPageSize(),
            currPage: this.__getCurrPage(),

            onPageNext: this.__handlePageNext,
            onPagePrev: this.__handlePagePrev,
            onPageSizeChange: this.__handlePageSizeChange,
          }}
          data={{
            onExport: null
          }}
        >
          {
            this.__getStatus() == this.STATUS_ERROR && (
              <>
                <Alert variant="danger">
                  <h2>Sorry, an error occurred</h2>
                  <p>{this.state.error}</p>
                </Alert>
              </>
            )
          }
          {
            items && items.length ? (
              <Panel noPadding>
                <DataGrid
                  minColumnWidth={0}
                  rowHeight={30}

                  columns={[
                    { key: 'id', name: 'ID', width: 55, sortable: true},
                    { key: 'agency_name', name: 'Name', sortable: true },
                    { key: 'agency_email', name: 'Email', sortable: true },
                    { key: 'mga_name', name: 'MGA', sortable: true},
                    // { key: 'num_orders', name: '# orders', width: 80, sortable: true},
                    { key: 'actions', name: '', width: 100, sortable: false}
                  ]}
                  rows={items.map((row) => {
                    row.actions = (
                      <div className="text-center">
                        <Link to={'/agencies/' + row.id + '//'}><Button variant="light"><i className="fa fa-eye mr-2" />view</Button></Link>
                      </div>
                    );

                    return row;
                  })}

                  headerRowHeight={40}
                  headerFiltersHeight={40}
                  rowHeight={40}
                  height={items.length * 40 + 45 + 2}

                  enableCellAutoFocus={false}

                  onRowClick={null}
                  sortColumn={this.__getSortBy()}
                  sortDirection={this.__getSortDir().toUpperCase()}
                  onSort={this.__handleSort}
                />
              </Panel>
            ) : <NoRecordsMessage />
          }
        </ListPageWrapper>
      </>
    );
  }
}

export default AgenciesListPage;