import React from 'react';
import { Link } from 'react-router-dom';

import Sitemap from '../../../components/shared/Sitemap.js';

class PageListPanel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  toggle = () => {
    this.setState({
      open: !this.state.open
    });
  }
  render() {
    return (
      <>
        <p style={{position: 'fixed', width: '1em', height: '1em', bottom: '15px', left: '13px', zIndex: 2147483647}} >
          <a href="javascript:void(0);" className="btn btn-outline text-dark" onClick={this.toggle}><i className={'fas ' + (this.state.open ? 'fa-times' : 'fa-plus')} /></a>
        </p>
        <div className="gig-admin-coming-soon gig-admin-coming-soon--full-opacity" style={{minWidth: this.state.open ? '325px' : 0, display: 'block', padding: '10px', position: 'fixed', bottom: '35px', left: '20px', zIndex: 2147483640}}>
          <div style={{backgroundColor: '#fff', padding: '3px 10px', display: this.state.open ? 'block' : 'none'}}>
            <div style={{zoom: 0.8}}>
              <p className="m-0 p-0 mb-2"><strong style={{fontSize: '150%'}}><i className="fad fa-map-signs mr-1" />Pages</strong></p>
              <div onClick={(e) => this.setState({open: false})}>
                <Sitemap />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PageListPanel;