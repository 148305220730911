import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

class PanelHeaderWrapper extends React.Component {
  render() {
    return (
      <div className={`p-0 m-0 card-header gig-admin-main-panel-header ${this.props.sticky ? 'gig-admin-sticky-panel-header' : ''}`}>
        {this.props.children}
      </div>
    );
  }
}

export default PanelHeaderWrapper;