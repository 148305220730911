import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import ObjectTypeDataViewer from './ObjectTypeDataViewer';

class ObjectTypePanelPlaceholder extends React.Component {
  render() {
    return (
      <>
        {
          this.props.ready ? (
            <>
              {this.props.children}
            </>
          ) : (
            <div className="thssjsf-placeholder">
              <ObjectTypeDataViewer
                schema={this.props.schema}
                uiSchema={{}}
                layout={this.props.layout}

                data={null}
              />
            </div>
          )
        }
      </>
    );
  }
}

export default ObjectTypePanelPlaceholder;