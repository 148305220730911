import React from 'react';

import { Container, Row, Col, Button, Alert, Badge, Tooltip } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';
import ObjectTypePanel from '../../components/lib/thss/json-schema-form/ObjectTypePanel';

import ProfilePicture from '../../components/shared/elems/ProfilePicture';

export default class AddBondNoteForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      note: ''
    };
  }

  handleChange = (e) => {
    const { typeName } = this.props;
    const note = e.currentTarget.value;

    this.setState({
      note
    }, () => {
      if (!this.props.onChange) {
        console.warn('WARNING: no onChange prop for note form (will dump props)', this.props);

        return;
      }

      this.props.onChange({note: this.state.note}, typeName);
    });
  }

  handleSubmit = () => {
    const { typeName } = this.props;

    if (!this.props.onSubmit) {
      console.warn('WARNING: no onSubmit prop for note form (will dump props)', this.props);

      return;
    }

    this.props.onSubmit(this.state.note, typeName);

    this.setState({
      note: ''
    });
  }

  render() {
    const { user } = this.props;

    return (
      <>
        <hr />

        <div><textarea rows="4" className="form-control" value={this.state.note} onChange={this.handleChange} /></div>

        {
          user && (
            <div style={{height: '2em', marginTop: '0.5em'}}>
              By: <span><ProfilePicture style={{height: '1em', width: '1em'}} username={user.username} /> {user.username}</span>
            </div>
          )
        }

        <div className="mt-2">
          <Button variant="primary" onClick={this.handleSubmit}><i className="fad fa-save mr-1" />Add note</Button>
        </div>
      </>
    );
  }
}