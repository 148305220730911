// core
import React from 'react';
import { Link } from 'react-router-dom';

// settings + utils
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';

import { Container, Row, Col, Button, Alert, Modal } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';
import NumberFormat from 'react-number-format';

// page-specific/etc.
import DataGrid from '../../components/lib/react-data-grid/lib/DataGrid';
import '../../components/lib/react-data-grid/dist/react-data-grid.css';
import PortalModal from '../shared/elems/PortalModal.js';

class ProductListDatagrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currPreview: ''
    };
  }

  __handleSort = (sortBy, sortDir) => {
    if (this.props.onSort) this.props.onSort(sortBy, sortDir);
  }

  handlePreviewOpen = (url) => {
    this.setState({
      currPreviewURL: url
    });
  }
  handlePreviewClose = () => {
    this.setState({
      currPreviewURL: ''
    });
  }

  render() {
    const { rows, sortBy, sortDir, licensedStates, productTypesById } = this.props;

    return (
      <Panel noPadding>

        {
          this.state.currPreviewURL && (
          <PortalModal
            localStorageKey="sbm:BondProductPreview"
            onClose={() => this.handlePreviewClose()}
          >
            <div style={{overflow: 'auto', maxHeight: '100%'}}>
              <img src={this.state.currPreviewURL} style={{width: '100%', height: 'auto'}} />
            </div> 
          </PortalModal>
          )
        }

        <DataGrid
          minColumnWidth={0}
          rowHeight={30}

          columns={[
            { key: 'state', name: 'State', width: 55, sortable: false },
            { key: 'name', name: 'Bond name', sortable: false },
            { key: 'obligee_name', name: 'Obligee', width: 280, sortable: false },
            { key: 'bond_amount_descr', name: 'Bond amount', width: 150, sortable: false},
            { key: 'preview_button', name: '', width: 110, sortable: false},
            { key: 'apply_button', name: '', width: 90, sortable: false}
          ]}
          rows={rows.map((row) => {
            const productType = productTypesById[row.product_type_id] || null;

            return {
              state: row.state_code,
              name: row.name,
              obligee_name: row.obligee_name || '-',
              bond_amount_descr: row.bond_amount_descr,
              product_type_name: <div className="text-left">{productType && productType.name}</div>,
              preview_button: (
                <div className="text-center">
                  <Button variant="light" onClick={e => this.handlePreviewOpen(row.bond_preview_url)}><i className="fad fa-eye mr-2" />Preview</Button>
                </div>
              ),
              apply_button: (
                <div className="text-center">
                  {
                    licensedStates.indexOf(row.state_code) !== -1 ? (
                      <Link to={`/bonds/${row.id}/quotes/-/new/`}><Button variant="light">Apply<i className="fa fa-arrow-right ml-1" /></Button></Link>
                    ) : (
                      <span className="text-light"><i className="fad fa-ban mr-1" />Not licensed</span>
                    )
                  }
                </div>
              )
            };
          })}

          headerRowHeight={40}
          headerFiltersHeight={40}
          rowHeight={40}
          height={rows.length * 40 + 45 + 2}

          enableCellAutoFocus={false}

          onRowClick={null}
          sortColumn={sortBy}
          sortDirection={sortDir}
          onSort={this.__handleSort}
        />
      </Panel>
    );
  }
}

export default ProductListDatagrid;