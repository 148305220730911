// core
import React from 'react';
import { Link } from 'react-router-dom'

// settings + utils
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';
import pluralize from 'pluralize';

import { Container, Row, Col, Button, Alert, Badge } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';

// lib
import BasePage from '../../pages/lib/BasePage';
import Spinner from '../../components/lib/thss/Spinner';

// shared
import PageHeaderWrapper from '../../components/shared/layout/PageHeaderWrapper';
import PanelHeaderWrapper from '../../components/shared/layout/PanelHeaderWrapper';
import PageContentWrapper from '../../components/shared/layout/PageContentWrapper';
import ButtonsToolbar from '../../components/shared/ButtonsToolbar';
import ValignMiddle from '../../components/lib/thss/ValignMiddle';
import FiltersToolbar from '../../components/shared/filters/FiltersToolbar';
import SlideDownTransition from '../../components/lib/thss/SlideDownTransition';
import MainHeading from '../../components/shared/MainHeading';
import ObjectTypePanel from '../../components/lib/thss/json-schema-form/ObjectTypePanel';
import FilterRestoreNotice from '../../components/shared/filters/FilterRestoreNotice';
import ListPageWrapper from '../../components/shared/ListPageWrapper';

// page-specific/etc.
import DataGrid from '../../components/lib/react-data-grid/lib/DataGrid';
import '../../components/lib/react-data-grid/dist/react-data-grid.css';

class BaseDetailsPage extends BasePage {
  constructor(props) {
    super(props);

    this.PAGE_MODE_VIEW = 'view';
    this.PAGE_MODE_NEW = 'new';
    this.PAGE_MODE_EDIT = 'edit';
    this.PAGE_MODE_DELETE = 'delete';

    this.state = {
      ...this.state,

      // data
      _formDataByType: {
        [this.DEFAULT_KEY]: null
      },

      focusedItemTypeName: '',
      focusedItemId: null
    };
  }

  __init = (props, callback) => {
    alert('implement INIT()!');

    if (callback) callback();
  }

  __fetchAll = (id, callback) => {
    alert('implement __fetchAll()!');

    if (callback) callback();
  }

  // figure out which item we're editing
  __handleToggleEdit = (typeName, id) => {
    this.setState({
      focusedItemTypeName: typeName,
      focusedItemId: id
    });
  }
  __getFocusedItemTypeName = () => {
    return this.state.focusedItemTypeName;
  }
  __getFocusedItemId = () => {
    return this.state.focusedItemId
  }
  __isItemFocused(typeName='', refId='') {
    let focused = this.__getFocusedItemTypeName() != '';

    if (typeName) focused = focused && this.state.focusedItemTypeName === typeName;
    if (refId) focused = focused && this.__getFocusedItemId() === refId;

    return focused;
  }

  __setFormData = (formData, typeName, isArray=false, arrayIndex=0, arrayUnset=false, callback) => {

    // store form data
    let finalData = null;

    if (formData !== undefined && formData !== null) {
      if (isArray) {
        // if saving to array, get curr values first, then replace using index index
        finalData = this.__getFormData(typeName) ? this.__getFormData(typeName) : [];
        finalData[arrayIndex] = formData;

        // if unset, remove item
        if (arrayUnset) {
          finalData.splice(arrayIndex, 1);
        }
      } else {
        // otherwise, just save form data
        finalData = formData;
      }
      finalData = JSON.parse(JSON.stringify(finalData));
    }

    this.setState((prevState) => {
      prevState._formDataByType[typeName] = finalData;

      return prevState;
    }, () => {
      if (callback) {
        callback();
      }
    });
  }

  __getFormData = (typeName, isArray=false, arrayIndex=0) => {
    if (!this.state._formDataByType) {
      return null;
    }

    let formData = null;

    if (isArray) {
      formData = this.state._formDataByType && this.state._formDataByType[typeName] ? this.state._formDataByType[typeName][arrayIndex] : null;
    } else {
      formData = this.state._formDataByType[typeName];
    }

    return formData;
  }

  // fetches items for a given object type—including items for all related types—and saves in page data with the type name as key
  __fetchObjectTypeItems = (typeName, id, callback, relatedTypesToIgnore) => {
    const done = () => {
      if (callback) callback();
    }

    const typeData = this.__getTypeDataByName(typeName);
    if (!typeData) {
      throw Error('unknown type');
    }

    const defaultHeaders = {
      'Authorization': 'Bearer ' + Auth.getToken()
    };
    const defaultOptions = {
      headers: defaultHeaders
    };

    if (!typeData.endpoints || !typeData.endpoints.show) {
      throw Error(`No "show" endpoint data for type ${typeName}`);
    }

    const endpointData = typeData.endpoints.show;
    const endpoint = endpointData.url.replace('{id}', id);
    if (!endpoint) {
      done();

      return;
    }

    this.__fetch('GET', endpoint, {}, {
      headers: defaultHeaders
    }, (response, error) => {
      if (error || !response || !response.ok) {
        this.__setStatus(this.STATUS_ERROR);
        this.__setErrors([...this.__getErrors(), {
          message: response ? response.error : error
        }]);

        return;
      }

      // save main object
      const data = response.data[endpointData.data_key];
      this.__setPageData(data, typeData.name);
      this.__setFormData(data, typeData.name);

      // get data for related objects
      let calls = [];
      let relatedTypes = typeData.related || [];
      if (relatedTypesToIgnore && relatedTypesToIgnore.length) {
        relatedTypes = relatedTypes.filter(relatedType => !relatedTypesToIgnore.includes(relatedType.type_name));
      }

      relatedTypes.forEach((options) => {
        const typeName = options.type_name;

        if (!options.endpoints) {
          throw Error('no endpoints for type ' + typeName);
        }

        const endpointData = options.has_many ? options.endpoints.list : options.endpoints.show;
        if (endpointData === undefined) {
          throw Error('no endpoint data for type ' + typeName);
        }
        const endpoint = endpointData.url.replace('{refId}', id);
        if (endpoint) {
          calls.push({
            method: 'GET',
            endpoint: endpoint,
            payload: {},
            options: {...defaultOptions},
            callback: (response, error) => {
              if (error || !response || !response.ok) {
                this.__setStatus(this.STATUS_ERROR);
                this.__setErrors([...this.__getErrors(), {
                  message: response ? response.error : error
                }]);

                return;
              }

              const data = response.data[endpointData.data_key];
              this.__setPageData(data, typeName);
              this.__setFormData(data, typeName);
            }
          });
        }
      });

      if (calls.length) {
        this.__fetchMultiple(calls, (responses, errors) => {
          done();
        });
      } else {
        done();
      }
    });
  }

  // saves a given type/subtype
  __handleSave = (typeName, id=null, parentType=null, refId=null, callback=null) => {
    if (this.__getStatus() !== this.STATUS_READY) {
      console.warn('WARNING: status is not "STATUS_READY", will not continue saving');
    }

    // reset status
    this.__setStatus(this.STATUS_BUSY);
    this.__setErrors([]);

    // get type info
    const typeData = this.__getTypeDataByName(typeName, parentType);
    if (!typeData) {
      throw Error('Cannot get type while trying to save');
    }

    // figure out endpoint, using ID to see if it's update or new
    let formData = null;
    let method = '';
    let endpoint = '';
    let payload = null;

    if (!id) {
      method = 'POST';
      endpoint = typeData.endpoints.list && typeData.endpoints.list.url;
      if (typeData.endpoints.create) { // override, but not all object types handle this correctly in the config
        endpoint = typeData.endpoints.create.url;
      }
    } else {
      method = 'PUT';
      endpoint = typeData.endpoints.update && typeData.endpoints.update.url;
    }
    if (!endpoint) {
      throw Error('no endpoint');
    }
    endpoint = endpoint.replace('{id}', id).replace('{refId}', refId);

    formData = Array.isArray(this.__getFormData(typeName)) ? this.__getFormData(typeName).find(item => item.id == id) : this.__getFormData(typeName);
    if (!formData) {
      throw Error('unable to get form data for item: ' + typeName);
    }

    payload = JSON.stringify(formData);

    this.__fetch(method, endpoint, payload, {
      headers: {
        'Authorization': 'Bearer ' + Auth.getToken()
      }
    }, (response, error) => {
      if (error || !response || !response.ok) {
        console.error('ERROR while saving in UDP', error, response);

        this.__setStatus(this.STATUS_ERROR);
        this.__setErrors([...this.__getErrors(), {
          message: response ? response.error : error
        }]);

        window.setTimeout(() => {
          this.__setStatus(this.STATUS_READY);
        }, 1500);

        return;
      }

      // get all data again, and reset
      this.__setStatus(this.STATUS_SUCCESS);
      const { id } = this.__getParams();
      this.__fetchAll(id, () => {
        this.__handleToggleEdit('', '');
        this.__setStatus(this.STATUS_READY);
        if (callback) callback();
      });
    });
  }

  __handleObjectTypeItemSave = (typeName, id, parentType=null, refId=null) => {
    this.__handleSave(typeName, id, parentType, refId, () => {

    });
  }
}

export default BaseDetailsPage;