// core
import React from 'react';

// settings + utils
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';
import XHR from '../../utils/XHR';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from '../../components/lib/thss/Panel';

import BasePage from './BasePage';

class BaseListPage extends BasePage {
  constructor(props) {
    super(props);

    this._filters_backup_key = '';

    this.DEFAULT_KEY = '__DEFAULT__';
    this.DEFAULT_SORT_BY = 'id';
    this.DEFAULT_SORT_DIR = 'asc';
    this.DEFAULT_OFFSET = 0;
    this.DEFAULT_LIMIT = 50;
    this.DEFAULT_PAGINATION_START = 1;
    this.DEFAULT_PAGINATION_SIZE = 50;

    this.SEARCH_KEY_CACHE_KEY = '__SEARCH_KEY_CACHE_KEY';
    this.SORT_BY_CACHE_KEY = '__SORT_BY_CACHE_KEY';
    this.SORT_DIR_CACHE_KEY = '__SORT_DIR_CACHE_KEY';
    this.PAGINATION_SIZE_CACHE_KEY = '__PAGINATION_SIZE_CACHE_KEY';
    this.PAGINATION_PAGE_CACHE_KEY = '__PAGINATION_PAGE_CACHE_KEY';


    this.state = {
      ...this.state,

      // searching
      _searchKey: {
        [this.DEFAULT_KEY]: ''
      },

      // filters
      _filters: {
        [this.DEFAULT_KEY]: {}
      },

      // sorting
      _sortBy: {
        [this.DEFAULT_KEY]: 'id'
      },
      _sortDir: {
        [this.DEFAULT_KEY]: 'asc'
      },

      // pagination
      _totalCount: {
        [this.DEFAULT_KEY]: 0
      },
      _paginationSize: {
        [this.DEFAULT_KEY]: 100
      },
      _currPage: {
        [this.DEFAULT_KEY]: 1
      },
    };
  }

  __init = (props, callback) => {
    if (callback) callback();
  }

  __setFiltersBackupKey = (key) => {
    this._filters_backup_key = 'filters-hiu890-fpo-' + key;
  }
  __getFiltersBackupKey = () => {
    return this._filters_backup_key;
  }

  __setFilters = (data, saveFilters=true, key=this.DEFAULT_KEY, callback) => {
    this.setState((prevState) => {
      prevState._filters[key] = data;

      return prevState;
    }, () => {
      if (saveFilters) {
        // save for later
        const k = this.__getFiltersBackupKey();
        window.ldb.get(k, (filtersJSON) => {
          let filters = filtersJSON ? JSON.parse(filtersJSON) : {};
          filters[key] = data;

          window.ldb.set(k, JSON.stringify(filters));
        });
      }

      if (callback) callback();
    });
  }
  __getFilters = (key=this.DEFAULT_KEY) => {
    return this.state._filters[key];
  }

  __setSearchKey = (searchKey, key=this.DEFAULT_KEY, callback) => {
    this.setState((prevState) => {
      prevState._searchKey[key] = searchKey;

      return prevState;
    }, () => {
      // this.__setFilters(searchKey, true, this.__getFiltersBackupKey() + this.SEARCH_KEY_CACHE_KEY);

      if (callback) callback();
    });
  }
  __getSearchKey = (key=this.DEFAULT_KEY) => {
    return this.state._searchKey[key];
  }

  __setSortBy = (sortBy, key=this.DEFAULT_KEY, callback) => {
    this.setState((prevState) => {
      prevState._sortBy[key] = sortBy;

      return prevState;
    }, () => {
      this.__setFilters(sortBy, true, this.__getFiltersBackupKey() + this.SORT_BY_CACHE_KEY);

      if (callback) callback();
    });
  }
  __getSortBy = (key=this.DEFAULT_KEY) => {
    return this.state._sortBy[key];
  }
  __setSortDir = (sortDir, key=this.DEFAULT_KEY, callback) => {
    this.setState((prevState) => {
      prevState._sortDir[key] = sortDir;

      return prevState;
    }, () => {
      this.__setFilters(sortDir, true, this.__getFiltersBackupKey() + this.SORT_DIR_CACHE_KEY);

      if (callback) callback();
    });
  }
  __getSortDir = (key=this.DEFAULT_KEY) => {
    return this.state._sortDir[key];
  }

  // pagination
  __setTotalCount = (totalCount, key=this.DEFAULT_KEY, callback) => {
    this.setState((prevState) => {
      prevState._totalCount[key] = totalCount;

      return prevState;
    }, () => {
      if (callback) callback();
    });
  }
  __getTotalCount = (key=this.DEFAULT_KEY) => {
    return this.state._totalCount[key];
  }
  __setPageSize = (pageSize, key=this.DEFAULT_KEY, callback) => {
    this.setState((prevState) => {
      prevState._paginationSize[key] = pageSize;

      return prevState;
    }, () => {
      this.__setFilters(pageSize, true, this.__getFiltersBackupKey() + this.PAGINATION_SIZE_CACHE_KEY);

      if (callback) callback();
    });
  }
  __getPageSize = (key=this.DEFAULT_KEY) => {
    return this.state._paginationSize[key];
  }
  __setCurrPage= (currPage, key=this.DEFAULT_KEY, callback) => {
    this.setState((prevState) => {
      prevState._currPage[key] = currPage;

      return prevState;
    }, () => {
      this.__setFilters(currPage, true, this.__getFiltersBackupKey() + this.PAGINATION_PAGE_CACHE_KEY);

      if (callback) callback();
    });
  }
  __getCurrPage= (key=this.DEFAULT_KEY) => {
    return this.state._currPage[key];
  }
  __getOffset = (key=this.DEFAULT_KEY) => {
    return this.__getCurrPage(key) !== undefined ? (this.__getCurrPage(key) - 1) * this.__getLimit(key) : 0;
  }
  __getLimit = (key=this.DEFAULT_KEY) => {
    return this.__getPageSize(key) !== undefined ? this.__getPageSize(key) : this.DEFAULT_LIMIT;
  }

  __handlePagePrev = () => {
    const nextPage = this.__getCurrPage(this.DEFAULT_KEY) == 1 ? 1 : this.__getCurrPage(this.DEFAULT_KEY) - 1;

    this.__setCurrPage(nextPage, this.DEFAULT_KEY, () => {
      this.__refreshData();
    });
  }
  __handlePageNext = () => {
    const nextPage = this.__getCurrPage(this.DEFAULT_KEY) + 1;

    this.__setCurrPage(nextPage, this.DEFAULT_KEY, () => {
      this.__refreshData();
    });
  }
  __handlePageSizeChange = (e, nextSizeOverride='') => {
    const nextSize = nextSizeOverride || e.currentTarget.value;

    this.__setPageSize(nextSize, this.DEFAULT_KEY, () => {
      this.__setCurrPage(1, this.DEFAULT_KEY, () => {
        this.__refreshData();
      });
    });
  }

  __handleFilterChange = (values, key, save=false) => {
    return this.__setFilters(values, save, key, () => {
      this.__setCurrPage(1, this.DEFAULT_KEY, () => {
        this.__refreshData();
      });
    });
  }

  __handleSearchChange = (searchKey) => {
    this.__setSearchKey(searchKey, this.DEFAULT_KEY, () => {
      this.__setCurrPage(1, this.DEFAULT_KEY, () => {
        this.__refreshData();
      });
    });
  }

  __handleSort = (sortBy, sortDir) => {
    if (sortDir == 'NONE') sortDir = 'asc'; // this is for react-datagrid

    this.__setSortBy(sortBy, this.DEFAULT_KEY, () => {
      this.__setSortDir(sortDir, this.DEFAULT_KEY, this.__refreshData);
    });
  }

  __restorePageOptions = () => {
    const filtersKey = this.__getFiltersBackupKey();
    window.ldb.get(filtersKey, (filtersJSON) => {
      let filters = filtersJSON ? JSON.parse(filtersJSON) : {};
      console.dir(filters);
      if (!filters) {
        return;
      }

      Object.keys(filters).forEach((filterKey) => {
        const filterData = filters[filterKey];

        switch (filterKey) {
          case this.__getFiltersBackupKey() + this.SEARCH_KEY_CACHE_KEY:
            break;
          case this.__getFiltersBackupKey() + this.SORT_BY_CACHE_KEY:
            const sortDir = filters[this.__getFiltersBackupKey() + this.SORT_DIR_CACHE_KEY] || 'asc';
            this.__handleSort(filterKey, sortDir);
            break;
          // case this.__getFiltersBackupKey() + this.SORT_DIR_CACHE_KEY:
          //   this.__setSortDir(filterData);
          //   break;
          case this.__getFiltersBackupKey() + this.PAGINATION_SIZE_CACHE_KEY:
            this.__setPageSize(filterData);
            break;
          case this.__getFiltersBackupKey() + this.PAGINATION_PAGE_CACHE_KEY:
            this.__setCurrPage(filterData);
            break;
          default:
            this.__setFilters(filterData, false, filterKey);
        }

        window.setTimeout(() => {
          this.__refreshData();
        }, 100);

      });

    });
  }
}

export default BaseListPage;