import React from 'react';

class ValignMiddle extends React.Component {
  render() {
    return (
      <table width="100%" height="100%" border="0">
        <tbody>
          <tr>
            <td width="100%" height="100%" align="center" valign="middle">
              <div className={this.props.className || ''} style={this.props.style || {}}>
                {this.props.children}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default ValignMiddle;