// core
import React from 'react';
import { Link } from 'react-router-dom';

// settings + utils
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';

import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';

// lib
import moment from 'moment';
import { DataTable, Column } from 'primereact/datatable';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova/theme.css';
import 'primeicons/primeicons.css';

// shared
import BaseListPage from '../../pages/lib/BaseListPage';
import Spinner from '../../components/lib/thss/Spinner';

import ButtonsToolbar from '../../components/shared/ButtonsToolbar';
import FiltersToolbar from '../../components/shared/filters/FiltersToolbar';
import MainHeading from '../../components/shared/MainHeading';
import FilterRestoreNotice from '../../components/shared/filters/FilterRestoreNotice';
import ListPageWrapper from '../../components/shared/ListPageWrapper';

// page-specific/etc.
import BondRecordListDatagrid from '../../components/datagrids/BondRecordListDatagrid';
import NoRecordsMessage from '../../components/shared/NoRecordsMessage';

import StatesFilter from '../../components/shared/filters/StatesFilter';
import ProductTypesFilter from '../../components/shared/filters/ProductTypesFilter';
import BondStatusFilter from '../../components/shared/filters/BondStatusFilter';
import BondAssignmentsFilter from '../../components/shared/filters/BondAssignmentsFilter';
import BondRecordTypesFilter from '../../components/shared/filters/BondRecordTypesFilter.js';

class BondRecordsListPage extends BaseListPage {
  constructor(props) {
    super(props);

    this.FILTERS_PRESELECT_ALL = false;

    this.DEFAULT_SORT_BY = 'id';
    this.DEFAULT_SORT_DIR = 'desc';

    this.state = {
      ...this.state
    };
  }

  __init = (props, callback) => {
    // set backup key for filters
    this.__setFiltersBackupKey('bond-records-overview');

    // define defaults
    this.__setPageSize(this.DEFAULT_PAGINATION_SIZE);
    this.__setCurrPage(this.DEFAULT_PAGINATION_START);
    this.__setSortBy(this.DEFAULT_SORT_BY);
    this.__setSortDir(this.DEFAULT_SORT_DIR);

    window.setTimeout(() => {
      this.__restorePageOptions();
    }, 50);

    window.setTimeout(() => {// TODO: remove timeout hack to make sort by work in bond record list
      this.fetchBondRecords()
      .then(() => {
        if (callback) callback();
      })
      .catch((error) => {
        this.__setStatus(this.STATUS_ERROR);
        this.__setErrors([...this.__getErrors(), error]);

        if (callback) callback();
      });
    }, 100);
  }

  fetchBondRecords = () => {
    return new Promise((resolve, reject) => {
      this.__fetch('GET', Settings.API_URL + '/api/v1/bond-records', {
        bond_ids: '',
        type_names: this.__getFilters('bond_record_types') ? this.__getFilters('bond_record_types').join(',') : '',
        product_types: this.__getFilters('product_types') ? this.__getFilters('product_types').join(',') : '',
        state_codes: this.__getFilters('state_codes') ? this.__getFilters('state_codes').join(',') : '',
        bond_status_names: this.__getFilters('bond_status_names') ? this.__getFilters('bond_status_names').join(',') : '',
        bond_assignees: this.__getFilters('bond_assignees') ? this.__getFilters('bond_assignees').join(',') : '',
        q: this.__getSearchKey() ? this.__getSearchKey() : '',
        offset: this.__getOffset() !== undefined ? this.__getOffset() : this.DEFAULT_OFFSET,
        limit: this.__getLimit() !== undefined ? this.__getLimit() : this.DEFAULT_LIMIT,
        sort_by: this.__getSortBy() !== undefined ? this.__getSortBy() : this.DEFAULT_SORT_BY,
        sort_dir: this.__getSortDir() || this.DEFAULT_SORT_DIR
      }, {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken()
        }
      })
        .then((response) => {
          // save items
          this.__setTotalCount(response.total_count);
          this.__setPageData(response.data.records, 'records', () => {
            resolve();
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  __refreshData = () => {
    this.__abortAll();

    this.__setStatus(this.STATUS_FETCHING);

    this.fetchBondRecords()
      .then(this.__setStatus(this.STATUS_READY))
  }

  render() {
    const items = this.__getPageData('records');
    const fetching = items === null;

    return (
      <>
        <ListPageWrapper
          header={
            <MainHeading
              icon={Settings.icons.objectTypes.bond_record}
              title="Applications"
              descr=""
            >
              {/* <ButtonsToolbar
                related={[
                  {
                    label: 'New application',
                    href: '/bonds/find/',
                    type: 'add'
                  }
                ]}
              /> */}
            </MainHeading>
          }
          status={{
            fetching: fetching,
            errors: this.__getErrors()
          }}
          searchKey={this.__getSearchKey()}
          search={{
            label: 'Search results:',
            placeholder: '',

            onChange: (searchKey) => this.__handleSearchChange(searchKey),
            onSubmit: (searchKey) => this.__handleSearchChange(searchKey),
          }}
          filters={[
            <BondRecordTypesFilter
              label="Filter by type:"
              allLabel="All types"
              types={this.props.appData.bondRecordTypes}
              selected={this.__getFilters('bond_record_types')}

              onChange={(bond_record_types) => this.__handleFilterChange(bond_record_types, 'bond_record_types', true)}
            />,
            <BondStatusFilter
              label="Filter by status:"
              allLabel="All statuses"
              statusGroups={this.props.appData.bondStatusGroups}
              statuses={this.props.appData.bondStatuses}

              selected={this.__getFilters('bond_status_names')}

              onChange={(statuses) => this.__handleFilterChange(statuses, 'bond_status_names', true)}
            />,
            <BondAssignmentsFilter
              label="Filter by assignee:"
              allLabel="Any assignee"

              username={this.props.user.username}
              teammates={this.props.appData.permissions.team_usernames}
              underwriter={this.props.appData.permissions.underwriter_username}

              selected={this.__getFilters('bond_assignees')}

              onChange={(product_types) => this.__handleFilterChange(product_types, 'bond_assignees', true)}
            />,
            <StatesFilter
              label="Filter by state:"
              allLabel="All states"
              states={this.props.appData.states}
              selected={this.__getFilters('state_codes')}

              onChange={(states) => this.__handleFilterChange(states, 'state_codes', true)}
            />,
            <ProductTypesFilter
              label="Filter by product type:"
              allLabel="All types"
              types={this.props.appData.productTypes}
              selected={this.__getFilters('product_types')}

              onChange={(product_types) => this.__handleFilterChange(product_types, 'product_types', true)}
            />
          ]}
          pagination={{
            totalCount: this.__getTotalCount(),
            paginationSize: this.__getPageSize(),
            currPage: this.__getCurrPage(),

            onPageNext: this.__handlePageNext,
            onPagePrev: this.__handlePagePrev,
            onPageSizeChange: this.__handlePageSizeChange,
          }}
          data={{
            onExport: null
          }}
        >
          {
            this.__getStatus() == this.STATUS_ERROR && (
              <>
                <Alert variant="danger">
                  <h2>Sorry, an error occurred</h2>
                  <p>{this.state.error}</p>
                </Alert>
              </>
            )
          }
          {
            fetching && (
              <Spinner message="Fetching, please wait..." />
            )
          }
          {
            this.__getStatus() != this.STATUS_FETCHING && items && items.length == 0 && (
              <NoRecordsMessage />
            )
          }
          {
            this.__getStatus() != this.STATUS_FETCHING && items && items.length > 0 && (
              <BondRecordListDatagrid
                rows={items}

                sortBy={this.__getSortBy()}
                sortDir={this.__getSortDir().toUpperCase()}
                onSort={this.__handleSort}
              />
            )
          }
        </ListPageWrapper>
      </>
    );
  }
}

export default BondRecordsListPage;