// core
import React from 'react';
import { Link } from 'react-router-dom';

// settings + utils
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';

import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';
import NumberFormat from 'react-number-format';

// shared
import BaseListPage from '../../pages/lib/BaseListPage';
import Spinner from '../../components/lib/thss/Spinner';
import NoRecordsMessage from '../../components/shared/NoRecordsMessage';

import ButtonsToolbar from '../../components/shared/ButtonsToolbar';
import FiltersToolbar from '../../components/shared/filters/FiltersToolbar';
import MainHeading from '../../components/shared/MainHeading';
import FilterRestoreNotice from '../../components/shared/filters/FilterRestoreNotice';
import ListPageWrapper from '../../components/shared/ListPageWrapper';

// page-specific/etc.
import TimestampLabel from '../../components/shared/elems/TimestampLabel';

import DataGrid from '../../components/lib/react-data-grid/lib/DataGrid';
import '../../components/lib/react-data-grid/dist/react-data-grid.css';

class InquiryListPage extends BaseListPage {
  constructor(props) {
    super(props);

    this.FILTERS_PRESELECT_ALL = false;

    this.state = {
      ...this.state
    }
  }

  __init = (props, callback) => {
    this.__setFiltersBackupKey('customer-inquiries-overview');

    this.__fetchAll(callback);
  }

  __getSortBy = () => {
    return 'id';
  }
  __getSortDir = () => {
    return 'desc';
  }

  __fetchAll = (callback) => {
    this.__setStatus(this.STATUS_FETCHING);

    this.__setPageSize(this.DEFAULT_PAGINATION_SIZE);
    this.__setCurrPage(this.DEFAULT_PAGINATION_START);
    this.__setSortBy('id');
    this.__setSortDir('DESC');

    this.fetchInquiries(() => this.__setStatus(this.STATUS_READY));
  };

  fetchInquiries = (callback) => {
    this.__fetch('GET',  Settings.API_URL + '/api/v1/inquiries?types=' + (this.__getFilters('inquiry_types') ? this.__getFilters('inquiry_types').join(',') : '') + '&states=' + (this.__getFilters('states') ? this.__getFilters('states').join(',') : '') + '&q=' + (this.__getSearchKey() ? this.__getSearchKey() : '') + '&offset=' + this.__getOffset() + '&limit=' + this.__getLimit() + '&sort_by=' + this.__getSortBy() + '&sort_dir=' + this.__getSortDir(), {}, {
      headers: {
        'Authorization': 'Bearer ' + Auth.getToken()
      }
    }, (response, error) => {
      if (error || !response || !response.ok) {
        this.__setStatus(this.STATUS_ERROR);
        this.__setErrors([...this.__getErrors(), {
          message: response ? response.error : error
        }]);

        return;
      }

      // save items
      this.__setTotalCount(response.total_count);
      this.__setPageData(response.data.inquiries, 'inquiries', () => {
        if (callback) callback();
      });
    });
  }

  __refreshData = () => {
    this.__abortAll();

    this.__setStatus(this.STATUS_FETCHING);

    this.fetchInquiries(() => this.__setStatus(this.STATUS_READY));
  }

  render() {
    const items = this.__getPageData('inquiries');

    return (
      <>
        <ListPageWrapper
          header={
            <MainHeading
              icon="fad fa-comment-alt-smile"
              title="Customer support/inquiries"
            >
              {this.__getStatus() == this.STATUS_FETCHING && <Spinner message="Fetching..." />}
            </MainHeading>
          }
          status={{
            fetching: this.__getStatus() == this.STATUS_FETCHING,
            errors: this.__getErrors()
          }}
          searchKey={this.__getSearchKey()}
          search={null}
          filters={[]}
          pagination={{
            totalCount: this.__getTotalCount(),
            paginationSize: this.__getPageSize(),
            currPage: this.__getCurrPage(),

            onPageNext: this.__handlePageNext,
            onPagePrev: this.__handlePagePrev,
            onPageSizeChange: this.__handlePageSizeChange,
          }}
          data={{
            onExport: null
          }}
        >
          {
            this.__getStatus() == this.STATUS_ERROR && (
              <>
                <Alert variant="danger">
                  <h2>Sorry, an error occurred</h2>
                  <p>{this.__getErrors().map(error => <div>{error.message}</div>)}</p>
                </Alert>
              </>
            )
          }
          {
            items && items.length ? (
              <>
                <DataGrid
                  minColumnWidth={0}
                  itemHeight={30}

                  columns={[
                    { key: 'id', name: 'ID', width: 55, sortable: false },
                    { key: 'webpage', name: 'Webpage', width: 180, sortable: false },
                    { key: 'email', name: 'Email', width: 260, sortable: false },
                    { key: 'phone', name: 'Phone', width: 180, sortable: false },
                    { key: 'message', name: 'Message', sortable: false },
                    { key: 'created_at', name: 'Created at', width: 160, sortable: false }
                  ]}
                  rows={items.map((item) => {
                    const subject = `EZ Surety Bonds inquiry #${item.id}`;
                    const body = `%0d%0d>> ${item.message}`;

                    return {
                      id: item.id,
                      webpage: <><a className="ml-2" href={item.webpage_url.split('?')[0] + '?ref_page_id=' + item.webpage_id} target="_BLANK">{item.webpage_title}</a></>,
                      email: <a href={`mailto:${item.email}?subject=${subject}&body=${body}`}>{item.email}</a>,
                      phone: <a href={`tel:${item.phone}`}>{item.phone}</a>,
                      message: item.message,
                      created_at: <TimestampLabel utc={item.created_at} />
                    };
                  })}

                  headerRowHeight={40}
                  headerFiltersHeight={40}
                  itemHeight={40}
                  height={items.length * 40 + 45 + 2}

                  enableCellAutoFocus={false}

                  onRowClick={null}
                  sortColumn={this.__getSortBy()}
                  sortDirection={this.__getSortDir()}
                  onSort={this.__handleSort}
                />
              </>
            ) : (
              <>
                { this.__getStatus() == this.STATUS_READY && <NoRecordsMessage /> }
              </>
            )
          }
        </ListPageWrapper>
      </>
    );
  }
}

export default InquiryListPage;