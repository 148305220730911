import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import ObjectTypePanelPlaceholder from './ObjectTypePanelPlaceholder';
import ObjectTypeDataEditor from './ObjectTypeDataEditor';
import ObjectTypeDataViewer from './ObjectTypeDataViewer';

class ObjectTypePanel extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  handleChange = (formData, errors=[]) => {
    this.props.onChange(formData, errors);
  }

  render() {
    return (
      <ObjectTypePanelPlaceholder ready={this.props.ready} schema={this.props.schema} layout={this.props.layout}>
        {
          this.props.focused ? (
            <ObjectTypeDataEditor
              schema={this.props.schema}
              uiSchema={{}}
              layout={this.props.layout}

              data={this.props.data}

              onChange={this.handleChange}
            />
          ) : (
            <ObjectTypeDataViewer
              schema={this.props.schema}
              uiSchema={{}}
              layout={this.props.layout}

              data={this.props.data}
            />
          )
        }
      </ObjectTypePanelPlaceholder>
    );
  }
}

export default ObjectTypePanel;