import React from 'react';

import { Link } from 'react-router-dom';

import moment from 'moment';

import Settings from '../../../config';

import { Container, Row, Col, Button, Alert, Badge, Tooltip } from 'react-bootstrap';
import Panel from '../../../components/lib/thss/Panel';

export default class BondLogEntry extends React.PureComponent {
  render() {
    const { entry, withLink } = this.props;

    const relativeDate = moment.utc(entry.created_at).fromNow();
    const iconClass = Settings.icons.logEntryTypes[entry.event_type];

    return (
      <Panel style={{height: '78px'}} noPadding className="py-2 px-3 mb-3">
        <div className="float-left">
          <i className={`fad ${iconClass} text-primary h1 mt-0 mt-3 ml-3 mr-4`} />
        </div>
        <div className="float-left">
          <strong>Application #{entry.record_id}</strong>
          <small className="ml-1">
            {entry.prev_status_name && entry.prev_status_name != entry.status_name ? <><Badge variant="secondary">{entry.prev_status_name}</Badge><i className="fad fa-arrow-right ml-2 mr-2" /></> : ''}<Badge variant="secondary">{entry.status_name}</Badge>
          </small><br />
          <big className="font-italic m-0">{entry.message || 'No message'}</big>
          <div><small>{relativeDate} by {entry.created_by}</small></div>
        </div>

        { withLink && <Link to={`/applications/${entry.record_id}/`}><Button variant="light" className="float-right mt-3">view application <i className="fad fa-arrow-right ml-1" /></Button></Link> }

        <div className="clearfix" />
      </Panel>
    )
  }
}