// core
import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

// settings + utils
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';

import { Container, Row, Col, Button, Alert, Badge } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';
import NumberFormat from 'react-number-format';

// page-specific/etc.
import DataGrid from '../../components/lib/react-data-grid/lib/DataGrid';
import '../../components/lib/react-data-grid/dist/react-data-grid.css';

import ProfilePicture from '../../components/shared/elems/ProfilePicture';

import TimestampLabel from '../../components/shared/elems/TimestampLabel';
import BondStatusLabel from '../../components/shared/elems/BondStatusLabel';

class BondRecordListDatagrid extends React.PureComponent {
  __handleSort = (sortBy, sortDir) => {
    if (this.props.onSort) this.props.onSort(sortBy, sortDir);
  }

  render() {
    const { rows, sortBy, sortDir } = this.props;

    return (
      <DataGrid
        minColumnWidth={0}
        rowHeight={30}

        columns={[
          { key: 'id', name: 'ID', width: 55, sortable: true},
          { key: 'principal_name', name: 'Customer', width: 195, sortable: false },
          { key: 'bond_number', name: 'Bond #', width: 115, sortable: true },
          { key: 'product_name', name: 'Details', width: 410, sortable: true },
          { key: 'status', name: 'Status', width: 150, sortable: true },
          { key: 'created_at', name: 'Submitted', width: 200, align: 'center', sortable: true},
          { key: 'assigned_to', name: 'Assigned to', width: 125, align: 'center', sortable: true},
          { key: 'actions', name: '', width: 100, sortable: false}
        ]}
        rows={rows.map((row, i) => {
          const newRow = {...row};

          const { ref_state_code, product_state_code, principal_name, status_name, status_label, status_group_name, created_by, created_at} = row;

          // use base_name to split name into 2 lines (base name is usually the bond name, while the full product name contains the obligee name)
          const obligeeDescr = newRow.product_name.split(newRow.product_base_name)[0];
          const bondDescr = newRow.product_name.replace(obligeeDescr, '');
          newRow.product_name = (
            <div className="sbm-bond-datagrid-row" style={{
              lineHeight: '1.45em',
              paddingTop: '0.15em'
            }}>
              <strong className="text-primary text-truncate">{bondDescr}</strong><br />
              <span className="d-flex">
                <Badge variant="light" className="mr-2">
                  <span><i className="fad fa-map-marker-alt mr-2" />{newRow.product_state_code}</span>
                </Badge>
                <Badge variant="light" className="text-truncate mr-2">
                  <span><i className="fad fa-gavel mr-2" />{obligeeDescr || <em>Variable obligee</em>}</span>
                </Badge>
                {
                  newRow.type_name &&
                  <Badge variant="light" className="mr-2">
                    <span>{newRow.type_name}</span>
                  </Badge>
                }
              </span>
            </div>
          );

          // add ref to bond state (could come from user if federal, etc.)
          newRow.product_state_code = ref_state_code != product_state_code ? `${product_state_code}/${ref_state_code}` : product_state_code;

          // add icon to principal according to its type
          newRow.principal_name = <span><i className={`fad ${newRow.is_business ? 'fa-building' : 'fa-user'} mr-2`} />{principal_name}</span>;

          // format status name
          newRow.status = <BondStatusLabel statusName={status_name} statusLabel={status_label} groupName={status_group_name} className="w-100" />

          // handle anonymous applications (EZSB, etc.)
          newRow.created_at = (
            <div style={{
              lineHeight: '1.45em',
              paddingTop: '0.15em'
            }}>
              <TimestampLabel dateOnly={true} timeOnly={false} utc={newRow.created_at} /> by {newRow.created_by == 'nobody' ? 'EZSB' : <span className="">{newRow.created_by}</span>}<br />
              <small>at <TimestampLabel dateOnly={false} timeOnly={true} utc={newRow.created_at} /></small>
            </div>
          );

          newRow.assigned_to = (
            <div style={{height: '100%', lineHeight: '100%'}}>
              {
                !newRow.assigned_to && (
                  <p className="py-3 text-muted">
                    <small><i className="fad fa-user-slash mr-2" />nobody</small>
                  </p>
                )
              }
              {
                newRow.assigned_to == Settings.NOBODY_USER_USERNAME && (
                  <p className="py-3 text-success">
                    <small><i className="fad fa-check mr-2" />no action</small>
                  </p>
                )
              }
              {
                newRow.assigned_to && newRow.assigned_to != Settings.NOBODY_USER_USERNAME && (
                  <>
                    <small><ProfilePicture username={newRow.assigned_to} className="py-1 mr-1" />
                    {newRow.assigned_to}</small>
                  </>
                )
              }
            </div>
          );

          newRow.actions = (
            <div className="text-center">
              <Link to={`/applications/${newRow.id}/`}><Button variant="light"><i className="fa fa-eye mr-2" />view</Button></Link>
            </div>
          );

          return newRow;
        })}

        headerRowHeight={40}
        headerFiltersHeight={40}
        rowHeight={44}
        height={rows.length * 44 + 45 + 2}

        enableCellAutoFocus={false}

        onRowClick={null}
        sortColumn={sortBy}
        sortDirection={sortDir}
        onSort={this.__handleSort}
      />
    );
  }
}

export default BondRecordListDatagrid;