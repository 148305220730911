import React from 'react';

import Settings from '../../../config';

import PortalModal from './PortalModal';
import { Button, Badge } from 'react-bootstrap';
import ProfilePicture from './ProfilePicture';
import TimestampLabel from './TimestampLabel';

class BondFile extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  handleClick = (e) => {
    this.setState({
      open: false
    }, () => {
      this.setState({open: true});
    });

  };

  render() {
    const stringToColor = function(str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var colour = '#';
      for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
      }
      return colour;
    }

    const { data, embedLink, downloadLink } = this.props;

    const fileTitle = data.title || data.filename;
    const fileTypeLabel = (data.type.charAt(0).toUpperCase() + data.type.slice(1)).replace('_', ' ');

    return (
      <>
        {
          this.state.open && (
          <PortalModal
            title={fileTitle}
            titleActions={
              <span className="ml-2" style={{zoom: 0.85}}>
                <a href={downloadLink}>
                  <Button variant="light" size="sm" className="p-1">
                    <i className="fad fa-download mr-1" />Download
                  </Button>
                </a>
              </span>
            }
            localStorageKey="smb:BondFile"
          >
            <iframe border="0" width="100%" height="100%" src={embedLink}></iframe>
          </PortalModal>
          )
        }

        <div className="w-100 position-relative">
          <table width="100%" border="0">
            <tr>
              <td colspan="2" align="left" valign="middle">
                <p className="m-0">
                  <div style={{display: 'inline-block', height: '2em', marginBottom: '-0.75em'}}><ProfilePicture username={data.created_by} className="pr-1 pb-2" /></div>
                  <span className="text-dark mr-1">{data.created_by}</span>
                  <small className="text-secondary">on <TimestampLabel utc={data.created_at} /></small>
                </p>
              </td>
            </tr>
            <tr>
              <td width="70%" align="left" valign="middle">
                <p className="m-0 p-0">
                  <a href="javascript:void(0)" className="h6" title="Click to view">{fileTitle}</a>
                  <Badge style={{marginLeft: '0.5em', color: '#ffff', backgroundColor: stringToColor(fileTypeLabel)}}>{fileTypeLabel}</Badge>
                </p>
              </td>
              <td width="30%" align="right" valign="middle">
                <a href="javascript:void(0);" onClick={this.handleClick} className="mr-2"><Button variant="light" size="sm" className="p-1"><i className="fad fa-eye mr-1" />View</Button></a>
                <a href={downloadLink} className=""><Button variant="light" size="sm" className="p-1"><i className="fad fa-download mr-1" />Download</Button></a>
              </td>
            </tr>
          </table>
        </div>
      </>
    );
  }
}

export default BondFile;
















