import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import Settings from '../../../config';

import Panel from '../../../components/lib/thss/Panel';

import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';

class BondStatusFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroupsByName: {}
    };
  }

  handleStatusGroupChange = (group) => {
    const selectedStatusNames = this.props.selected || [];

    const groupName = group.name;
    const groupStatusNames = group.statuses.map(status => status.name);
    const isInactive = this.state.selectedGroupsByName[groupName] === undefined;

    this.setState((prevState) => {
      prevState.selectedGroupsByName[groupName] = isInactive ? group : undefined;

      return prevState;
    }, () => {
      const nextStatusNames = isInactive ? selectedStatusNames.concat(groupStatusNames) : selectedStatusNames.filter(statusName => groupStatusNames.indexOf(statusName) == -1);

      if (this.props.onChange) {
        this.props.onChange(nextStatusNames);
      }
    });
  }

  handleStatusChange = (statuses) => {
    const statusNames = statuses.map(status => status.name);
    if (this.props.onChange) {
      this.props.onChange(statusNames);
    }
  }

  render() {
    const { statusGroups, statuses } = this.props;
    const selectedStatusNames = this.props.selected || [];

    if (!statuses || !Array.isArray(statuses)) {
      console.warn('No statuses, return early');

      return '';
    }

    return (
      <>
        {
          this.props.label && <p className="mt-0 mb-1 text-muted"><small>{this.props.label}</small></p>
        }

        <MultiSelect
          value={statuses.filter(status => selectedStatusNames.indexOf(status.name) != -1)}
          options={statusGroups}

          filter={false}

          onChange={(e) => this.handleStatusChange(e.value)}

          optionGroupLabel="label"
          optionGroupChildren="statuses"
          optionLabel="label"

          panelClassName="sbm-grouped-multiselect"

          placeholder='—'

          optionGroupTemplate={(group) => {
            const selectedStatusNames = this.props.selected || [];
            const isGroupSelected = group.statuses.filter(status => selectedStatusNames.indexOf(status.name) == -1).length === 0;

            return (
              <div onClick={(e) => this.handleStatusGroupChange(group)} checked={false}>
                <Checkbox checked={isGroupSelected} /><span className="ml-2">{group.label}</span>
              </div>
            );
          }}

          style={{width: '100%'}}
        />
      </>
    );
  }
}

export default BondStatusFilter;