// TODO: is there a way/does it make sense to make these come from the API?

let layouts = [];

//////////////////////////////////////// Core objects ////////////////////////////////////////////
layouts.state = [
  {
    name: {xs: 8},
    code: {xs: 4}
  }
];

layouts.business_entity_type = [
  {
    name: {xs: 12}
  }
];

layouts.address = [
  {
    mailing_street: {xs: 12}
  },
  {
    mailing_city: {xs: 6},
    mailing_state: {xs: 3},
    mailing_zip: {xs: 3}
  },
];

layouts.person = [
  {
    first_name: {xs: 4},
    middle_name: {xs: 4},
    last_name: {xs: 4}
  }
];

layouts.contact = [
  {
    email: {xs: 12}
  },
  {
    phone: {xs: 6},
    fax: {xs: 6},
  },
];

layouts.business = [
  {
    business_name: {xs: 6},
    business_dba: {xs: 6},
  },
  {
    business_fein_number: {xs: 7},
    business_entity_type_id: {xs: 5}
  },
  ...layouts.contact,
  ...layouts.address
];

layouts.business_person = [
  {
    first_name: {xs: 6},
    last_name: {xs: 6}
  },

  ...layouts.business
];

layouts.commission_rate = [
  {
    percentage: {xs: 6},
    product_type_id: {xs: 6}
  }
];

layouts.upload = [
  {
    title: {xs: 12}
  },
  {
    descr: {xs: 12}
  },
  {
    filename: {xs: 12}
  },
  {
    base64: {xs: 12}
  },
  {
    private: {xs: 12}
  },
  {
    mime_type: {xs: 12}
  },
  {
    file_size: {xs: 12}
  },
  {
    added_by_username: {xs: 12}
  }
];

// affiliates (MGA/agency)
layouts.affiliate = [
  ...layouts.business, // not adding group since MGAs and agencies will add initial heading
  {
    label: 'Bank information',
    elements: [
      {
        ach_bank_name: {xs: 12}
      },
      {
        ach_routing_number: {xs: 6},
        ach_acct_number: {xs: 6}
      },
      {
        ach_bank_street: {xs: 12}
      },
      {
        eo_carrier: {xs: 6},
        eo_expiration: {xs: 6}
      }
    ]
  },
  {
    label: 'Commission bonuses',
    elements: [
      {
        contingency_bonus: {xs: 6},
        contingency_volume: {xs: 6}
      }
    ]
  },
  {
    label: 'Verified?',
    elements: [
      {
        verified: {xs: 12}
      }
    ]
  },
  {
    label: 'Legacy info',
    elements: [
      {
        legacy_type: {xs: 3},
        legacy_entry_id: {xs: 3},
        legacy_code: {xs: 3},
        legacy_master_code: {xs: 3}
      }
    ]
  }
];

layouts.affiliate_address = [
  ...layouts.address
];

layouts.affiliate_commission_rate = [
  ...layouts.commission_rate
];

layouts.affiliate_applicant = [
    ...layouts.person
];

layouts.affiliate_employee = [
  ...layouts.person
];

//////////////////////////////////////// MGAs ////////////////////////////////////////////
layouts.mga = [
  {
    label: 'Basic information',
    elements: [
      {
        mga_id: {xs: 12},
      }
    ]
  },

  ...layouts.affiliate,

  {
    legacy_mga_id: {xs: 3}
  }
];

layouts.mga_address = [
  ...layouts.affiliate_address
];

layouts.mga_applicant = [
  ...layouts.affiliate_applicant,
  ...layouts.contact
];

layouts.mga_employee = [
  ...layouts.affiliate_employee,
  ...layouts.contact
];

layouts.mga_commission_rate = [
  {
    mga_id: {xs: 12}
  },

  ...layouts.affiliate_commission_rate
];

layouts.mga_licensed_state = [
  {
    mga_id: {xs: 12}
  },

  ...layouts.state
];

//////////////////////////////////////// Agencies ////////////////////////////////////////////
layouts.agency = [
  {
    label: 'Basic information',
    elements: [
      {
        mga_id: {xs: 12},
      }
    ]
  },

  ...layouts.affiliate,

  {
    legacy_mga_id: {xs: 3}
  }
];

layouts.agency_address = [
  ...layouts.affiliate_address
];

layouts.agency_applicant = [
  ...layouts.affiliate_applicant,
  ...layouts.contact
];

layouts.agency_employee = [
  ...layouts.affiliate_employee,
  ...layouts.contact
];

layouts.agency_commission_rate = [
  {
    agency_id: {xs: 12}
  },

  ...layouts.affiliate_commission_rate
];

layouts.agency_licensed_state = [
  {
    agency_id: {xs: 12}
  },

  ...layouts.state
];

////////////////////////////////////////// BondRecords ////////////////////////////////////////
layouts.bond_record = [
  {
    label: 'Basic information',
    elements: [
      {
        id: {xs: 6}
      },
      {
        product_name: {xs: 8},
        product_id: {xs: 4}
      },
      {
        product_state_code: {xs: 4},
        product_type_id: {xs: 4},
        bond_penalty_amount: {xs: 4}
      }
    ]
  },
  {
    label: 'Issuance/expiration',
    elements: [
      {
        surety_company_id: {xs: 12}
      },
      {
        bond_number: {xs: 3},
        issue_date: {xs: 3},
        effective_date: {xs: 3},
        expiration_date: {xs: 3}
      }
    ]
  },
  {
    label: 'Rating',
    elements: [
      {
        is_credit_required: {xs: 6},
        is_underwriting: {xs: 6}
      },
      {
        bond_premium_amount: {xs: 12}
      }
    ]
  },
  {
    label: 'Agency + commissions',
    elements: [
      {
        agency_id: {xs: 4},
        agency_commission_rate: {xs: 4},
        agency_commission_amount: {xs: 4}
      }
    ]
  }
];
layouts.bond_rating_details = [
  {
    bond_premium_amount: {xs: 12}
  },
  {
    rating_tier: {xs: 12},
  },
  {
    rating_description: {xs: 12}
  },
  {
    normalized_fico_score: {xs: 4},
    normalized_past_dues_amount: {xs: 4},
    normalized_no_bankruptcy: {xs: 4}
  },
  {
    normalized_no_derogatory_items: {xs: 4},
    normalized_no_past_due_child_support: {xs: 4},
    normalized_evidence_of_positive_net_worth: {xs: 4},
  }
];

layouts.bond_applicant = [
  ...layouts.person,
  ...layouts.contact,
  ...layouts.address
];
layouts.bond_business = [
  ...layouts.business
];
layouts.bond_risk_assessment_options = [
  {
    is_felon: {xs: 6},
    has_previous_claim: {xs: 6}
  }
];
layouts.bond_mso_custom_fields = []; // set on the fly
layouts.bond_indemnitors_global_options = [
  {
    us_citizens: {xs: 12}
  }
];
layouts.bond_indemnitor = [
  {
    'ownership_percentage': {xs: 12},
  },
  {
    'is_us_citizen': {xs: 12}
  },
  {
    'is_felon': {xs: 6},
    'has_previous_claim': {xs: 6},
  },
  {
    indemnitor_type: {xs: 4}
  },
  {
    first_name: {xs: 4},
    middle_name: {xs: 4},
    last_name: {xs: 4}
  },
  {
    phone: {xs: 6},
    email: {xs: 6}
  },
  {
    ssn: {xs: 7},
    dob: {xs: 5}
  },
  {
    property_owner: {xs: 6}
  },
  ...layouts.address,
  {
    credit_pull_authorized: {xs: 12}
  }
];
layouts.bond_options = [
  {
    effective_date: {xs: 6}
  }
];
layouts.bond_public_note = [
  {
    note: {xs: 12}
  }
];
layouts.bond_private_note = [
  {
    note: {xs: 12}
  }
];
layouts.bond_shipping_options = [
  {
    "shipping_method_id": {xs: 6},
    "price": {xs: 6}
  },
  {
    street: {xs: 12}
  },
  {
    city: {xs: 6},
    zip: {xs: 2},
    state_code: {xs: 4},
  }
];

layouts.bond_payment = [
  {
    "payment_type": {xs: 12},
  },
  {
    "transaction_id": {xs: 12}
  },
  {
    "ref_amount": {xs: 6},
    "authorized_amount": {xs: 6}
  },
  {
    "status_name": {xs: 12},
  },
  {
    "status_details": {xs: 12},
  },
  {
    "account_type": {xs: 6},
    "account_number": {xs: 6}
  }
]

export default layouts;







