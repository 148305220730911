import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from '../../components/lib/thss/Panel';

class NoRecordsMessage extends React.Component {
  render() {
    return (
      <h3>No records found.</h3>
    );
  }
}

export default NoRecordsMessage;