import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from '../../lib/thss/Panel';

class ClaimsFraudWarning extends React.PureComponent {
  render() {
    const stateCode = this.props.state;

    const heading = <p className="mb-0"><i className="fas fa-exclamation-triangle mr-1" /> {stateCode ? stateCode + ' state warning' : 'Warning'}</p>;

    switch (stateCode) {
      case 'AL':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or who knowingly presents false information in an application for insurance is guilty of a crime and may be subject to restitution, fines, or confinement in prison, or any combination thereof.
            </small></p>
            <p className="mt-2 mb-2"><small>
              Workers Compensation: Any person who makes or causes to be made any knowingly false or fraudulent material statement or material representation for the purpose of obtaining workers compensation benefits for himself or herself or any other person is guilty of a Class C felony.
            </small></p>
          </div>
        );
      case 'AK':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              A person who knowingly and with intent to injure, defraud, or deceive an insurance company files a claim containing false, incomplete, or misleading information may be prosecuted under state law.
            </small></p>
          </div>
        );
      case 'AZ':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              For your protection Arizona law requires the following statement to appear on this form: Any person who knowingly presents a false or fraudulent claim for payment of a loss is subject to criminal and civil penalties.
            </small></p>
          </div>
        );
      case 'AR':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.
            </small></p>
            <p className="mt-2 mb-2"><small>
              Workers Compensation: Any person or entity who willfully and knowingly makes any material false statement or representation, who willfully and knowingly omits or conceals any material information, or who willfully and knowingly employs any device, scheme or artifice, for the purpose of obtaining any benefit or payment, defeating or wrongfully increasing or wrongfully decreasing any claim for benefit or payment, or obtaining or avoiding workers compensation coverage or avoiding payment of the proper insurance premium, or who aids and abets for any of said purposes, under this chapter will be guilty of a Class D felony.
            </small></p>
          </div>
        );
      case 'CA':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              For your protection, California law requires the following to appear on this form: Any person who knowingly presents a false or fraudulent claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.
            </small></p>
            <p className="mt-2 mb-2"><small>
              Workers Compensation: Any person who makes or causes to be made any knowingly false or fraudulent material statement or material representation for the purpose of obtaining or denying workers compensation benefits or payments is guilty of a felony.
            </small></p>
          </div>
        );
      case 'CO':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              It is unlawful to knowingly provide false, incomplete, or misleading facts or information to an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment, fines, denial of insurance, and civil damages. Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading facts or information to a policyholder or claimant for the purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a settlement or award payable from insurance proceeds shall be reported to the Colorado Division of Insurance within the Department of Regulatory Agencies.
            </small></p>
          </div>
        );
      case 'DE':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Any person who knowingly, and with intent to injure, defraud or deceive any insurer, files a statement of claim containing any false, incomplete or misleading information is guilty of a felony.
            </small></p>
          </div>
        );
      case 'DC':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              WARNING: It is a crime to provide false or misleading information to an insurer for the purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or fines. In addition, an insurer may deny insurance benefits if false information materially related to a claim was provided by the applicant.
            </small></p>
          </div>
        );
      case 'FL':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of claim containing any false, incomplete, or misleading information is guilty of a felony of the third degree.
            </small></p>
            <p className="mt-2 mb-2"><small>
              Workers Compensation: Any person who, knowingly and with intent to injure, defraud, or deceive any employer or employee, insurance company, or self-insured program, files a statement of claim containing any false or misleading information, commits insurance fraud, punishable as provided in s. 817.234.
            </small></p>
          </div>
        );
      case 'ID':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Any person who knowingly, and with intent to defraud or deceive any insurance company, files a statement containing any false, incomplete, or misleading information is guilty of a felony.
            </small></p>
          </div>
        );
      case 'IN':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              A person who knowingly and with intent to defraud an insurer files a statement of claim containing any false, incomplete, or misleading information commits a felony.
            </small></p>
          </div>
        );
      case 'KS':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Workers Compensation: Warning: Acceptance of employment with a different employer that requires the performance of activities you have stated you cannot perform because of the injury for which you are receiving temporary disability benefits could constitute fraud and could result in loss of future benefits and restitution of prior workers compensation awards and benefits paid.
            </small></p>
          </div>
        );
      case 'KY':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Any person who knowingly and with intent to defraud any insurance company or other person files a statement of claim containing any materially false information or conceals, for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime.
            </small></p>
          </div>
        );
      case 'LA':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.
            </small></p>
            <p className="mt-2 mb-2"><small>
              Workers Compensation: Failure to answer truthfully may result in forfeiture of workers compensation benefits.
            </small></p>
          </div>
        );
      case 'ME':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties may include imprisonment, fines or a denial of insurance benefits.
            </small></p>
          </div>
        );
      case 'MD':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Any person who knowingly or willfully presents a false or fraudulent claim for payment of a loss or benefit or who knowingly or willfully presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.
            </small></p>
          </div>
        );
      case 'MN':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              A person who files a claim with intent to defraud, or helps commit a fraud against an insurer, is guilty of a crime.
            </small></p>
            <p className="mt-2 mb-2"><small>
              Workers Compensation: Any person who, with intent to defraud, receives workers’ compensation benefits to which the person is not entitled by knowingly misrepresenting, misstating, or failing to disclose any material fact is guilty of theft and shall be sentenced pursuant to s 609.52, subdivision 3.
            </small></p>
          </div>
        );
      case 'NH':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Any person who, with a purpose to injure, defraud or deceive any insurance company, files a statement of claim containing any false, incomplete or misleading information is subject to prosecution and punishment for insurance fraud, as provided in RSA 638:20.
            </small></p>
          </div>
        );
      case 'NJ':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Any person who knowingly files a statement of claim containing any false or misleading information is subject to criminal and civil penalties.
            </small></p>
          </div>
        );
      case 'NM':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to civil fines and criminal penalties.
            </small></p>
          </div>
        );
      case 'NY':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              GENERAL: Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and the stated value of the claim for each such violation.
            </small></p>
            <p className="mt-2 mb-2"><small>
              AUTO: Any person who knowingly makes or knowingly assists, abets, solicits or conspires with another to make a false report of the theft, destruction, damage or conversion of any motor vehicle to a law enforcement agency, the department of motor vehicles or an insurance company, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and the value of the subject motor vehicle or stated claim for each violation.
            </small></p>
            <p className="mt-2 mb-2"><small>
              FIRE: Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance containing any false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime. The proposed insured affirms that the foregoing information is true and agrees that these applications shall constitute a part of any policy issued whether attached or not and that any willful concealment or misrepresentation of a material fact or circumstances shall be grounds to rescind the insurance policy.
            </small></p>
          </div>
        );
      case 'OH':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Any person who, with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.
            </small></p>
          </div>
        );
      case 'OK':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              WARNING: Any person who knowingly, and with intent to injure, defraud or deceive any insurer, makes any claim for the proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony.
            </small></p>
            <p className="mt-2 mb-2"><small>
              Workers Compensation Warning: Any person or entity who makes any material false statement or representation, who willfully and knowingly omits or conceals any material information, or who employs any device, scheme, or artifice, or who aids and abets any person for the purpose of: 1. obtaining any benefit or payment, 2. increasing any claim for benefit or payment, or 3. obtaining workers' compensation coverage under this act, shall be guilty of a felony punishable pursuant to Section 1663 of Title 21 of the Oklahoma Statutes.
            </small></p>
          </div>
        );
      case 'PA':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              GENERAL: Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime and subjects such person to criminal and civil penalties.
            </small></p>
            <p className="mt-2 mb-2"><small>
              AUTO: Any person who knowingly and with intent to injure or defraud any insurer files an application or claim containing any false, incomplete or misleading information shall, upon conviction, be subject to imprisonment for up to seven years and the payment of a fine of up to $15,000.
            </small></p>
          </div>
        );
      case 'RI':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.
            </small></p>
          </div>
        );
      case 'TN':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.
            </small></p>
            <p className="mt-2 mb-2"><small>
              Workers Compensation: It is a crime to knowingly provide false, incomplete or misleading information to any party to a workers compensation transaction for the purpose of committing fraud. Penalties include imprisonment, fines and denial of insurance benefits.
            </small></p>
          </div>
        );
      case 'TX':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Workers Compensation: Any person who knowingly presents a false or fraudulent claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.
            </small></p>
          </div>
        );
      case 'UT':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Workers Compensation: Any person who knowingly presents false or fraudulent underwriting information, files or causes to be filed a false or fraudulent claim for disability compensation or medical benefits, or submits a false or fraudulent report or billing for health care fees or other professional services is guilty of a crime and may be subject to fines and confinement in state prison.
            </small></p>
          </div>
        );
      case 'VA':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.
            </small></p>
          </div>
        );
      case 'WA':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              It is a crime to knowingly provide false, incomplete, or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines, and denial of insurance benefits.
            </small></p>
          </div>
        );
      case 'WV':
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.
            </small></p>
          </div>
        );
      default:
        return (
          <div className="mt-0 mb-4">
            {heading}

            <p className="mt-2 mb-2"><small>
              I certify that all information given in this application is correct to the best of my knowledge. Completion of this application constitutes permission to obtain consumer information which may be used to determine bonding eligibility. I understand that knowingly submitting false, incomplete, or misleading information is considered fraud and guilty of a felony.
            </small></p>
          </div>
        );
    }
  }
}

export default ClaimsFraudWarning;