import React from 'react';

import { Container, Row, Col, Button } from 'react-bootstrap';

import Payment from 'payment';
import CreditCard from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

function formatCreditCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 15)}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8,
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === 'amex' ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

function formatExpirationDate(value) {
  const numberValue = clearNumber(value);
  let printValue = '';

  if (value == numberValue + '/') {
    printValue = numberValue + '/';
  } else {
    printValue = numberValue;
  }

  if (numberValue.length >= 3) {
    return `${printValue.slice(0, 2)}/${printValue.slice(2, 4)}`;
  }

  return printValue;
}

function formatFormData(data) {
  return Object.keys(data).map(d => `${d}: ${data[d]}`);
}

class BondPaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      number: '',
      name: '',
      expiry: '',
      cvc: '',
      issuer: '',
      focused: '',
      formData: null,
    };
  }

  componentDidMount() {
    // add test values
    [
      {
        name: 'name',
        value: 'Niccolo TEST'
      },
      {
        name: 'number',
        value: '5424000000000015'
      },
      {
        name: 'expiry',
        value: '12/20'
      },
      {
        name: 'cvc',
        value: '999'
      }
    ].forEach((target) => {
      const elem = document.querySelector('#ezsb_cc_payment_form [name="' + target.name + '"]');
      elem.value = target.value;

      this.handleInputChange({target});
    });
  }

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === 'number') {
      target.value = formatCreditCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { issuer } = this.state;
    const formData = {
      name: this.state.name,
      number: this.state.number,
      expiry: this.state.expiry,
      cvc: this.state.cvc
    };

    if (this.props.onSubmit) {
      return this.props.onSubmit(formData);
    }
  };

  render() {
    const { name, number, expiry, cvc, focused, issuer, formData } = this.state;

    return (
      <div key="Payment">
        <div className="App-payment">
          <Row>
            <Col xs={5}>
              <div className="float-left">
                <CreditCard
                  number={number}
                  name={name}
                  expiry={expiry}
                  cvc={cvc}
                  focused={focused}
                  callback={this.handleCallback}
                />
              </div>
            </Col>
            <Col xs={7}>
              <form id="ezsb_cc_payment_form" className="text-left" /*ref={c => (this.form = c)} onSubmit={this.handleSubmit} */>
                <input type="hidden" name="issuer" value={issuer} />

                <div className="form-group">
                  <input
                    type="text"
                    name="number"
                    className="form-control"
                    placeholder="CreditCard Number"
                    pattern="[\d| ]{16,22}"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <input
                      type="text"
                      name="expiry"
                      className="form-control"
                      placeholder="Valid Thru"
                      pattern="\d\d/\d\d"
                      required
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      name="cvc"
                      className="form-control"
                      placeholder="CVC"
                      pattern="\d{3,4}"
                      required
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                </div>

                <p className="mt-4">
                  <Button variant="primary" onClick={this.props.status == '' ? this.handleSubmit : null}>
                    {
                      !this.props.error && this.props.status == '' && 'Pay ' + (this.props.amount ? '$ ' + this.props.amount :  '')
                    }
                    {
                      !this.props.error && this.props.status == 'busy' && 'Working...'
                    }
                    {
                      !this.props.error && this.props.status == 'success' && <><i className="fa fa-check" /> Thank you!</>
                    }
                  </Button>
                </p>

                {
                  this.props.error && (
                    <>
                      <p style={{color: 'red', textAlign: 'center', marginBottom: '10px'}}><i className="fa fa-exclamation-triangle" /> {this.props.error}</p>
                      {/* <p style={{marginTop: 0, fontSize: '10px', textAlign: 'center'}}>Please wait...</p> */}
                    </>
                  )
                }
              </form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default BondPaymentForm;