import React from 'react';

import { Container, Row, Col, Button } from 'react-bootstrap';

import Panel from '../../lib/thss/Panel';

class BondStepSubmitButton extends React.PureComponent {
  render() {
    return (
      <>
        <button className={'btn btn-light ' + (this.props.numRequired === 0 ? 'text-dark animated pulse' : 'text-light disabled')} onClick={e => this.props.numRequired === 0 && this.props.onSubmit(e)}>
          <i className={'fad ' + (this.props.numRequired ? 'fa-times' : 'fa-check') + ' mr-1'} />{this.props.allowAdding ? 'Done adding' : 'Continue'}
        </button>

        {
          this.props.allowAdding && (
            <>
              <span className="ml-2 mr-2">or</span>
              <a href="javascript:void(0);" className="text-white" onClick={this.props.onAddItem}><u>add additional owner</u></a>
            </>
          )
        }

        { this.props.numRequired > 0 && <span className="ml-2"><i className="fa fa-exclamation-triangle mr-2" />{this.props.numRequired} {this.props.numRequired === 1 ? 'required field' : 'required fields'} left</span> }
        {
          this.props.allowSkipping && (
            <>
              {
                this.props.skipped == false ? (
                  <span className="ml-1">or <Button className="ml-2" variant="light" onClick={this.props.onSkip}><i className="fad fa-forward mr-2" />skip this step</Button></span>
                ) : (
                  <span className="ml-1">or <Button className="ml-2" variant="light" onClick={this.props.onRestore}><i className="fad fa-redo mr-2" />restore this step</Button></span>
                )
              }
            </>
          )
        }
      </>
    )
  }
}

export default BondStepSubmitButton;