import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from '../../../components/lib/thss/Panel';

class FiltersToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: this.props.collapsable ? false : true
    };
  }

  toggle = () => {
    if (!this.props.collapsable) {
      return;
    }

    this.setState({
      open: !this.state.open
    });
  }

  render() {
    let statusMessage = this.props.message;

    return (
      <Container fluid className="mb-4" style={{backgroundColor: '#dedede', paddingTop: '0.75rem', paddingBottom: '0.75rem', border: '1px solid #d6d6d6', borderRadius: '0.3rem'}}>
        <Row>
          <Col xs={12}>
            {/* Header */}
            <div style={this.props.collapsable ? {cursor: 'pointer'} : {}} onClick={this.toggle}>
              {
                this.props.collapsable ? (
                  <div style={{position: 'absolute', top: this.state.open ? '0.25rem' : '0.5rem', right: '20px'}}>
                    <a href="javascript:void(0);">
                      <i className={this.state.open ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} style={{fontSize: '1.25remZ', color: '#aaa'}} />
                    </a>
                  </div>
                ) : ''
              }
              <h4><i className="fa fa-filter" /> Filters <small>({statusMessage || 'no filter'})</small></h4>
            </div>
          </Col>
        </Row>

        {this.props.children}
      </Container>
    );
  }
}

export default FiltersToolbar;