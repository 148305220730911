import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import ObjectField from '@rjsf/core/dist/cjs/components/fields/ObjectField';
import { retrieveSchema } from '@rjsf/core/dist/cjs/utils';


class FieldGroup extends ObjectField {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      uiSchema,
      errorSchema,
      idSchema,
      required,
      disabled,
      readonly,
      onBlur,
      formData,
      viewmode
    } = this.props;

    const { definitions, fields, formContext } = this.props.registry;
    const { SchemaField, TitleField, DescriptionField } = fields;
    const schema = retrieveSchema(this.props.schema, definitions);
    const title = (schema.title === undefined) ? '' : schema.title;

    const layout = uiSchema['ui:layout'];
    if (!layout) {
      return <p>No layout! HFHD78</p>;
    }

    return (
      <>
        {
          title && <TitleField
            id={`${idSchema.$id}__title`}
            title={title}
            required={required}
            formContext={formContext}
          />
        }
        {
          schema.description && <DescriptionField
            id={`${idSchema.$id}__description`}
            description={schema.description}
            formContext={formContext}
          />
        }
        {
          layout && Array.isArray(layout) && layout.map((o, i) => {
            // see if object is a group or already a row
            let label = '';
            let rows = [];

            const isGroup = Object.keys(o).length === 2 && o.label !== undefined && o.elements !== undefined;
            if (isGroup) {
              label = o.label;
              rows = o.elements;
            } else {
              rows = [o]; // convert to array again to process the same way as a group
            }

            const rowContent = rows.map((row) => {
              return (
                <Row className="thssjsf-field-group__fields-row" key={i}>
                  {
                    Object.keys(row).map((fieldName, i) => {
                      const { doShow, ...rowProps } = row[fieldName];

                      let style = {}
                      if (doShow && !doShow({ formData })) {
                        style = { display: 'none' }
                      }

                      if (schema.properties[fieldName]) {
                        if (viewmode) {
                          return (
                            <Col className="thssjsf-field-group__fields-col" {...rowProps} key={i} style={style}>
                              <div className="p-1 mb-2 bg-light border rounded px-3 py-2">
                                <small className="text-muted" style={{fontSize: '90%'}}>{schema.properties[fieldName].title}:</small><br />
                                <big style={{display: 'inline-block', minHeight: '1.25rem'}}>
                                  {
                                    (() => {
                                      // if dropdown, replace with option label
                                      if (schema.properties[fieldName].enum) {
                                        const fieldValue = formData[fieldName];

                                        const enumIndex = schema.properties[fieldName].enum.indexOf(fieldValue);

                                        return schema.properties[fieldName].enumNames[enumIndex];
                                      }

                                      // else, figure out by type
                                      switch (schema.properties[fieldName].type) {
                                        case 'boolean':
                                          return formData[fieldName] ? 'Yes' : 'No';
                                        case 'integer':
                                          return formData[fieldName] ? formData[fieldName].toLocaleString() : formData[fieldName];
                                        case 'number':
                                          return formData[fieldName] ? parseFloat(formData[fieldName]).toLocaleString() : formData[fieldName];
                                        default:
                                          return formData[fieldName];
                                      }
                                    })()
                                  }
                                </big>
                              </div>
                            </Col>
                          );
                        } else {
                          return (
                            <Col className="thssjsf-field-group__fields-col" {...rowProps} key={i} style={style}>
                              <SchemaField
                                fieldName={fieldName}

                                schema={schema.properties[fieldName]}
                                uiSchema={uiSchema[fieldName]}
                                errorSchema={errorSchema[fieldName]}
                                idSchema={idSchema[fieldName]}

                                formData={formData[fieldName]}

                                onChange={this.onPropertyChange(fieldName)}
                                onBlur={onBlur}

                                registry={this.props.registry}

                                required={this.isRequired(fieldName)}
                                disabled={disabled}
                                readonly={readonly}
                              />
                              {
                                errorSchema && errorSchema[fieldName] && Array.isArray(errorSchema[fieldName].__errors) && (
                                  <>
                                    {
                                      errorSchema[fieldName].__errors.map((errorMessage) => {
                                        return (
                                          <div className="thssjsf-field-group__error">
                                            <i className="fad fa-exclamation-triangle mr-2" />{errorMessage}
                                          </div>
                                        );
                                      })
                                    }
                                  </>
                                )
                              }
                            </Col>
                          );
                        }
                      } else {
                        const { render, ...rowProps } = row[fieldName];

                        let UIComponent = () => null;

                        if (render) {
                          UIComponent = render;
                        }

                        return (
                          <Col className="thssjsf-field-group__fields-col" {...rowProps} key={i} style={style}>
                            <UIComponent
                              fieldName={fieldName}

                              formData={formData}

                              schema={schema}
                              uiSchema={uiSchema}
                              errorSchema={errorSchema}

                              registry={this.props.registry}
                            />
                          </Col>
                        );
                      }
                    })
                  }
                </Row>
              );
            });

            return (
              <div className="thssjsf-field-group">
                {label && <h5 className="thssjsf-field-group__label">{label}</h5>}

                <div className="thssjsf-field-group__fields">
                  {rowContent}
                </div>
              </div>
            );
          })
        }</>
    )
  }
}

export default FieldGroup;