import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from '../../../components/lib/thss/Panel';

import { MultiSelect } from 'primereact/multiselect';

class StatesFilter extends React.Component {
  constructor(props) {
    super(props);

    // populate options
    this.options = this.props.states.map((state) => {
      return {
        label: state.name,
        value: state.code
      };
    });
  }

  handleStateChange = (state) => {
    if (this.props.onChange) this.props.onChange(state);
  }

  render() {
    return (
      <>
        {
          this.props.label && <p className="mt-0 mb-1 text-muted"><small>{this.props.label}</small></p>
        }

        <MultiSelect
          optionLabel='label'
          optionValue='value'
          placeholder="—"
          value={this.props.selected}
          options={this.options}
          filter={false}
          maxSelectedLabels={0}
          selectedItemTemplate={(option) => option ? option + ', ' : ''}
          selectedItemsLabel={(() => {
            const MAX_SHOWN = 10;

            if (!this.props.selected || !this.props.selected.length) {
              return 'Select state';
            }

            if (this.props.selected && this.props.states && this.props.selected.length == this.props.states.length) {
              return this.props.allLabel || 'All states';
            }

            let label = '';
            for (let i=0; i<this.props.selected.length; i++) {
              const stateCode = this.props.selected[i];

              if (i < MAX_SHOWN) {
                if (i) label += ', ';
                label += stateCode;
              } else {
                label += ' + ' + (this.props.selected.length - MAX_SHOWN) + ' more...';

                break;
              }
            };


            return label;
          })()}
          onChange={(e) => this.handleStateChange(e.value)}

          style={{width: '100%'}}
        />
      </>
    );
  }
}

export default StatesFilter;