import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { Rnd } from 'react-rnd';

const modalDiv = document.getElementById('portalModal');

class PortalModal extends React.Component {
  constructor(props) {
    super(props);
    this.container = document.createElement('div');

    this.maximizedLocalStorageKey = this.props.localStorageKey ?  `${this.props.localStorageKey}:isMaximized` : null;
    const maximized = window.localStorage.getItem(this.maximizedLocalStorageKey) || 'N';

    this.state = {
      x: 0,
      y: 0,
      show: true,
      maximized: maximized
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
    modalDiv.appendChild(this.container);
    this.resizeModal(this.state.maximized === 'Y' ? 1 : 0.6);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
    modalDiv.removeChild(this.container);
  }

  resizeModal = (widthMultipler=0.6, heightMultipler=1.0, reposition=true) => {
    const { windowWidth, windowHeight } = this.getWindowDimensions();
    const width = windowWidth * widthMultipler;
    const height = windowHeight * heightMultipler;

    if (reposition) {
      this.setState({
        x: Math.round(Math.max((windowWidth - width) / 2, 0)),
        y: Math.round(Math.max((windowHeight - height) / 2, 0))
      });
    }

    this.setState({
      width: Math.min(width, windowWidth),
      height: Math.min(height, windowHeight)
    });
  };

  getWindowDimensions = () => {
    const w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName("body")[0],
      windowWidth = w.innerWidth || e.clientWidth || g.clientWidth,
      windowHeight = w.innerHeight || e.clientHeight || g.clientHeight,
      docHasScrollbar = e.scrollHeight > e.clientHeight;

    return {
      windowWidth: windowWidth - (docHasScrollbar ? 15 : 0), // scrollbar correction
      windowHeight: windowHeight - (45 + 54 + 10) // Header and footer + room for resize handle
    };
  };

  handleWindowResize = () => {
    this.resizeModal(0.6, 1.0, false);
  };

  handleClose = () => {
    this.setState({
      show: false
    });

    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  handleMaximize = () => {
    const maximized = this.state.maximized == 'Y' ? 'N' : 'Y';
    if (maximized == 'Y') {
      this.oldPosition = {
        width: this.state.width,
        height: this.state.height,
        x: this.state.x,
        y: this.state.y
      };

      this.resizeModal(1);
    } else {
      if (this.oldPosition) {
        this.setState({
          x: this.oldPosition.x,
          y: this.oldPosition.y,
          width: this.oldPosition.width,
          height: this.oldPosition.height
        });
      }
    }

    this.setState({
      maximized: maximized
    }, () => {
      if (this.maximizedLocalStorageKey) {
        window.localStorage.setItem(this.maximizedLocalStorageKey, maximized);
      }
    });
  };

  render() {
    return this.state.show ?
      ReactDOM.createPortal(
        <div className="sbm-modal">
          <Rnd
            size={{ width: this.state.width, height: this.state.height }}
            position={{ x: this.state.x, y: this.state.y }}
            className={this.props.rndClass || 'bg-light border'}
            bounds="window"
            dragHandleClassName="modal-header"
            enableResizing={true}
            onDragStop={(e, d) => {
              this.setState({ x: d.x, y: d.y });
            }}
            onResize={(e, direction, ref, delta, position) => {
              const maximized = 'N';
              this.setState({
                width: ref.style.width,
                height: ref.style.height,
                maximized: maximized,
                ...position
              }, () => {
                if (this.maximizedLocalStorageKey) {
                  window.localStorage.setItem(this.maximizedLocalStorageKey, maximized);
                }
              });
            }}
          >
            <Modal.Header>
              <Modal.Title>
                <span className="h6">{this.props.title}</span>
                {this.props.titleActions}
              </Modal.Title>

              <div className="float-right">
                <Button variant="secondary" onClick={this.handleMaximize}>
                  <i className={`far ${this.state.maximized == 'Y' ? 'fa-window-restore' : 'fa-window-maximize'}`} />
                </Button>
                <Button variant="secondary" className="ml-2" onClick={this.handleClose}><i className="far fa-times" /></Button>
              </div>
            </Modal.Header>

            <Modal.Body>
              {this.props.children}
            </Modal.Body>
          </Rnd>
        </div>,
        this.container
      ) : null;
  }
}

export default PortalModal;