// core
import React from 'react';
import { Link } from 'react-router-dom'

// settings + utils
import moment from 'moment';
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';
import pluralize from 'pluralize';

import { Container, Row, Col, Button, Alert, Badge, Tooltip, Form } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';
import ProfilePicture from '../../components/shared/elems/ProfilePicture';

// lib
import NumberFormat from 'react-number-format';
import BaseDetailsPage from '../../pages/lib/BaseDetailsPage';
import Spinner from '../../components/lib/thss/Spinner';
import ObjectTypeItemsPanel from '../../components/lib/thss/json-schema-ui/ObjectTypeItemsPanel';

// shared
import PageHeaderWrapper from '../../components/shared/layout/PageHeaderWrapper';
import PanelHeaderWrapper from '../../components/shared/layout/PanelHeaderWrapper';
import PageContentWrapper from '../../components/shared/layout/PageContentWrapper';
import ButtonsToolbar from '../../components/shared/ButtonsToolbar';
import ValignMiddle from '../../components/lib/thss/ValignMiddle';
import FiltersToolbar from '../../components/shared/filters/FiltersToolbar';
import SlideDownTransition from '../../components/lib/thss/SlideDownTransition';
import MainHeading from '../../components/shared/MainHeading';
import ObjectTypePanel from '../../components/lib/thss/json-schema-form/ObjectTypePanel';
import FilterRestoreNotice from '../../components/shared/filters/FilterRestoreNotice';
import ListPageWrapper from '../../components/shared/ListPageWrapper';

// page-specific/etc.
import DataGrid from '../../components/lib/react-data-grid/lib/DataGrid';
import '../../components/lib/react-data-grid/dist/react-data-grid.css';

import ErrorPanel from '../../components/shared/ErrorPanel';
import Address from '../../components/shared/elems/Address';
import Person from '../../components/shared/elems/Person';
import Business from '../../components/shared/elems/Business';
import Note from '../../components/shared/elems/Note';
import BondFile from '../../components/shared/elems/BondFile';
import FileUploadForm from '../../components/shared/FileUploadForm';

import BondNotesPanel from '../../components/bonds/BondNotesPanel';
import AddBondNoteForm from '../../components/bonds/AddBondNoteForm';
import BondStatusTransitionsPanel from '../../components/bonds/BondStatusTransitionsPanel.js';
import BondLogEntry from '../../components/shared/elems/BondLogEntry';


export default class BondFilesPanel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filterValue: ''
    };
  }

  getPayload = (base64, file) => {
    if (!base64 || !file) {
      return null;
    }

    return {
      type: 'other',
      title: file.name,
      filename: file.name,
      file_size: file.size,
      base64_encoded_string: base64
    };
  }

  handleFilterClick = (filterValue) => {
    this.setState({
      filterValue: filterValue
    });
  }

  handleChange = (base64, file) => {
    const { typeData, data, formData, focus, fetching, error, onToggleEdit, onChange, onSave, user, permissions } = this.props;

    const payload = this.getPayload(base64, file);

    if (onChange) {
      onChange(payload, typeData.type_name);
    }
  }

  handleSubmit = () => {
    const { typeData, data, formData, focus, fetching, error, onToggleEdit, onChange, onSave, user, permissions } = this.props;

    if (onSave) {
      onSave(typeData.type_name, null); // there is no item ID since there is no editing (TODO: see if we need to add that)
    }
  }

  handleCancel = () => {
    const { typeData, data, formData, focus, fetching, error, onToggleEdit, onChange, onSave, user, permissions } = this.props;

    if (onToggleEdit) {
      onToggleEdit('', '');
    }
  }

  render() {
    const { typeData, data, formData, focus, fetching, saving, success, error, onToggleEdit, onChange, onSave, user, permissions, onShowBondChange } = this.props;

    if (fetching) {
      return '';
    }
    if (!typeData) {
      return <ErrorPanel errors={[{message: 'No type data'}]} />
    }

    const typeName = typeData.type_name;

    // get basic info about type
    const label = typeData.label;
    const schema = typeData.schema;
    const layout = typeData.formLayout;
    const min = typeData.min;
    const max = typeData.max;

    // prepare data
    const fileDataList = !data ? [] : data;
    const fileFileDataList = !formData ? [] : formData;

    // create list of file types to filter by type
    const fileTypesFilters = [];
    fileDataList.forEach(fileData => {
      if (!fileTypesFilters.find(f => f.value == fileData.type))  {
        const filterLabel = (fileData.type.charAt(0).toUpperCase() + fileData.type.slice(1)).replace('_', ' ');
        const filterValue = fileData.type;

        fileTypesFilters.push({
          label: filterLabel,
          value: filterValue
        });
      }
    });

    return (
      <>
        <ObjectTypeItemsPanel
          className={this.props.className || ''}
          style={this.props.style || {}}
          headerRenderer={(title) => {
            return (
              <h2 className="m-0 my-2">
                <div style={{display: 'inline-block', width: '1.5em'}}><i className={Settings.icons.objectTypes.uploads + ' text-muted'} />
                  { typeName == 'bond_private_file' && <span className="mr-2" style={{zoom: 0.6, marginLeft: '-0.75em'}}><i className="fad fa-lock " /></span> }
                </div>

                {title}

                {/* <span className="sbm-bond__include-bond-checkbox float-right">
                  <Form.Check.Input id={typeName + '_includeBondData'}
                    type="checkbox"
                    checked={!!this.props.showBondData}
                    onChange={(e) => onShowBondChange(e.target.checked)} />

                  <Form.Check.Label for={typeName + '_includeBondData'}>Show all</Form.Check.Label>
                </span> */}
              </h2>
            )
          }}

          typeName={typeName}
          label={label}
          schema={schema}
          layout={layout}

          data={fileDataList.filter(fileData => !this.state.filterValue || fileData.type == this.state.filterValue)}
          formData={fileFileDataList.filter(fileFormData => !this.state.filterValue || fileFormData.type == this.state.filterValue)}

          min={min}
          max={max}
          expandAdd={true}

          focus={focus}

          fetching={fetching}
          saving={saving}
          success={success}
          error={error}

          // filters={fileTypesFilters}
          // filterValue={this.state.filterValue}
          // onFilterClick={this.handleFilterClick}

          itemRenderer={(myTypeName, mySchema, myLayout, myItemData, isEditing, isFetching) => {
            if (!permissions.view) {
              return <p></p>; {/* just to make it look good */}
            }

            if (!myItemData) {
              console.warn('WARNING: no item data while getting sidebar item');

              return <p>WARNING: no item data while getting sidebar item</p>;
            }

            const embedLink = `${Settings.API_URL}/api/v1/bond-records/${myItemData.record_id}/files/${myItemData.is_private ? 'private' : 'public'}/${myItemData.id}?access_key=${this.props.accessKey || ''}&format=pdf`;
            const downloadLink = embedLink + '&download';

            return (
              <>
                <BondFile data={myItemData} embedLink={embedLink} downloadLink={downloadLink}/>
              </>
            );
          }}

          formRenderer={(myTypeName, mySchema, myLayout, myFormData, myItemId, onChange, onSubmit, isSaving, isSuccess, errorMessage) => {
            return (
              <>
                {
                  permissions.create ? (
                    <FileUploadForm
                      typeName={myTypeName}

                      formData={myFormData}

                      saving={isSaving}
                      success={isSuccess}
                      error={errorMessage}

                      onChange={this.handleChange}
                      onSubmit={this.handleSubmit}
                      onCancel={this.handleCancel}
                    />
                  ) : (
                    <p><i className="fad fa-info mr-2" />NOTE: you don't have permission to {permissions.view ? 'create' : 'view/create'} {label.toLowerCase()}.</p>
                  )
                }
              </>
            );
          }}

          onToggleEdit={onToggleEdit}
          onChange={onChange}
          onSave={onSave}
        />
      </>
    )
  }
}