import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from '../../../components/lib/thss/Panel';

import { MultiSelect } from 'primereact/multiselect';

class BondAssignmentsFilter extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange = (usernames) => {
    if (this.props.onChange) this.props.onChange(usernames);
  }

  render() {
    if (!this.props.teammates || !Array.isArray(this.props.teammates)) {
      console.warn('No teammates, return early');

      return '';
    }

    const options = !this.props.teammates ? [] : [
      ...this.props.teammates.map((username) => {
        return {
          label: `${username} ${username == this.props.username ? ' (you)' : ''}`,
          value: username
        };
      }), {
        label: `${this.props.underwriter} (underwriter)`,
        value: this.props.underwriter
      }
    ];

    if (!options.length) {
      return '';
    }

    return (
      <>
        {
          this.props.label && <p className="mt-0 mb-1 text-muted"><small>{this.props.label}</small></p>
        }

        <MultiSelect
          optionLabel='label'
          optionValue='value'
          placeholder='—'
          value={this.props.selected}
          options={options}
          filter={false}
          maxSelectedLabels={0}
          selectedItemTemplate={(option) => option ? option + ', ' : ''}
          selectedItemsLabel={(() => {
            const MAX_SHOWN = 10;

            if (!this.props.selected || !this.props.selected.length) {
              return 'Select teammate/underwriter';
            }

            if (this.props.selected && options && this.props.selected.length == options.length) {
              return this.props.allLabel || 'Anyone';
            }

            let label = '';
            for (let i=0; i<this.props.selected.length; i++) {
              const username = this.props.selected[i];
              const option = options.find((option) => option.value == username)

              if (i < MAX_SHOWN) {
                if (i) label += ', ';
                label += option ? option.label : '';
              } else {
                label += ' + ' + (this.props.selected.length - MAX_SHOWN) + ' more...';

                break;
              }
            };


            return label;
          })()}
          onChange={(e) => this.handleChange(e.value)}

          style={{width: '100%'}}
        />
      </>
    );
  }
}

export default BondAssignmentsFilter;