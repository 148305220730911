const key = 'gig_portal_auth_access_token_20200430';

const Auth = {
  getToken() {
    const token = ('; ' + document.cookie).split('; ' + key + '=').pop().split(';').shift();

    return token;
  },

  setToken(token) {
    if (token) {
      document.cookie = key + '=' + token + '; expires=01 Jan 2050 00:00:00 UTC; path=/;';
    } else {
      document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
  }
}

export default Auth;