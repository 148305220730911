// core
import React from 'react';
import { Link } from 'react-router-dom';

// settings + utils
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from '../../components/lib/thss/Panel';

// shared
import PageHeaderWrapper from '../../components/shared/layout/PageHeaderWrapper';
import PageContentWrapper from '../../components/shared/layout/PageContentWrapper';

// page-specific/etc.
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

class ListPageWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.DEFAULT_PAGINATION_ITEMS = [25, 50, 100, 250, 500];

    this.state = {
      searchChangeTimeout: null,
      searchSubmitTimeout: null
    };
  }

  componentDidMount() {

  }
  __handleSearchChange = (e) => {
    const value = e.currentTarget.value;

    window.clearTimeout(this.state.searchChangeTimeout);

    if (this.props.search.onChange) {
      const timeout = window.setTimeout(() => {
        this.props.search.onChange(value, e);
      }, 350);

      this.setState({
        searchChangeTimeout: timeout
      });
    }
  }

  handleSearchSubmit = (e) => {
    const value = e.currentTarget.value;

    window.clearTimeout(this.state.searchSubmitTimeout);

    if (this.props.search.onChange) {
      const timeout = window.setTimeout(() => {
        this.props.search.onChange(value, e);
      }, 350);

      this.setState({
        searchSubmitTimeout: timeout
      });
    }
  }

  render() {
    // pagination
    const paginationSettings = this.props.pagination;
    let paginationItems = [];
    if (paginationSettings) {
      // cast to correct datatypes, just in case
      paginationSettings.totalCount = parseInt(paginationSettings.totalCount);
      paginationSettings.paginationSize = parseInt(paginationSettings.paginationSize);
      paginationSettings.currPage = parseInt(paginationSettings.currPage);

      paginationItems = this.DEFAULT_PAGINATION_ITEMS;
    }

    const currPageFrom = (paginationSettings.currPage - 1) * paginationSettings.paginationSize + 1;
    let currPageTo = Math.min(currPageFrom - 1 + paginationSettings.paginationSize, paginationSettings.totalCount);
    const hasPrev = currPageFrom != 1;
    const hasNext = currPageTo < paginationSettings.totalCount;

    const paginationCountElem = (
      <>
        <a href="javascript:void(0);" className={'btn  ' + (!hasPrev ? 'btn-secondary' : 'btn-primary') + ' mr-2 ' + (!hasPrev || this.props.status.fetching ? 'disabled' : '')} onClick={this.props.status.fetching ? null : this.props.pagination.onPagePrev}>
          <i className="fa fa-arrow-left" />
        </a>
        <strong>{currPageFrom}</strong>-<strong>{currPageTo}</strong> of <strong>{paginationSettings.totalCount}</strong>
        <a href="javascript:void(0);" className={'btn  ' + (!hasNext ? 'btn-secondary' : 'btn-primary') + ' ml-2 ' + (!hasNext || this.props.status.fetching ? 'disabled' : '')} onClick={this.props.status.fetching ? null : this.props.pagination.onPageNext}>
          <i className="fa fa-arrow-right" />
        </a>
      </>
    );

    return (
      <>
        <PageHeaderWrapper>
          <div className="">
            <Container fluid className="pt-3 pb-3">
              <Row>
                <Col xs={6}>
                  {this.props.header}
                </Col>
                <Col xs={6} className="text-right">
                  <table border="0" width="100%" height="100%">
                    <tr>
                      <td valign="middle" align="right">
                        {
                          this.props.data.onExport ? (
                            <span className="mr-3 pr-2 border-right">
                              <a href="javascript:void(0);" className={'btn  ' + (this.props.status.fetching ? 'btn-secondary' : 'btn-primary') + ' ml-2 ' + (this.props.status.fetching ? 'disabled' : '') + ' mr-2 align-middle'} onClick={this.props.data.onExport}><i className="fa fa-file-excel mr-1" /> Export</a>
                              <span className="align-middle"><input type="checkbox" /> all records</span>
                            </span>
                          ): ''
                        }

                        {paginationCountElem}
                      </td>
                    </tr>
                  </table>
                </Col>
              </Row>
            </Container>
          </div>

          <hr className="m-0" />

          <div className="">
            <Container fluid style={{paddingTop: '0.7rem', paddingBottom: '0.4rem'}}>
              <Row noGutters style={{borderColor: '#ccc'}}>
                <Col xs={(12 - this.props.filters.length * 2)}>
                  {
                    this.props.search && (
                      <div className="w-100 pr-5 float-left" style={{maxWidth: '600px'}}>
                        {
                          this.props.search.label && <p className="mt-0 mb-1 text-muted"><small>{this.props.search.label}</small></p>
                        }
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fa fa-search" /></span>
                          </div>

                          <input
                            type="text"
                            className="form-control input-lg"

                            onChange={this.__handleSearchChange}
                            onSubmit={this.handleSearchSubmit}
                          />
                        </div>
                      </div>
                    )
                  }
                </Col>
                {
                  this.props.filters && this.props.filters.length > 0 && this.props.filters.map((filter, i) => {
                    return (
                      <Col xs={2} className="pr-2 position-relative">
                        { filter }
                      </Col>
                    );
                  })
                }
              </Row>
            </Container>
          </div>
        </PageHeaderWrapper>

        <PageContentWrapper>
          <Container fluid className="mt-4">
            <Row>
              <Col xs={12}>
                <div style={this.props.status.fetching ? {opacity: 0.3} : {}}>
                  {this.props.children}
                </div>
              </Col>
            </Row>
          </Container>

          <Container fluid className="mt-4">
            <Row className="mt-2">
              <Col xs={2}>
                View: <select className="form-control" value={paginationSettings.paginationSize} onChange={this.props.pagination.onPageSizeChange}>
                  {
                    paginationItems.map((item) => {
                      return (
                        <option value={item}>{item}</option>
                      );
                    })
                  }
                </select>
              </Col>
              <Col xs={0} className="offset-4 text-right">
                {paginationCountElem}
              </Col>
            </Row>
          </Container>
        </PageContentWrapper>
      </>
    );
  }
}

export default ListPageWrapper;