import React from 'react';

import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import SlideDownTransition from '../../components/lib/thss/SlideDownTransition';

class ErrorPanel extends React.PureComponent {
  render() {
    return (
      <SlideDownTransition open={this.props.errors && this.props.errors.length > 0}>
        <Alert variant="danger"><h2><i className="fad fa-exclamation-triangle mr-2" />Sorry, something went wrong</h2>
          {
            this.props.errors.map((error) => {
              return <div>{JSON.stringify(error.message)}</div>
            })
          }
        </Alert>
      </SlideDownTransition>
    );
  }
}

export default ErrorPanel;