import React from 'react';

import AnimateHeight from 'react-animate-height';

class SlideDownTransition extends React.Component {
  constructor(props) {
    super(props);

    this.CLOSE_DURATION_MULTIPLIER = 1; // close faster/slower than opening?
  }

  render() {
    const height = this.props.open ? 'auto' : 0;

    let duration;
    let delay;
    let easing;

    if (this.props.open) {
      delay = this.props.delayOpen !== undefined ? this.props.delayOpen : this.props.delay !== undefined ? this.props.delay : 0;
      duration = this.props.durationOpen !== undefined ? this.props.durationOpen : this.props.duration !== undefined ? this.props.duration : 350;
      easing = this.props.easingOpen !== undefined ? this.props.easingOpen : this.props.easing !== undefined ? this.props.easing : 'cubic-bezier(0.905, 0.070, 0.480, 0.815)';
    } else {
      delay = this.props.delayClose !== undefined ? this.props.delayClose : this.props.delay !== undefined ? this.props.delay : 0;
      duration = this.props.durationClose !== undefined ? this.props.durationClose : this.props.duration !== undefined ? this.props.duration : 350;
      duration = duration * this.CLOSE_DURATION_MULTIPLIER;
      easing = this.props.easingClose !== undefined ? this.props.easingClose : this.props.easing !== undefined ? this.props.easing : 'cubic-bezier(0.905, 0.070, 0.480, 0.815)';
    }

    return (
      <AnimateHeight
        height={height}
        duration={duration}
        delay={delay}
        easing={easing}
      >
        {this.props.children}
      </AnimateHeight>
    );
  }
}

export default SlideDownTransition;