import React from 'react';

import { Container, Row, Col, Button, Badge} from 'react-bootstrap';
import ValignMiddle from '../../../components/lib/thss/ValignMiddle';
import ProfilePicture from './ProfilePicture';
import TimestampLabel from './TimestampLabel';

class Note extends React.PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="w-100 position-relative mt-1 mb-1">
        <p className="m-0">
          <div style={{display: 'inline-block', height: '2em', marginBottom: '-0.75em'}}><ProfilePicture username={data.created_by}className="pr-1 pb-2" /></div>
          <span className="text-dark mr-1">{data.created_by}</span>
          <small className="text-secondary">on <TimestampLabel utc={data.created_at} /></small>
        </p>

        <blockquote className="blockquote m-0">{data.note}</blockquote>
      </div>
    )
  }
}

export default Note;
















