import React from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';

/*
  To make this work intuitively for the user, we are modifying the core component.
  User might be typing a date, and it doesn't make sense to submit invalid dates while he's still typing.
  We're grabbing the onChange event internally, but we submit the value only when it make sense, e.g. after the user clicks on the calendar, on when the field loses focus

  Dates are represented in 2 different formats at any given time,
  1) ISO date, default value for HTML date inputs, ex.: YYYY-MM-DD
  2) user format, currently US time only, MM/DD/YYYY
  In addition, date picker use their own string to specify format, ex. "MM/dd/yyyy" instead of "MM/DD/YYYY" like MomentJS uses
*/

class DateField extends React.Component {
  constructor(props) {
    super(props);

    this.MOMENTJS_INPUT_FORMAT = 'MM/DD/YYYY'; // user can type in dates using this format
    this.MOMENTJS_VALUE_FORMAT = 'YYYY-MM-DD'; // value of the field (including "value" property and what we return)
    this.DATEPICKER_INPUT_FORMAT = 'MM/dd/yyyy'; // "MM/DD/YYYY" in datepicker's preferred format

    this.SUBMIT_DELAY = 100; // submit with a small delay to wait for setState()

    this.state = {
      fieldValue: this.props.value ? moment(this.getDateFromString(this.props.value, this.MOMENTJS_VALUE_FORMAT)).format(this.MOMENTJS_INPUT_FORMAT) : null, // curr value as raw string (MM/DD/YYYY)
    }
  }

  // checks whether date is valid date according to format
  isValidDate(dateStr, format) {
    const d = moment(dateStr, format, true);
    const valid = d.isValid();

    return valid;
  }

  // takes date string and returns date object according to format
  getDateFromString = (dateStr, format) => {
    if (!this.isValidDate(dateStr, format)) {
      console.warn('INVALID date', dateStr);

      return null;
    }

    const myDate = moment(dateStr, format, true).toDate();

    return myDate;
  }

  // takes date string and converts it to another format
  convertFormat = (dateStr, formatFrom, formatTo) => {
    if (!this.isValidDate(dateStr, formatFrom)) {
      console.warn('INVALID date', dateStr);

      return null;
    }

    return moment(dateStr, formatFrom, true).format(formatTo);
  }

  // handleChangeRaw gets called when typing in text field, returns raw field value
  handleChangeRaw = (fieldValue) => {
    if (fieldValue === undefined) { // if incorrectly triggered with cal click, ignore (this library sucks BTW)
      return;
    }

    // add slashes (unless we're deleting)
    if (!this.state.fieldValue || this.state.fieldValue.length <= fieldValue.length) {
      fieldValue = fieldValue.replace(/\D/g, '');
      let len = fieldValue.length;

      if (len > 1 && len < 4) {
        fieldValue = fieldValue.substring(0, 2) + '/' + fieldValue.substring(2, 3);
      } else if (fieldValue >= 4) {
        fieldValue = fieldValue.substring(0, 2) + '/' + fieldValue.substring(2, 4) + '/' + fieldValue.substring(4, len);
        fieldValue = fieldValue.substring(0, 10)
      }
    }

    this.setState({
      fieldValue: fieldValue
    });
  }

  // handleUpdateValue gets called when selecting date via calendar, or when typed in value looks like a date (even if invalid), returns date object
  handleUpdateValue = (date) => {
    if (date === null) {
      console.warn('WARNING: date is null, ignore input');

      return;
    }

    const invalidDate = isNaN(date.getTime());
    if (invalidDate) {
      console.warn('WARNING: date is invalid, ignore input');

      return;
    }

    const fieldValue = moment(date).format(this.MOMENTJS_INPUT_FORMAT);

    this.setState({
      fieldValue: fieldValue
    });
  };

  handleSubmit = () => {
    window.setTimeout(() => {
      if (!this.isValidDate(this.state.fieldValue , this.MOMENTJS_INPUT_FORMAT)) {
        console.warn('WARNING: date is invalid, ignore submit');

        return;
      }

      const yyyymmdd = this.convertFormat(this.state.fieldValue, this.MOMENTJS_INPUT_FORMAT, this.MOMENTJS_VALUE_FORMAT);
      if (this.props.onChange) {
        return this.props.onChange(yyyymmdd);
      }
    }, this.SUBMIT_DELAY);
  }

  render() {
    const currDate = this.state.fieldValue  && this.isValidDate(this.state.fieldValue , this.MOMENTJS_INPUT_FORMAT) ? this.getDateFromString(this.state.fieldValue, this.MOMENTJS_INPUT_FORMAT) : null;
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const isMobile = vw < 768;

    return (
        <DatePicker
          selected={currDate}
          value={this.state.fieldValue}
          placeholderText="MM/DD/YYYY"

          dateFormat={this.DATEPICKER_INPUT_FORMAT}
          strictParsing

          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          scrollableYearDropdown

          popperPlacement="top-start"
          popperModifiers={{
              flip: {
                  behavior: ["bottom"] // don't allow it to flip to be above
              },
              preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
              },
          }}

          withPortal={isMobile} // use modal on mobile
          onFocus={e => isMobile ? e.target.blur() : null} // disable typing on mobile

          onChange={this.handleUpdateValue}
          onChangeRaw={e => {this.handleChangeRaw(e.target.value);}}
          onBlur={(e) => null}
          onCalendarClose={this.handleSubmit}
        />
    );
  }
}

export default DateField;