import React from 'react';

import { Container, Row, Col, Button, Badge} from 'react-bootstrap';
import ValignMiddle from '../../../components/lib/thss/ValignMiddle';
import Settings from '../../../config';
import ProfilePicture from './ProfilePicture';
import TimestampLabel from './TimestampLabel';

class Assignment extends React.PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="w-100 position-relative m-0">
        <p className="m-0">
          <div style={{display: 'inline-block', height: '2em', marginBottom: '-0.75em'}}><ProfilePicture username={data.assigned_by} className="pr-1 pb-2" /></div>
          <span className="text-dark">{data.assigned_by}</span>

          <small className="text-secondary ml-2">on <TimestampLabel utc={data.created_at} /></small>
        </p>

        <div className="mt-2" style={{clear: 'both'}}>
          <blockquote className="blockquote m-0">{data.message}</blockquote>
        </div>

        <p className="mt-2 mb-0">
          {
            data.assigned_to == Settings.NOBODY_USER_USERNAME ? (
              <span className="text-muted">Marked as "no action required"</span>
            ) : (
              <>
                <span className="mr-2">Assigned to:</span>
                <div style={{display: 'inline-block', padding: '0.25em 0.5em', backgroundColor: 'rgba(225, 190, 100, 0.2', borderRadius: '0.5em'}}>
                  <div style={{display: 'inline-block', height: '2em', marginBottom: '-0.75em'}}><ProfilePicture username={data.assigned_to} className="pr-1 pb-2" /></div>
                  <span className="text-dark">{data.assigned_to}</span>
                </div>
              </>
            )
          }
        </p>
      </div>
    )
  }
}

export default Assignment;
















