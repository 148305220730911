// core
import React from 'react';
import { Link } from 'react-router-dom';

// settings + utils
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';

import { Container, Row, Col, Button, Alert, Badge, Card } from 'react-bootstrap';

import Panel from '../../components/lib/thss/Panel';

// lib
import moment from 'moment';
import BasePage from '../../pages/lib/BasePage';
import Spinner from '../../components/lib/thss/Spinner';

// shared
import ValignMiddle from '../../components/lib/thss/ValignMiddle';
import ProfilePicture from '../../components/shared/elems/ProfilePicture';
import PageHeaderWrapper from '../../components/shared/layout/PageHeaderWrapper';
import PageContentWrapper from '../../components/shared/layout/PageContentWrapper';
import ButtonsToolbar from '../../components/shared/ButtonsToolbar';
import FiltersToolbar from '../../components/shared/filters/FiltersToolbar';

import FilterRestoreNotice from '../../components/shared/filters/FilterRestoreNotice';
import MainHeading from '../../components/shared/MainHeading';
import ListPageWrapper from '../../components/shared/ListPageWrapper';

// page-specific/etc.
import BondRecordListDatagrid from '../../components/datagrids/BondRecordListDatagrid';
import NoRecordsMessage from '../../components/shared/NoRecordsMessage';

class Homepage extends BasePage {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      tasks: [],
      updates: [],
      drafts: []
    }
  }

  __init = (props, callback) => {
    this.__fetchAll(callback);
  }

  __fetchAll = (callback) => {
    // fetch tasks
    const tasks = `34867	9323	ann	Ann	pending documents from CNA	2020-06-24 22:10:05	2020-06-24 22:10:05
    34866	15436	ann	Ann	email AMS invoice to Danny when you get it.	2020-06-24 20:45:59	2020-06-24 20:45:59
    33665	19377	ann	Ann	working on increase. pending docs from the lawyer	2020-04-15 18:40:01	2020-04-15 18:40:01
    33578	19758	ann	Kelly	Please attach copy of rewrite bond to this file. 	2020-04-08 17:39:01	2020-04-08 17:39:01
    32969	19372	ann	Ann	working on quote	2020-03-03 19:58:33	2020-03-03 19:58:33
    32958	13875	ann	Ann	following up on social and Dob for renewal quote	2020-03-03 17:11:47	2020-03-03 17:11:47
    32957	12562	ann	Ann	following up on renewal payment	2020-03-03 17:10:42	2020-03-03 17:10:42
    32956	10199	ann	Ann	The client has to have a bond per Tampa Electric. Tampa Electric is reaching out to the client.	2020-03-03 17:09:50	2020-03-03 17:09:50
    32955	2320	ann	Ann	pending financials from Broker for renewal terms.	2020-03-03 17:08:43	2020-03-03 17:08:43
    32907	19340	ann	Dawn	Can you please review, looks like you were already working on a quote.	2020-03-02 18:20:05	2020-03-02 18:20:05
    32795	18771	ann	Ann	requote	2020-02-27 19:13:46	2020-02-27 19:13:46
    32792	1621	ann	Ann	working on renewal quote	2020-02-27 19:03:56	2020-02-27 19:03:56`.split("\n").map((line) => {
      const props = line.split("\t");

      return {
        // id: props[0],
        record_id: props[1],
        bond_status: 'DRAFT',
        assigned_to_username: props[2],
        assigned_to_email: `${props[2]}@suretybonds.market`,
        assigned_by_username: props[3],
        assigned_by_email: `${props[3].toLowerCase()}@suretybonds.market`,
        notes: props[4],
        date: props[5],
      }
    });

    let calls = [];
    const defaultOptions = {
      headers: {
        'Authorization': 'Bearer ' + Auth.getToken()
      }
    };

    // get drafts
    calls.push({
      method: 'GET',
      endpoint: Settings.API_URL + '/api/v1/bond-records',
      payload: {
        q: '',
        offset: 0,
        limit: 10,
        sort_by: 'id',
        sort_dir: 'desc'
      },
      options: {...defaultOptions},
      callback: (response, error) => {
        if (error || !response || !response.ok) {
          this.__setStatus(this.STATUS_ERROR);
          this.__setErrors([...this.__getErrors(), {
            message: response ? response.error : error
          }]);

          return;
        }

        this.__setPageData(response.data.drafts, 'drafts');
      }
    });

    // get updates
    calls.push({
      method: 'GET',
      endpoint: Settings.API_URL + '/api/v1/log/bonds',
      payload: {
        q: '',
        offset: 0,
        limit: 10,
        sort_by: 'id',
        sort_dir: 'desc'
      },
      options: {...defaultOptions},
      callback: (response, error) => {
        if (error || !response || !response.ok) {
          this.__setStatus(this.STATUS_ERROR);
          this.__setErrors([...this.__getErrors(), {
            message: response ? response.error : error
          }]);

          return;
        }

        this.__setPageData(response.data.updates, 'updates');
      }
    });

    this.__fetchMultiple(calls, (responses, errors) => {
      if (callback) callback();
    });
  }

  render() {
    const tasks = this.state.tasks;
    const updates = this.__getPageData('updates') || [];
    const drafts = this.__getPageData('drafts') || [];

    return (
      <>
        <PageHeaderWrapper>
          <Container fluid className="pt-3 pb-3">
            <Row>
              <Col xs={12}>
                <Row noGutters>
                  <Col xs={8}>
                    <MainHeading
                      icon="fad fa-house"
                      title="Home"
                      descr=""
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </PageHeaderWrapper>

        <PageContentWrapper>
          <Container fluid>
            <Row className="mb-4">
              <Col xs={6}>
                <h2 className="gig-admin-section-title float-left mt-0 mb-4"><i className={Settings.icons.objectTypes.task + ' mr-2'} />
                  Recent tasks
                </h2>
                {/* <Link className="float-right font-weight-normal" onClick={(e) => alert('takes to app list with assigned to selected')}>View all<i className="fad fa-arrow-right ml-1" /></Link> */}
                <div className="clearfix" />

                <h1 className="bg-secondary text-white rounded px-3 py-2 m-0">COMING SOON</h1>

                {/* {
                  tasks.map((task, i) => {
                    const relativeDate = moment.utc(task.date).fromNow();

                    return (
                      <Panel style={{height: '78px'}} noPadding className="py-2 px-3 mb-3">
                        <div className="float-left mr-3">
                          <ProfilePicture className="mt-1" username={task.assigned_by} />
                        </div>
                        <div className="float-left">
                          <strong>BondRecord #{task.record_id} </strong><small><Badge variant="secondary">Draft</Badge></small><br />
                          <big className="font-italic m-0">{task.notes}</big>
                          <div><small>Assigned by <strong>{task.assigned_by_username}</strong> {relativeDate}</small></div>
                        </div>

                        <Button variant="light" className="float-right mt-3" to={'/quotes/-/new/' + task.record_id + '/'}>view application <i className="fad fa-arrow-right ml-1" /></Button>

                        <div className="clearfix" />
                      </Panel>
                    );
                  })
                } */}
              </Col>
              <Col xs={6}>
                <h2 className="gig-admin-section-title float-left mt-0 mb-4"><i className={Settings.icons.objectTypes.updates + ' mr-2'} />
                  Latest updates
                </h2>
                {/* <Link className="float-right font-weight-normal" onClick={(e) => alert('takes to system logs (?)')}>View all<i className="fad fa-arrow-right ml-1" /></Link> */}
                <div className="clearfix" />

                {
                  updates.map((update) => {
                    const relativeDate = moment.utc(update.created_at).fromNow();
                    const iconClass = Settings.icons.logEntryTypes[update.event_type];

                    return (
                      <Panel style={{height: '78px'}} noPadding className="py-2 px-3 mb-3">
                        <div className="float-left">
                          <i className={`fad ${iconClass} text-primary h1 mt-0 mt-3 ml-3 mr-4`} />
                        </div>
                        <div className="float-left">
                          <strong>Application #{update.record_id}</strong>
                          <small className="ml-1">
                            {update.prev_status_name && update.prev_status_name != update.status_name ? <><Badge variant="secondary">{update.prev_status_name}</Badge><i className="fad fa-arrow-right ml-2 mr-2" /></> : ''}<Badge variant="secondary">{update.status_name}</Badge>
                          </small><br />
                          <big className="font-italic m-0">{update.message || 'No message'}</big>
                          <div><small>{relativeDate} by {update.created_by}</small></div>
                        </div>

                        <Link to={`/applications/${update.record_id}/`}><Button variant="light" className="float-right mt-3">view application <i className="fad fa-arrow-right ml-1" /></Button></Link>

                        <div className="clearfix" />
                      </Panel>
                    )
                  })
                }
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs={12}>
                <Panel>
                  <h2 className="gig-admin-section-title float-left mt-0 mb-3"><i className={Settings.icons.objectTypes.report + ' mr-2'} />
                    Sales report
                  </h2>
                  <Link className="float-right font-weight-normal" to="/applications/">View all applications<i className="fad fa-arrow-right ml-1" /></Link>
                  <div className="clearfix" />

                  {/* <img width="65%" height="400px" src="/images/tmp/homepage-chart.png" /> */}
                  <h1 className="bg-secondary text-white rounded px-3 py-2 m-0">COMING SOON</h1>
                </Panel>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs={12}>
                <Panel  noPadding className="p-1">
                  <div className="p-3 pt-2">
                    <h2 className="gig-admin-section-title mt-0 mb-0"><i className={Settings.icons.objectTypes.bond_record + ' mr-2'} />
                      Recent applications
                    </h2>

                    {
                      drafts.length == 0 && (
                        <p className="mt-4"><Spinner message="Fetching..." /></p>
                      )
                    }
                  </div>

                  {
                    drafts && drafts.length > 0 ? (
                      <BondRecordListDatagrid
                        rows={drafts}

                        licensedStates={this.props.appData.permissions.licensed_states}
                        productTypesById={this.props.appData.productTypesById}

                        sortBy={'id'}
                        sortDir={'desc'}
                        onSort={null}
                      />
                    ) : <NoRecordsMessage />
                  }
                </Panel>
              </Col>
            </Row>
          </Container>
        </PageContentWrapper>
      </>
    );
  }
}

export default Homepage;