import React from 'react';

import { Container, Row, Col, Card } from 'react-bootstrap';

// base components
import Panel from '../../lib/thss/Panel';
import Spinner from '../../lib/thss/Spinner';

import styles from './BondFormPanel.module.css';

class BondFormPanel extends React.PureComponent {
  render() {
    return (
      <div className={`${styles.panel} ${this.props.disabled ? styles.disabled : ''} ${this.props.className ? this.props.className : ''}`}>
        <Row noGutters>
          <Col xs={8}>
            <h2 className="text-left"><span className="text-muted">{this.props.step}.</span> {this.props.title}</h2>
          </Col>
          <Col xs={4} className="text-right">
            <div style={{zoom: 1.10}}>
              { this.props.fetching && <Spinner message="" /> }
              {/* { this.props.completed && <span className="text-success"><i className="fad fa-check" /> Completed</span> } */}
              { this.props.completed && (this.props.onEditClick !== undefined && this.props.onEditClick !== null) && <a className="btn btn-sm btn-outline border-dark" href="javascript:void(0);" onClick={this.props.onEditClick}><i className="fad fa-pencil" /> Edit</a> }
              { this.props.error && <span className="text-danger"><i className="fad fa-exclamation-triangle" /> Error</span> }
            </div>
          </Col>
        </Row>

        { this.props.children }

        <hr className="mt-0" />
      </div>
    );
  }
}

export default BondFormPanel;