import React from 'react';

import moment from 'moment';

export default class TimestampLabel extends React.PureComponent {
  render() {
    const TZ = 'America/New_York';
    let format = '';
    if (this.props.dateOnly) {
      format = 'll';
    } else if (this.props.timeOnly) {
      format = 'h:mm A';
    } else {
      format = 'll h:mm A';
    }

    const dateUTC = this.props.utc;
    const dateLocal = moment(dateUTC, moment.ISO_8601).tz(TZ);
    const relativeDate = dateLocal.fromNow();

    return (
      <>
        {dateLocal.format(format)}
        {this.props.dateOnly == false && <> <small>({relativeDate})</small></>}
      </>
    )
  }
}