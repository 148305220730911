// core
import React from 'react';
import { Link } from 'react-router-dom'

// settings + utils
import moment from 'moment';
import pluralize from 'pluralize';

import { Container, Row, Col, Button, Alert, Badge, Tooltip } from 'react-bootstrap';

// lib
import Spinner from '../Spinner';
import Panel from '../Panel';
import SlideDownTransition from '../SlideDownTransition';

// shared
import ValignMiddle from '../ValignMiddle';

import ObjectTypePanel from '../json-schema-form/ObjectTypePanel';

export default class ObjectTypeItemsPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adding: false,
      saving: false
    };
  }

  handleFilterClick = (filterValue) => {
    return this.props.onFilterClick(filterValue);
  }

  renderItem = (typeName, schema, layout, itemData, editing, fetching) => {
    if (this.props.itemRenderer) {
      return this.props.itemRenderer(typeName, schema, layout, itemData, editing, fetching);
    }

    return (
      <ObjectTypePanel
        typeName={typeName}
        schema={schema}
        layout={layout}
        data={itemData}

        ready={true}
        focused={false}
      />
    );
  }

  renderForm = (typeName, schema, layout, formData, itemId, onChange, onSubmit, saving, success, error) => { // TODO: why is "error" a string instead of a bool like success/saving?
    if (this.props.formRenderer) {
      return this.props.formRenderer(typeName, schema, layout, formData, itemId, onChange, onSubmit, saving, success, error);
    }

    return (
      <>
        <ObjectTypePanel
          typeName={typeName}
          schema={schema}
          layout={layout}
          data={formData}

          ready={true}
          focused={true}

          onChange={(formData, errors) => {
            onChange(formData, typeName);
          }}
        />
        {
          error && (
            <big className="text-danger"><i className="fad fa-exclamation-triangle mr-1" /> Sorry, something went wrong</big>
          )
        }
        {
          saving && (
            <big><i className="fad fa-circle-notch fa-spin mr-1" /> Saving changes...</big>
          )
        }
        {
          success && (
            <big className="text-success"><i className="fad fa-check mr-1" /> Changes saved</big>
          )
        }
        {
          !error && !saving && !success && (
            <p><Button variant="primary" onClick={(e) => this.handleSave(typeName, itemId)}><i className="fad fa-save mr-1" />Save changes</Button> or <a href="javascript:void(0)" onClick={(e) => this.handleToggleEdit('', '')}>cancel</a></p>
          )
        }
      </>
    );
  }

  handleToggleEdit = (typeName, id) => {
    this.props.onToggleEdit(typeName, id);
  }

  handleChange = (formData, typeName, isArray=false, arrayIndex=0, arrayUnset=false, callback) => {
    if (!this.props.onChange) {
      console.warn('No onChange callback for ' + typeName);

      return;
    }

    this.props.onChange(formData, typeName, isArray, arrayIndex, arrayUnset, callback);
  }

  handleSave = (typeName, itemId) => {
    this.props.onSave(typeName, itemId);
  }

  handlePanelHeaderClick = () => {
    const { typeName } = this.props;
    
    if (typeof this.props.onHeaderClick === 'function') {
      this.props.onHeaderClick(typeName);
    }
  }

  render() {
    const {
      typeName,
      label,
      schema,
      layout,

      data,
      formData,

      min,
      max,
      disableEditing,
      expandAdd,

      focus,
      fetching,
      saving,
      success,
      error,

      insertFormOnTop
    } = this.props;

    // data could be a single item, or an array. for simplicity, let's treat everything as an array, but let's pass the flag isArray
    const isArray = Array.isArray(data);
    const dataArray = !data ? [] : (!isArray ? [data] : data);
    const formDataArray = !formData ? [] : (!isArray ? [formData] : formData);

    // UI flags
    const allowAdding = !fetching && dataArray.length < max;
    const adding = focus.typeName == typeName && !focus.itemId;
    const enableAddButtons = allowAdding && !adding && !focus.itemId;
    const enableEditButtons = !focus.typeName && !disableEditing;

    const addPanel = (
      <Row noGutters>
        <Col xs={12}>
          <SlideDownTransition open={allowAdding && !expandAdd}>
            <hr />
            <Button variant="light" className={!enableAddButtons ? 'disabled' : ''} onClick={e => enableAddButtons && this.handleToggleEdit(typeName, null)}><i className="fad fa-plus mr-1" />Add {label.toLowerCase()}</Button>
          </SlideDownTransition>

          <SlideDownTransition open={adding || expandAdd}>
            { !insertFormOnTop && <hr /> }
            {
              this.renderForm(typeName, schema, layout, formDataArray[dataArray.length], null, (formData, typeName) => this.handleChange(formData, typeName, true, dataArray.length, false, null), (typeName, itemId) => this.handleSave(typeName, itemId), saving, success, error)
            }
          </SlideDownTransition>
        </Col>
      </Row>
    );

    const filters = !this.props.filters ? null : [
      {
        label: 'All',
        value: ''
      },
      ...this.props.filters
    ];

    return (
      <Panel
        headerRenderer={typeof this.props.headerRenderer === 'function' ? (title) => this.props.headerRenderer(title) : null}

        focused={focus.typeName == typeName}
        title={!fetching && label.toLowerCase().slice(-1) != 's' ? pluralize(label, dataArray.length) : label}

        maxHeight={null}
        className={`${this.props.className}`}
        style={this.props.style || {}}

        collapsable={this.props.collapsable}
        collapsed={this.props.collapsed}
        onHeaderClick={this.handlePanelHeaderClick}
      >
        { this.props.topSectionRenderer && this.props.topSectionRenderer() }

        { insertFormOnTop && addPanel }

        <div>
          {
            filters && (
              <p>
                {
                  filters.map((filter) => {
                    return (
                      <a href="javascript:void(0)" className="mr-2" onClick={() => this.handleFilterClick(filter.value)}>
                        <Badge variant={this.props.filterValue == filter.value ? 'primary' : 'secondary'}>{filter.label}</Badge>
                      </a>
                    );
                  })
                }
              </p>
            )
          }
          {
            dataArray.length == 0 && (
              <>
                { fetching ? <Spinner message="Fetching..." /> : <p className="text-secondary"><i className="fad fa-slash mr-2" />No data</p> }
              </>
            )
          }
          {
            dataArray.length > 0 && Array.from(Array(dataArray.length).keys()).map((i) => {
              const editing = focus.typeName == typeName && focus.itemId == dataArray[i].id;
              const itemData = dataArray[i];
              const itemFormData = formDataArray[i];

              return (
                <>
                  <SlideDownTransition open={editing}>
                    <Row noGutters>
                      <Col xs={12}>
                        <div className="w-100">
                          {
                            this.renderForm(typeName, schema, layout, itemFormData, itemData ? itemData.id : null, (itemFormData, typeName) => this.handleChange(itemFormData, typeName, true, i, false, null), (typeName, itemId) => this.handleSave(typeName, itemId), saving, success, error)
                          }
                        </div>
                      </Col>
                    </Row>
                  </SlideDownTransition>
                  <SlideDownTransition open={!editing}>
                    <Row noGutters>
                      <Col xs={12}>
                        <div className="position-relative">
                          {
                            this.renderItem(typeName, schema, layout, itemData, editing, fetching)
                          }

                          {
                            this.props.onToggleEdit && (
                              <ValignMiddle>
                                <div className="text-right" style={{position: 'absolute', top: '50%', right: '0px', transform: 'translateY(-50%)'}}>
                                  <Button variant="light" className={!enableEditButtons ? 'disabled' : ''} onClick={e => enableEditButtons && this.handleToggleEdit(typeName, itemData.id)}><i className="fad fa-pencil mr-1" />Edit</Button>
                                </div>
                              </ValignMiddle>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                  </SlideDownTransition>

                  {i + 1 < dataArray.length && <hr />}
                </>
              );
            })
          }
        </div>

        { !insertFormOnTop && addPanel }
      </Panel>
    );
  }
}