// core
import React from 'react';
import { Link } from 'react-router-dom';

// settings + utils
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';

import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';
import NumberFormat from 'react-number-format';

// shared
import BaseListPage from '../../pages/lib/BaseListPage';
import Spinner from '../../components/lib/thss/Spinner';
import NoRecordsMessage from '../../components/shared/NoRecordsMessage';

import ButtonsToolbar from '../../components/shared/ButtonsToolbar';
import FiltersToolbar from '../../components/shared/filters/FiltersToolbar';
import MainHeading from '../../components/shared/MainHeading';
import FilterRestoreNotice from '../../components/shared/filters/FilterRestoreNotice';
import ListPageWrapper from '../../components/shared/ListPageWrapper';

// page-specific/etc.
import StatesFilter from '../../components/shared/filters/StatesFilter';
import ProductTypesFilter from '../../components/shared/filters/ProductTypesFilter';
import ProductListDatagrid from '../../components/datagrids/ProductListDatagrid';

class ProductListPage extends BaseListPage {
  constructor(props) {
    super(props);

    this.FILTERS_PRESELECT_ALL = false;

    this.state = {
      ...this.state,

      hideUnlicensed: true
    }
  }

  __init = (props, callback) => {
    this.__setFiltersBackupKey('products-overview');

    this.__fetchAll(callback);
  }

  __getSortBy = () => {
    return 'state_code,name';
  }
  __getSortDir = () => {
    return 'asc,asc';
  }

  handleToggleClick = (e) => {
    this.setState({
      hideUnlicensed: !this.state.hideUnlicensed
    }, () => {
      let stateNames = [];

      if (this.state.hideUnlicensed) {
        stateNames = this.props.appData.permissions.licensed_states;
      } else {
        stateNames = this.props.appData.states.map(state => state.code);
      }

      this.__setFilters(stateNames, false, 'states', () => {
        this.__refreshData();
      });
    });
  }

  __fetchAll = (callback) => {
    this.__setStatus(this.STATUS_FETCHING);

    // fetch types first (needed for setting initial filters)
    this.__fetch('GET', Settings.API_URL + '/api/v1/product-types', {}, {
      headers: {
        'Authorization': 'Bearer ' + Auth.getToken()
      }
    }, (response, error) => {
      if (error || !response || !response.ok) {
        this.__setStatus(this.STATUS_ERROR);
        this.__setErrors([...this.__getErrors(), {
          message: response ? response.error : error
        }]);

        return;
      }

      // save items
      this.__setPageData(response.data, 'product_types');

      // __init filters
      this.__setFilters(this.props.appData.permissions.licensed_states, false, 'states');

      if (this.FILTERS_PRESELECT_ALL) {
        this.__setFilters(this.props.appData.productTypes.map(type => type.id), false, 'product_types');
      }

      // __init pagination
      this.__setPageSize(this.DEFAULT_PAGINATION_SIZE);
      this.__setCurrPage(this.DEFAULT_PAGINATION_START);
      this.__setSortBy('name');
      this.__setSortDir(this.DEFAULT_SORT_DIR);

      // finally, fetch products
      this.fetchProducts(() => this.__setStatus(this.STATUS_READY));
    });
  };

  fetchProducts = (callback) => {
    this.__fetch('GET',  Settings.API_URL + '/api/v1/products?types=' + (this.__getFilters('product_types') ? this.__getFilters('product_types').join(',') : '') + '&states=' + (this.__getFilters('states') ? this.__getFilters('states').join(',') : '') + '&q=' + (this.__getSearchKey() ? this.__getSearchKey() : '') + '&offset=' + this.__getOffset() + '&limit=' + this.__getLimit() + '&sort_by=' + this.__getSortBy() + '&sort_dir=' + this.__getSortDir(), {}, {
      headers: {
        'Authorization': 'Bearer ' + Auth.getToken()
      }
    }, (response, error) => {
      if (error || !response || !response.ok) {
        this.__setStatus(this.STATUS_ERROR);
        this.__setErrors([...this.__getErrors(), {
          message: response ? response.error : error
        }]);

        return;
      }

      // save items
      this.__setTotalCount(response.total_count);
      this.__setPageData(response.data.products, 'products', () => {
        if (callback) callback();
      });
    });
  }

  __refreshData = () => {
    this.__abortAll();

    this.__setStatus(this.STATUS_FETCHING);

    this.fetchProducts(() => this.__setStatus(this.STATUS_READY));
  }

  render() {
    const items = this.__getPageData('products');

    return (
      <>
        <ListPageWrapper
          header={
            <MainHeading
              icon="fad fa-search"
              title="Find bonds"
            >
              {this.__getStatus() == this.STATUS_FETCHING && <Spinner message="Fetching..." />}
              {/* <img style={{position: 'absolute', top: '28px', left: '-15px', height: '50px', width: 'auto'}} src="/images/shared/arrow-down.png" /> */}
            </MainHeading>
          }
          status={{
            fetching: this.__getStatus() == this.STATUS_FETCHING,
            errors: this.__getErrors()
          }}
          searchKey={this.__getSearchKey()}
          search={{
            label: 'Search results:',
            placeholder: '',

            onChange: (searchKey) => this.__handleSearchChange(searchKey),
            onSubmit: (searchKey) => this.__handleSearchChange(searchKey),
          }}
          filters={[
            <StatesFilter
              label="Filter by state:"
              allLabel="All states"
              states={this.props.appData.states}
              selected={this.__getFilters('states')}

              onChange={(states) => this.__handleFilterChange(states, 'states')}
            />,
            <ProductTypesFilter
              label="Filter by product type:"
              allLabel="All types"
              types={this.props.appData.productTypes}
              selected={this.__getFilters('product_types')}

              onChange={(productTypes) => this.__handleFilterChange(productTypes, 'product_types', true)}
            />
          ]}
          pagination={{
            totalCount: this.__getTotalCount(),
            paginationSize: this.__getPageSize(),
            currPage: this.__getCurrPage(),

            onPageNext: this.__handlePageNext,
            onPagePrev: this.__handlePagePrev,
            onPageSizeChange: this.__handlePageSizeChange,
          }}
          data={{
            onExport: null
          }}
        >
          {
            this.__getStatus() == this.STATUS_ERROR && (
              <>
                <Alert variant="danger">
                  <h2>Sorry, an error occurred</h2>
                  <p>{this.__getErrors().map(error => <div>{error.message}</div>)}</p>
                </Alert>
              </>
            )
          }
          {
            items && items.length ? (
              <>
                {
                  this.__getStatus() != this.STATUS_FETCHING && (
                    <label className="m-0 p-0 float-left" style={{cursor: 'pointer'}} onClick={this.handleToggleClick}>
                      <input type="checkbox" className="mr-1" checked={this.state.hideUnlicensed}/>hide bonds that I'm not licensed to issue
                    </label>
                  )
                }
                <h3 className="gig-admin-section-title text-right m-0 p-0 float-right">Select your bond to start a new application <img style={{position: 'absolute', right: '30px', top: '35px', zIndex: 1, height: '2.5em', width: 'auto'}} src="/images/shared/arrow-down-right.png" /></h3>
                <div className="clearfix mb-4" />

                <ProductListDatagrid
                  rows={items}

                  licensedStates={this.props.appData.permissions.licensed_states}
                  productTypesById={this.props.appData.productTypesById}

                  sortBy={this.__getSortBy()}
                  sortDir={this.__getSortDir().toUpperCase()}
                  onSort={this.__handleSort}
                />
              </>
            ) : (
              <>
                { this.__getStatus() == this.STATUS_READY && <NoRecordsMessage /> }
              </>
            )
          }
        </ListPageWrapper>
      </>
    );
  }
}

export default ProductListPage;