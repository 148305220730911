import React from 'react';

import Gravatar from 'react-gravatar';

class ProfilePicture extends React.PureComponent {
  render() {
    return (
      <span className={this.props.className || ''} style={{...this.props.style, display: 'inline-block', verticalAlign: 'middle', position: 'relative', height: '100%', width: 'auto'}}>
        <span style={{display: 'inline-block', height: '100%', width: 'auto', borderRadius: '150%', overflow: 'hidden'}}>
          <Gravatar width="256" height="256" email={this.props.username || ''} style={{display: 'inline-block', height: '100%', width: 'auto', marginBottom: 0}} />
        </span>
      </span>
    )
  }
}

export default ProfilePicture;
















