// core
import React from 'react';
import { Link } from 'react-router-dom'

// settings + utils
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from '../../components/lib/thss/Panel';

// lib
import BasePage from '../../pages/lib/BasePage';
import Spinner from '../../components/lib/thss/Spinner';

// shared
import PageHeaderWrapper from '../../components/shared/layout/PageHeaderWrapper';
import PageContentWrapper from '../../components/shared/layout/PageContentWrapper';
import ButtonsToolbar from '../../components/shared/ButtonsToolbar';
import FiltersToolbar from '../../components/shared/filters/FiltersToolbar';

import FilterRestoreNotice from '../../components/shared/filters/FilterRestoreNotice';
import MainHeading from '../../components/shared/MainHeading';
import ListPageWrapper from '../../components/shared/ListPageWrapper';

// page-specific/etc.


class TasksPage extends BasePage {
  constructor(props) {
    super(props);
  }

  __init = (props, callback) => {
    if (callback) callback();
  }

  render() {
    return (
      <>
        <PageHeaderWrapper>
          <Container fluid className="pt-3 pb-3">
            <Row>
              <Col xs={12}>
                <Row noGutters>
                  <Col xs={8}>
                    <MainHeading
                      icon={Settings.icons.objectTypes.task}
                      title="My tasks"
                      descr=""
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </PageHeaderWrapper>

        <PageContentWrapper>
          <Container fluid>
            <Row>
              <Col xs={12}>
                <Panel>
                  <h1 className="bg-secondary text-white rounded px-3 py-2 m-0">COMING SOON</h1>
                </Panel>
              </Col>
            </Row>
          </Container>
        </PageContentWrapper>
      </>
    );
  }
}

export default TasksPage;