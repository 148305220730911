// core
import React from 'react';

// settings + utils
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';
import XHR from '../../utils/XHR';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from '../../components/lib/thss/Panel';

class BasePage extends React.Component {
  constructor(props) {
    super(props);

    this.STATUS_ERROR = -3;
    this.STATUS_FETCHING = -1;
    this.STATUS_BUSY = 1;
    this.STATUS_READY = 2;
    this.STATUS_SUCCESS = 3;

    this.state = {
      // XHR stuff
      _status: this.STATUS_READY,
      _queue: {},
      _errors: [],

      // this is the object(s) before any filtering/search/editing, etc.
      _fetchedItems: null,

      // current item(s)
      _pageData: null,

      // meta about item(s)
      _itemsMeta: null,

      // forms
      _formSchemasByType: {
        [this.DEFAULT_KEY]: null
      },
      _formLayoutsByType: {
        [this.DEFAULT_KEY]: null
      },
    };
  }

  componentDidMount() {
    this.__init(this.props, this.__afterInit);
  }

  componentWillReceiveProps(nextProps) {
    this.__init(nextProps, this.__afterInit);
  }

  componentWillUnmount() {
    this.__abortAll();
  }

  // handle data when loading page for the first time
  __init = (props, callback) => {
    console.error('Please implement __init() XHFGT67', this);

    if (callback) callback();
  }
  // additional operations after __init() runs
  __afterInit = () => {

  }
  // reload SOME data for the page, compared to __init() it only fetches fresh data, ignores caches etc. which might be in __init()
  __refreshData = () => {

  }

  // utilities
  __getParams = (props=this.props) => {
    if (!props.match || !props.match.params) {
      return {};
    }

    const id = props.match.params.id ? props.match.params.id.replace('-', '') : null;

    return {
      id
    };
  }

  // XHR
  __makeKey = (method, endpoint) => {
    return String(method).toUpperCase() + ' ' + String(endpoint).toLowerCase();
  }
  __abortByKey = (key) => {
    if (this.state._queue === undefined || this.state._queue[key] === undefined) {
      return false;
    }

    const controller = this.state._queue[key];
    if (!controller) {
      console.error('Controller ' + key + ' does not exist');
    }

    XHR.abort(controller);
  }
  __abortAll = () => {
    Object.keys(this.state._queue).forEach((key) => {
      this.__abortByKey(key);
    });
  }

  __fetch = (method, endpoint, payload, options, callback) => {
    return new Promise((resolve, reject) => {
      this.__abort(method, endpoint);

      const key = this.__makeKey(method, endpoint);
      const controller = XHR.fetch(method, endpoint, payload, options, (response, error) => {
        if (error) {
          // aborting call is not an error
          if (error.code == error.ABORT_ERR) {
            resolve(null);
          }

          // even if status code is wrong, returns response—if any—for legacy apps
          if (callback) {
            callback(response, error);
          }

          reject(error);
        }

        if (callback) {
          callback(response, error);
        }
        resolve(response);
      });

      this.setState((prevState) => {
        prevState._queue[key] = controller;

        return prevState;
      });
    });
  }

  __fetchMultiple = (calls, globalCallback) => {
    let allResponses = [];
    let allErrors = [];

    calls.forEach((call) => {
      (() => {
        const { method, endpoint, payload, options, callback } = call;

        this.__fetch(method, endpoint, payload, options, (response, error) => {
          if (response) {
            allResponses.push(response);
          }
          if (error) {
            allErrors.push(error);
          }

          if (callback) callback(response, error);

          // are we done?
          if (allResponses.length + allErrors.length == calls.length) {
            if (globalCallback) globalCallback(allResponses.length ? allResponses : null, allErrors.length ? allErrors : null);
          }
        });
      })();
    });
  }

  __abort = (method, endpoint) => {
    const key = this.__makeKey(method, endpoint);

    this.__abortByKey(key);
  }

  // status
  __setStatus = (status, callback) => {
    const valid = [
      this.STATUS_ERROR,
      this.STATUS_FETCHING,
      this.STATUS_BUSY,
      this.STATUS_READY,
      this.STATUS_SUCCESS
    ];

    if (valid.indexOf(status) === -1) {
      console.error('Invalid status');

      return false;
    }

    this.setState({
      _status: status
    }, () => {
      if (callback) callback();
    });
  }
  __getStatus = () => {
    return this.state._status;
  }

  // errors
  __setErrors = (errors) => {
    this.setState({
      _errors: errors
    });
  }
  __hasErrors = () => {
    return this.state._errors && this.state._errors.length > 0;
  }
  __getErrors = () => {
    return this.state._errors;
  }

  // page data
  __setPageData = (items, key='__DEFAULT__', callback) => {
    this.setState((prevState) => {
      if (!prevState._pageData) {
        prevState._pageData = {};
      }

      prevState._pageData[key] = items;

      return prevState;
    }, () => {
      if (callback) return callback();
    })
  }
  __getPageData = (key='__DEFAULT__', isArray=false, arrayIndex=0) => {
    if (!this.state._pageData) {
      return null;
    }

    let pageData = null;

    if (isArray) {
      pageData = this.state._pageData[key] !== undefined ? this.state._pageData[key][arrayIndex] : null;
    } else {
      pageData = this.state._pageData[key];
    }

    return pageData;
  }

  // type data

  // override for schema/layout in appData
  __setFormSchemaOverride = (data, typeName=this.DEFAULT_KEY, callback) => {
    this.setState((prevState) => {
      prevState._formSchemasByType[typeName] = data;

      return prevState;
    }, () => {
      if (callback) callback();
    });
  }
  __setFormLayoutOverride = (data, typeName=this.DEFAULT_KEY, callback) => {
    this.setState((prevState) => {
      prevState._formLayoutsByType[typeName] = data;

      return prevState;
    }, () => {
      if (callback) callback();
    });
  }
  // get form schema/layout from appData (or override if present)
  __getFormSchema = (typeName, relatedType='') => {
    // if schema override in state, return that
    if (this.state._formSchemasByType && this.state._formSchemasByType[typeName]) {
      return this.state._formSchemasByType[typeName];
    }

    // else, return schema from type in appData
    let typeData = null;
    if (relatedType) {
      typeData = this.props.appData.objectTypesByName[relatedType].related.find(type => type.type_name == typeName);
    } else {
      typeData = this.props.appData.objectTypesByName[typeName];
    }

    return typeData ? typeData.schema : null;
  }
  __getFormLayout = (typeName, relatedType='') => {
    // if layout override in state, return that
    if (this.state._formLayoutsByType && this.state._formLayoutsByType[typeName]) {
      return this.state._formLayoutsByType[typeName];
    }

    // else, return layout from type in appData
    let typeData = null;
    if (relatedType) {
      typeData = this.props.appData.objectTypesByName[relatedType].related.find(type => type.type_name == typeName);
    } else {
      typeData = this.props.appData.objectTypesByName[typeName];
    }

    return typeData ? typeData.formLayout : null;
  }

  __getTypeDataByName = (typeName, relatedType='') => {
    let typeData = null;

    if (relatedType) {
      typeData = this.props.appData.objectTypesByName[relatedType].related.find(type => type.type_name == typeName);
    } else {
      typeData = this.props.appData.objectTypesByName[typeName];
    }

    if (!typeData) {
      throw Error('no type data for type ' + typeName + (relatedType ? '/' + relatedType : ''));
    }
    // make sure we're using our methods to populate form schema/layout
    typeData.schema = this.__getFormSchema(typeName, relatedType);
    typeData.formLayout = this.__getFormLayout(typeName, relatedType);

    return typeData;
  }
}

export default BasePage;