// core
import React from 'react';
import { Link } from 'react-router-dom'

// settings + utils
import moment from 'moment';
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';
import pluralize from 'pluralize';

import { Container, Row, Col, Button, Alert, Badge, Tooltip } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';
import ProfilePicture from '../../components/shared/elems/ProfilePicture';

// lib
import NumberFormat from 'react-number-format';
import BaseDetailsPage from '../../pages/lib/BaseDetailsPage';
import Spinner from '../../components/lib/thss/Spinner';
import ObjectTypeItemsPanel from '../../components/lib/thss/json-schema-ui/ObjectTypeItemsPanel';

// shared
import PageHeaderWrapper from '../../components/shared/layout/PageHeaderWrapper';
import PanelHeaderWrapper from '../../components/shared/layout/PanelHeaderWrapper';
import PageContentWrapper from '../../components/shared/layout/PageContentWrapper';
import ButtonsToolbar from '../../components/shared/ButtonsToolbar';
import ValignMiddle from '../../components/lib/thss/ValignMiddle';
import FiltersToolbar from '../../components/shared/filters/FiltersToolbar';
import SlideDownTransition from '../../components/lib/thss/SlideDownTransition';
import MainHeading from '../../components/shared/MainHeading';
import ObjectTypePanel from '../../components/lib/thss/json-schema-form/ObjectTypePanel';
import FilterRestoreNotice from '../../components/shared/filters/FilterRestoreNotice';
import ListPageWrapper from '../../components/shared/ListPageWrapper';

// page-specific/etc.
import DataGrid from '../../components/lib/react-data-grid/lib/DataGrid';
import '../../components/lib/react-data-grid/dist/react-data-grid.css';

import ErrorPanel from '../../components/shared/ErrorPanel';
import Address from '../../components/shared/elems/Address';
import Person from '../../components/shared/elems/Person';
import Business from '../../components/shared/elems/Business';

class AgencyDetailsPage extends BaseDetailsPage {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state
    };
  }

  __init = (props, callback) => {
    const { id } = this.__getParams(props);

    // if page ID changed, reload data
    if (!this.__getPageData('agency') || this.__getPageData('agency').id != id) {
      this.__setStatus(this.STATUS_FETCHING);

      this.__fetchAll(id, () => {
        this.__setStatus(this.STATUS_READY);

        if (callback) callback();
      });
    }
  }

  __afterInit = () => {
    // silence is golden
  }

  __refreshData = () => {
    this.__abortAll();

    const { id } = this.__getParams(this.props);

    this.__setStatus(this.STATUS_FETCHING);

    this.__fetchAll(id, () => {
      this.__setStatus(this.STATUS_READY);
    });
  }

  __fetchAll = (id, callback) => {
    this.__fetchObjectTypeItems('agency', id, callback);
  }

  handleStatusTransition = (transition) => {
    const { id } = this.__getParams(this.props);

    this.__setStatus(this.STATUS_FETCHING);

    const method = 'PUT';
    const endpoint = `${Settings.API_URL}/api/v1/agencies/${id}/status`;
    const payload = {transition_name: transition.transition_name};

    this.__fetch(method, endpoint, payload, {
      headers: {
        'Authorization': 'Bearer ' + Auth.getToken()
      }
    }, (response, error) => {
      if (error || !response || !response.ok) {
        console.error('ERROR while saving in UDP', error, response);

        this.__setStatus(this.STATUS_ERROR);
        this.__setErrors([...this.__getErrors(), {
          message: response ? response.error : error
        }]);

        window.setTimeout(() => {
          this.__setStatus(this.STATUS_READY);
        }, 1500);

        return;
      }

      this.__refreshData();
    });
  }

  render() {
    const { id } = this.__getParams();

    const agency = this.__getPageData('agency');
    const agencyId = agency ? agency.id : '';
    const product = this.__getPageData('product');

    return (
      <>
        <PageContentWrapper>
          <Container fluid>
            <Row>
              <Col xs={7}>
                <Panel>
                  {/* Header */}
                  <PanelHeaderWrapper sticky={true}>
                    <Container fluid className="pt-3 pb-2">
                      {
                        agency && (
                          <>
                            <Row>
                              <Col xs={8}>
                                <MainHeading
                                  icon={Settings.icons.objectTypes.agency}
                                  title={agency.business_name}
                                  subtitle={''}
                                  descr=""
                                />
                              </Col>
                              <Col xs={4}>
                                <ValignMiddle className="text-right">
                                  {
                                    !this.__isItemFocused('agency') && <Button variant="light" className={this.__isItemFocused() ? 'disabled' : ''} onClick={(e) => this.__handleToggleEdit('agency', agencyId)}><i className="fad fa-pencil mr-2" />Edit</Button>
                                  }
                                  {
                                    this.__isItemFocused('agency', agencyId) && (
                                      <>
                                        {
                                          this.__getStatus() == this.STATUS_BUSY && <Spinner message="Saving changes..." />
                                        }
                                        {
                                          this.__getStatus() == this.STATUS_SUCCESS && <><i className="fad fa-check text-success" /> Changes saved</>
                                        }
                                        {
                                          this.__getStatus() == this.STATUS_READY &&  (
                                            <>
                                              <Button variant="primary" onClick={(e) => this.__handleSave('agency', agencyId, null, null)}><i className="fad fa-save mr-2" />Save changes</Button>
                                              <span className="ml-1">or <a href="javascript:void(0)" onClick={(e) => this.__handleToggleEdit('', '')}>cancel</a></span>
                                            </>
                                          )
                                        }
                                      </>
                                    )
                                  }
                                </ValignMiddle>
                              </Col>
                            </Row>
                          </>
                        )
                      }
                    </Container>
                  </PanelHeaderWrapper>

                  {/* Main messages (errors + status) */}
                  <div>
                    {
                      this.__hasErrors() && (
                        <>
                          <Alert variant="danger"><h2>Sorry, something went wrong</h2>
                            {
                              this.__getErrors().map((error) => {
                                return <div>{JSON.stringify(error.message)}</div>
                              })
                            }
                          </Alert>
                        </>
                      )
                    }
                  </div>

                  {/* Agency */}
                  <ObjectTypePanel
                    typeName={'agency'}
                    schema={this.__getFormSchema('agency')}
                    layout={this.__getFormLayout('agency')}

                    data={this.__isItemFocused('agency', agencyId) ? this.__getFormData('agency') : this.__getPageData('agency')}

                    ready={this.__getStatus() != this.STATUS_FETCHING}
                    focused={this.__isItemFocused('agency', agencyId)}

                    onChange={(formData, errors) => this.__setFormData(formData, 'agency')}
                  />
                </Panel>
              </Col>
              <Col xs={5}>
                {/* Sidebar */}
                {
                  [
                    'agency_address'
                  ].map((typeName) => {
                    const typeData = this.__getTypeDataByName(typeName, 'agency');
                    if (!typeData) {
                      return <p className="text-danger">Type {typeName} cannot be found</p>;
                    }

                    // get basic info about type
                    const label = typeData.label;
                    const schema = this.__getFormSchema(typeName, 'agency');
                    const layout = this.__getFormLayout(typeName, 'agency');
                    const min = typeData.min;
                    const max = typeData.max;

                    // get status information
                    const fetching = this.__getStatus() == this.STATUS_FETCHING;
                    const saving = this.__getFocusedItemTypeName() == typeName && this.__getStatus() == this.STATUS_BUSY;
                    const success = this.__getFocusedItemTypeName() == typeName && this.__getStatus() == this.STATUS_SUCCESS;
                    const error = ''; // TODO: handle errors here


                    return (
                      <ObjectTypeItemsPanel
                        typeName={typeName}
                        label={label}
                        schema={schema}
                        layout={layout}

                        data={this.__getPageData(typeName)}
                        formData={this.__getFormData(typeName)}

                        min={min}
                        max={max}
                        expandAdd={false}

                        focus={{
                          typeName: this.__getFocusedItemTypeName(),
                          itemId: this.__getFocusedItemId()
                        }}

                        fetching={fetching}
                        saving={saving}
                        success={success}
                        error={error}

                        itemRenderer={
                          [
                            'agency_address'
                          ].indexOf(typeName) === -1 ? null : (
                            (myTypeName, mySchema, myLayout, myItemData, isEditing, isFetching) => {
                            if (!myItemData) {
                              console.warn('WARNING: no item data while getting sidebar item');

                              return <p>WARNING: no item data while getting sidebar item</p>;
                            }

                            switch (myTypeName) {
                              case 'agency_address':
                                return <Address data={{
                                  mailing_street: myItemData.street,
                                  mailing_city: myItemData.city,
                                  mailing_zip: myItemData.zip,
                                  mailing_state: myItemData.state_code,

                                }} />;
                              default:
                                return 'UNKNOWN TYPE';
                            }
                          })
                        }

                        onToggleEdit={this.__handleToggleEdit}
                        onChange={this.__setFormData}
                        onSave={(typeName, itemId) => this.__handleSave(typeName, itemId, 'agency', agencyId, () => null)}
                      />
                    );
                  })
                }
              </Col>
            </Row>
          </Container>
        </PageContentWrapper>
      </>
    );
  }
}

export default AgencyDetailsPage;