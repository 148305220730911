import React from 'react';

import { Container, Row, Col, Alert } from 'react-bootstrap';
import Panel from '../../lib/thss/Panel';

// base components
import SlideDownTransition from '../../lib/thss/SlideDownTransition';
import ObjectTypePanel from '../../lib/thss/json-schema-form/ObjectTypePanel';

import BondFormPanel from './BondFormPanel';
import BondStepSubmitButton from './BondStepSubmitButton';
import Tooltip from './Tooltip';
import ClaimsFraudWarning from './ClaimsFraudWarning';

class BondFormStep extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  handleEditClick = () => {
    if (this.props.onEditClick) {
      const stepIndex = this.props.stepIndex;

      return this.props.onEditClick(stepIndex);
    }
  }
  handleFormChange = (formData, typeName, isArray=false, arrayIndex=0, arrayUnset=false) => {
    if (this.props.onChange) {
      return this.props.onChange(formData, typeName, isArray, arrayIndex, arrayUnset);
    } else {
      console.warn('WARNING: no form handler in bond form step');
    }
  }

  handleSkip= () => {
    if (this.props.onSkip) {
      const stepIndex = this.props.stepIndex;

      return this.props.onSkip(stepIndex);
    }
  }
  handleRestore= () => {
    if (this.props.onRestore) {
      const stepIndex = this.props.stepIndex;

      return this.props.onRestore(stepIndex);
    }
  }

  handleSubmit = () => {
    if (this.props.onSubmit) {
      const stepIndex = this.props.stepIndex;

      return this.props.onSubmit(stepIndex);
    }
  }

  render() {
    // global info
    const switchingSteps = this.props.switchingSteps;

    // basic step info
    const stepOptions = this.props.stepOptions;
    const stepTypes = this.props.stepTypes;
    const adjustableQuantityTypeNames = this.props.adjustableQuantityTypeNames;
    const adjustableQuantityLimit = this.props.adjustableQuantityLimit;
    const stepPos = this.props.stepPos;
    const skip = this.props.skip;
    const optional = this.props.optional;
    const stepTitle = this.props.stepTitle;
    const stepIntroDescr = this.props.stepIntroDescr;
    const tooltipTitle = this.props.tooltipTitle;
    const tooltipText = this.props.tooltipText;
    const tooltipComponent = this.props.tooltipComponent;

    // step status
    const fetching = this.props.fetching;
    const error = this.props.error;
    const ready = this.props.ready;
    const disabled = this.props.disabled;
    const focused = this.props.focused;
    const completed = this.props.completed;
    const numRequired = this.props.numRequired;

    const colors = this.props.colors;

    const getNumItems = (typeName) => {
      const MIN_ITEMS = 1;

      const { stepOptions } = this.props;

      const typeOptions = stepOptions.types.find(typeData => typeData.typeName == typeName);
      const formData = typeOptions ? typeOptions.formData : null;
      if (!formData) {
        console.warn('WARNING: no form data while getting items', typeName, stepOptions);

        return MIN_ITEMS;
      }

      return Array.isArray(formData) ? formData.length : MIN_ITEMS;
    };

    const handleAddItem = (typeName) => {
      const arrayIndex = getNumItems(typeName);

      this.handleFormChange({}, typeName, true, arrayIndex, false);
    };
    const handleRemoveItem = (typeName, arrayIndex) => {
      this.handleFormChange(null, typeName, true, arrayIndex, true);
    };

    // group types by adjustable and non-adjustable quantity. Adjustable quantity items are used for indemnitors, they're grouped together and get their own treatment. This pattern allows to mix adjustable and non-adjustable types in one step
    const fixedQuantityTypes = stepTypes.filter(typeOptions => adjustableQuantityTypeNames.indexOf(typeOptions.typeName) === -1);
    const adjustableQuantityTypes = stepTypes.filter(typeOptions => adjustableQuantityTypeNames.indexOf(typeOptions.typeName) !== -1);

    return (
      <BondFormPanel
        step={stepPos}
        title={stepTitle}

        fetching={fetching}
        ready={ready}
        disabled={disabled}
        focused={focused}
        completed={completed}
        error={error}

        className=""

        onChange={this.handleFormChange}
        onEditClick={this.props.onEditClick ? this.handleEditClick : null}
      >
        {
          stepIntroDescr && (
            <SlideDownTransition duration={this.ANIMATION_DURATION} open={focused && !skip && !fetching}>
              <Alert as="p" variant="info"><i className="fad fa-info mr-2" />
                {stepIntroDescr}
              </Alert>
            </SlideDownTransition>
          )
        }

        <SlideDownTransition duration={this.ANIMATION_DURATION} open={focused || completed}>
          <SlideDownTransition duration={this.ANIMATION_DURATION} open={skip && (focused || completed) && !fetching}>
            <p><i className="fad fa-check mr-2" />This step is not required for this application</p>
          </SlideDownTransition>
          <SlideDownTransition duration={this.ANIMATION_DURATION} open={!skip}>
            <>
              {
                fixedQuantityTypes.map((typeOptions) => {
                  const { typeName, formSchema, formLayout, formData } = typeOptions;

                  return (
                    <ObjectTypePanel
                      typeName={typeName}
                      schema={formSchema}
                      layout={formLayout}
                      data={formData}

                      ready={ready}
                      focused={focused}

                      onChange={(formData, errors) => this.handleFormChange(formData, typeName)}
                    />
                  );
                })
              }
              {
                adjustableQuantityTypes.length > 0 && adjustableQuantityTypes.map((typeOptions) => {
                  const { typeName, formSchema, formLayout, formData } = typeOptions;

                  const numItems = getNumItems(typeName);
                  const numItemsArray = numItems ? Array(numItems).fill('').map((_, idx) => idx) : []; // just the num indemnitors as an array, to make life easier with React

                  return (
                    <div className="animated  faster">
                      {
                        numItemsArray.map((x, i) => {
                          const n = i+1;
                          const itemData = typeof formData != 'undefined' && typeof formData[i] != 'undefined' ? formData[i] : {};

                          return (
                            <>
                              <h3>
                                <i className="fad fa-user mr-1" /> Item {n} of {numItems}
                                { focused && n > 1 && <button className="btn btn-outline btn-sm border-dark text-dark float-right" onClick={e => handleRemoveItem(typeName, i)}><i className="fad fa-trash mr-2" />Remove</button> }
                              </h3>

                              <ObjectTypePanel
                                typeName={typeName}
                                schema={formSchema}
                                layout={formLayout}
                                data={itemData}

                                ready={ready}
                                focused={focused}

                                onChange={(formData, errors) => this.handleFormChange(formData, typeName, true, i, false)}
                              />
                            </>
                          );
                        })
                      }
                    </div>
                  );
                })
              }
            </>
          </SlideDownTransition>
        </SlideDownTransition>

        {
          (tooltipTitle || tooltipText || tooltipComponent) && (
            <SlideDownTransition duration={this.ANIMATION_DURATION} delayOpen={this.TOOLTIP_DELAY} open={focused && ready && !switchingSteps}>
              <Tooltip colors={colors} placeholder={!ready}>
                <h4 className="text-white text-left">{tooltipTitle}</h4>
                <p>{tooltipText}</p>

                {
                  tooltipComponent ? (
                    <>
                      { tooltipComponent }
                    </>
                  ) : (
                    <p className="mt-4 mb-4">
                      <BondStepSubmitButton
                        numRequired={skip ? 0 : numRequired}
                        allowSkipping={optional}
                        skipped={skip}

                        allowAdding={adjustableQuantityTypes && adjustableQuantityTypes.length > 0 && numRequired === 0 && getNumItems(adjustableQuantityTypeNames[0]) < adjustableQuantityLimit}

                        onSkip={this.handleSkip}
                        onRestore={this.handleRestore}
                        onAddItem={e => handleAddItem(adjustableQuantityTypeNames[0])}
                        onSubmit={this.handleSubmit}
                      />
                    </p>
                  )
                }
              </Tooltip>
            </SlideDownTransition>
          )
        }
      </BondFormPanel>
    );
  }
}

export default BondFormStep;