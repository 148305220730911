import React from 'react';

import { Container, Row, Col, Button, Alert, Badge, Tooltip } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';
import ObjectTypePanel from '../../components/lib/thss/json-schema-form/ObjectTypePanel';

import ProfilePicture from '../../components/shared/elems/ProfilePicture';
import { templateSettings } from 'lodash-es';

import Settings from '../../config';

export default class AddBondAssignmentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        assigned_to: '',
        message: ''
      }
    };
  }

  handleTeammateChange = (e) => {
    const { typeName } = this.props;

    const username = e.currentTarget.value;

    this.setState((prevState) => {
      prevState.formData.assigned_to = username;

      return prevState;
    }, () => {
      if (!this.props.onChange) {
        console.warn('WARNING: no onChange prop for message form (will dump props)', this.props);

        return;
      }

      this.props.onChange(this.state.formData, typeName);
    });
  }

  handleChange = (e) => {
    const { typeName } = this.props;

    const message = e.currentTarget.value;

    this.setState((prevState) => {
      prevState.formData.message = message;

      return prevState;
    }, () => {
      if (!this.props.onChange) {
        console.warn('WARNING: no onChange prop for message form (will dump props)', this.props);

        return;
      }

      this.props.onChange(this.state.formData, typeName);
    });
  }

  handleSubmit = () => {
    const { typeName } = this.props;

    if (!this.props.onSubmit) {
      console.warn('WARNING: no onSubmit prop for message form (will dump props)', this.props);

      return;
    }

    const payload = {
      assigned_to: this.state.assignedTo,
      message: this.state.message
    };

    this.props.onSubmit(payload, typeName);

    this.setState({
      formData: {
        assigned_to: '',
        message: ''
      }
    });
  }

  render() {
    const { user, teammates, underwriter } = this.props;

    const canSubmit = this.state.formData.assigned_to || this.state.formData.message;

    return (
      <>
        <Row noGutters>
          <Col xs={3}>
            <label className="float-left form-control w-auto pl-0 border-0 mr-2">Assign to:</label>
            <select className="form-control d-inline-block w-auto float-left" value={this.state.formData.assigned_to} onChange={this.handleTeammateChange}>
              <option value="">— sel. —</option>
              <option value={Settings.NOBODY_USER_USERNAME}>No action required</option>;
              <option disabled>──────────</option>
              {
                teammates.map((username) => {
                  return <option value={username}>{username}{username == user.username && ' (yourself)'}</option>;
                })
              }
              <option disabled>──────────</option>
              <option value={underwriter}>{underwriter} (underwriter)</option>;
            </select>
            <div className="clearfix" />
          </Col>
          <Col></Col>
        </Row>

        <div>
          Message:<br />
          <textarea rows="4" className="form-control" value={this.state.formData.message} onChange={this.handleChange} />
        </div>

        {
          user && (
            <div style={{height: '2em', marginTop: '0.5em'}}>
              By: <span><ProfilePicture style={{height: '1em', width: '1em'}} username={user.username} /> {user.username}</span>
            </div>
          )
        }

        <div className="mt-2">
          <Button variant="primary" className={!canSubmit ? 'disabled' : ''} onClick={canSubmit ? this.handleSubmit : null}><i className="fad fa-save mr-1" />Save assignment</Button>
        </div>
      </>
    );
  }
}