// core
import React from 'react';

const StatusComponent = (superclass) => class extends superclass {
  constructor(props) {
    super(props);

    this.STATUS_ERROR = -3;
    this.STATUS_FETCHING = -1;
    this.STATUS_BUSY = this.STATUS_FETCHING;
    this.STATUS_READY = 1;
    this.STATUS_SUCCESS = 2;
  }

  __setStatus = (status, key='__DEFAULT__', callback) => {
    const valid = [
      this.STATUS_ERROR,
      this.STATUS_FETCHING,
      this.STATUS_BUSY,
      this.STATUS_READY,
      this.STATUS_SUCCESS
    ];

    if (valid.indexOf(status) === -1) {
      console.error('Invalid status');

      return false;
    }

    this.setState((prevState) => {
      if (!prevState._status) {
        prevState._status = {};
      }

      prevState._status[key] = status;

      return prevState;
    }, () => {
      if (callback) callback();
    });
  }
  __getStatus = (key='__DEFAULT__') => {
    return this.state._status ? this.state._status[key] : null;
  }

  __setErrors = (errors, key='__DEFAULT__', callback) => {
    this.setState((prevState) => {
      if (!prevState._errors) {
        prevState._errors = {};
      }

      prevState._errors[key] = errors;

      return prevState;
    }, () => {
      if (callback) callback();
    });
  }
  __getErrors = (key='__DEFAULT__') => {
    return this.state._errors[key];
  }
  __hasErrors = (key='__DEFAULT__') => {
    return this.state._errors[key] && this.state._errors[key].length > 0;
  }
}

export default StatusComponent;