import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Row, Col, Alert } from 'react-bootstrap';

import Form from "@rjsf/core";
import ObjectTypeDataViewerFieldGroup from './layout/ObjectTypeDataViewerFieldGroup';

class ObjectTypeDataViewer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data, schema, uiSchema, layout } = this.props;

    if (!schema || !uiSchema) {
      return <Alert as="p" variant="danger" className="mt-4"><i className="fa fa-exclamation-triangle" /> No form schema/ui schema</Alert>;
    }
    if (!layout) {
      return <Alert as="p" variant="danger" className="mt-4"><i className="fa fa-exclamation-triangle" /> No layout</Alert>;
    }

    uiSchema['ui:field'] = 'layout';
    uiSchema['ui:layout'] = layout;

    return (
      <Form
        schema={schema}
        uiSchema={uiSchema}
        liveValidate={false}
        formData={data}

        fields={{
          layout: ObjectTypeDataViewerFieldGroup
        }}

        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        onError={this.handleError}
      >
        {' ' /* Hide submit button */}
      </Form>
    );
  }
}

export default ObjectTypeDataViewer;