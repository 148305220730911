// core
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import moment from 'moment';

import { Container, Row, Col, Badge, Button } from 'react-bootstrap';

import Panel from '../../../components/lib/thss/Panel';

// settings + utils
import Settings from '../../../config.js';
import Auth from '../../../services/Auth.js';

// components
import PageListPanel from '../../../components/shared/tmp/PageListPanel';
import ProfilePicture from '../../../components/shared/elems/ProfilePicture';

class SharedPageLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      notificationsOpen: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickAway);
  }
  componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickAway);
  }

  handleNotificationPanelToggle = (e) => {
    this.setState({notificationsOpen: !this.state.notificationsOpen})
  }

  handleMenuToggle = (e) => {
    this.setState({menuOpen: !this.state.menuOpen})
  }

  handleBondRecordPageClick = (url) => {
    this.props.history.push(url);
  }

  handleClickAway = (e) => {
    if (this.state.menuOpen) {
      this.setState({menuOpen: false})
    }
    if (this.state.notificationsOpen) {
      this.setState({notificationsOpen: false})
    }
  }

  handleLogoutClick = () => {
    Auth.setToken('');

    window.location.reload();
  }

  render() {
const updatesTSV = `2285	3596	PAID	PAYMENT_REQUIRED	PAYMENT	Bond #3596 is paid!	1	nobody	nobody	2021-08-09 14:07:15	2021-08-09 14:07:15	45.28.2.148	45.28.2.148	DkPgHOx0be3F2c4lQYhzLZJW9ASdi8
2284	3596	APPROVED	DRAFT	APPROVAL	Bond #3596 has been approved!	0	nobody	nobody	2021-08-09 14:06:36	2021-08-09 14:06:36	45.28.2.148	45.28.2.148	EDpeBZbxlWTVkh7NgrMHatu5cXJGRj
2283	3583	BOND_ISSUE_READY	SIGNATURE_REQUIRED	SIGNATURE	Indeminity for bond #3583 has been uploaded	1	ann	ann	2021-08-09 13:57:09	2021-08-09 13:57:09	66.188.78.32	66.188.78.32	INBP75hXxedwmRi1ZAJDv2U9sH3zru
2282	3293	BOND_ISSUE_READY	SIGNATURE_REQUIRED	SIGNATURE	Indeminity for bond #3293 has been uploaded	1	crichter	crichter	2021-08-09 13:52:48	2021-08-09 13:52:48	69.135.82.198	69.135.82.198	F01XDvbHRq2Ek4gtxz3jndsyhYf8GT
2281	3595	PAY_CHARGE_AGENCY	PAYMENT_REQUIRED	PAYMENT	Bond #3595 is paid!	1	lisa	lisa	2021-08-09 13:31:28	2021-08-09 13:31:28	75.177.168.69	75.177.168.69	cpe7hzkfCJoQnURwFAl8ZuXV0vbLSr
2280	3595	APPROVED	UNDERWRITING_REVIEW	APPROVAL	Bond #3595 has been approved!	1	lisa	lisa	2021-08-09 13:31:28	2021-08-09 13:31:28	75.177.168.69	75.177.168.69	4h9jriPwUa8zef7uI2YRo5s1gANXG0
2279	3594	PAY_CHARGE_AGENCY	PAYMENT_REQUIRED	PAYMENT	Bond #3594 is paid!	1	lisa	lisa	2021-08-09 13:10:58	2021-08-09 13:10:58	75.177.168.69	75.177.168.69	W5mzdURvjZh94rntP1qxT7NsoQyXOb
2278	3594	APPROVED	UNDERWRITING_REVIEW	APPROVAL	Bond #3594 has been approved!	1	lisa	lisa	2021-08-09 13:10:58	2021-08-09 13:10:58	75.177.168.69	75.177.168.69	OZhojzx4qyKpTYFbiElk7QdRWar0mI
2277	3222	BOND_ISSUE_READY	SIGNATURE_REQUIRED	SIGNATURE	Indeminity for bond #3222 has been uploaded	1	crichter	crichter	2021-08-06 20:58:58	2021-08-06 20:58:58	69.135.82.198	69.135.82.198	EytKdk9YcmvxQJ3neVh1O4FHUzXGwN
2276	3584	APPROVED	DRAFT	APPROVAL	Bond #3584 has been approved!	0	nobody	nobody	2021-08-06 20:48:34	2021-08-06 20:48:34	12.28.173.82	12.28.173.82	1L6xDZBpdV9u85MTIK7JsAO4hfWNnY
2275	3583	SIGNATURE_REQUIRED	PAID	SIGNATURE	Bond #3583 requires signature(s)	1	nobody	nobody	2021-08-06 19:54:01	2021-08-06 19:54:01	107.77.235.114	107.77.235.114	H9ScZKAjqNG4EVCRdLFt0Yfesp6xI2
2274	3583	PAID	PAYMENT_REQUIRED	PAYMENT	Bond #3583 is paid!	1	nobody	nobody	2021-08-06 19:54:01	2021-08-06 19:54:01	107.77.235.114	107.77.235.114	YzD1MIKtT5AFqN8sOukHj4QeXrR0mp
2273	3570	PAID	PAYMENT_REQUIRED	PAYMENT	Bond #3570 is paid!	1	nobody	nobody	2021-08-06 18:14:20	2021-08-06 18:14:20	172.58.4.57	172.58.4.57	zvRBQtXmku3LS5GyfqVIcCFwHAiYeM
2272	3583	APPROVED	DRAFT	APPROVAL	Bond #3583 has been approved!	0	nobody	nobody	2021-08-06 18:06:23	2021-08-06 18:06:23	50.20.17.146	50.20.17.146	dWrQkVxHADEaBmbn7wK5uCqeLf1j8p
2271	3582	PAY_CHARGE_AGENCY	PAYMENT_REQUIRED	PAYMENT	Bond #3582 is paid!	1	ann	ann	2021-08-06 16:58:13	2021-08-06 16:58:13	66.188.78.32	66.188.78.32	L7XrHUMg1cGWozsl5NixvqFZ9O3PD0
2270	3582	APPROVED	DRAFT	APPROVAL	Bond #3582 has been approved!	0	nobody	nobody	2021-08-06 16:51:23	2021-08-06 16:51:23	66.188.78.32	66.188.78.32	TEwboJQc6ps7mhlH3uGWFZqdrgOLKy
2269	3581	PAY_CHARGE_AGENCY	PAYMENT_REQUIRED	PAYMENT	Bond #3581 is paid!	1	mbalzano	mbalzano	2021-08-06 14:58:29	2021-08-06 14:58:29	69.135.82.198	69.135.82.198	elWEjbPOF20cuaHYDqG3Cyxsft6X9n
2268	3581	APPROVED	DRAFT	APPROVAL	Bond #3581 has been approved!	0	mbalzano	mbalzano	2021-08-06 14:58:29	2021-08-06 14:58:29	69.135.82.198	69.135.82.198	p9vgW5hxUcNilnrBHoPXK3aY6dAM1G
2267	3580	APPROVED	DRAFT	APPROVAL	Bond #3580 has been approved!	0	nobody	nobody	2021-08-06 14:45:36	2021-08-06 14:45:36	173.218.57.9	173.218.57.9	hnFqlYE5sJGN8Dy9LBbXAejQWPxzmZ
2266	3579	PAID	PAYMENT_REQUIRED	PAYMENT	Bond #3579 is paid!	1	nobody	nobody	2021-08-06 14:30:24	2021-08-06 14:30:24	173.218.57.9	173.218.57.9	QeNRBZEOrfaWKIF8xbM7oGcdgJkvPs
2265	3579	APPROVED	DRAFT	APPROVAL	Bond #3579 has been approved!	0	nobody	nobody	2021-08-06 14:29:43	2021-08-06 14:29:43	173.218.57.9	173.218.57.9	te7ugJYXcjkislMHDWpz2Ro1ZyL38b
2264	3578	APPROVED	DRAFT	APPROVAL	Bond #3578 has been approved!	0	nobody	nobody	2021-08-06 14:28:05	2021-08-06 14:28:05	173.218.57.9	173.218.57.9	AMNLpCBfPgYF61IXRjWVe9yUiQbSdH
2263	3511	SIGNATURE_REQUIRED	PAID	SIGNATURE	Bond #3511 requires signature(s)	1	nobody	nobody	2021-08-06 14:17:38	2021-08-06 14:17:38	71.105.202.223	71.105.202.223	1EkcVSfMehYGR2nNUbrDFP9CwIX6J5
2262	3511	PAID	PAYMENT_REQUIRED	PAYMENT	Bond #3511 is paid!	1	nobody	nobody	2021-08-06 14:17:38	2021-08-06 14:17:38	71.105.202.223	71.105.202.223	4fzyGeuEb0qd763VSrKACTtOjoRiD8
2261	3575	APPROVED	DRAFT	APPROVAL	Bond #3575 has been approved!	0	nobody	nobody	2021-08-05 21:43:39	2021-08-05 21:43:39	128.92.161.194	128.92.161.194	xrimuLqXwec3t5JHbAkMRSy92W18gf
2260	3574	APPROVED	DRAFT	APPROVAL	Bond #3574 has been approved!	0	nobody	nobody	2021-08-05 20:18:09	2021-08-05 20:18:09	73.207.16.181	73.207.16.181	fqYVIXudynHRF3etPbzLMrjC7N1isK
2259	3570	APPROVED	UNDERWRITING_REVIEW	APPROVAL	Bond #3570 has been approved!	1	ann	ann	2021-08-05 20:17:46	2021-08-05 20:17:46	66.188.78.32	66.188.78.32	rBcvRFk52U6MOXYaTmfJVHpPGs9jZ1
2258	3573	PAID	PAYMENT_REQUIRED	PAYMENT	Bond #3573 is paid!	1	nobody	nobody	2021-08-05 19:09:27	2021-08-05 19:09:27	73.207.105.184	73.207.105.184	hxjU5DTp8VabmNCcfy0Q6kFwB7vgH1
2257	3573	APPROVED	DRAFT	APPROVAL	Bond #3573 has been approved!	0	nobody	nobody	2021-08-05 19:07:57	2021-08-05 19:07:57	73.207.105.184	73.207.105.184	xidfMrEmwZ8vbDOCSXyPtR53V7usq1
2256	3572	APPROVED	DRAFT	APPROVAL	Bond #3572 has been approved!	0	nobody	nobody	2021-08-05 18:58:39	2021-08-05 18:58:39	73.207.105.184	73.207.105.184	fCqYoeJOAc2GuByHM9txmwd6nPIK08
2255	3571	APPROVED	DRAFT	APPROVAL	Bond #3571 has been approved!	0	nobody	nobody	2021-08-05 18:46:50	2021-08-05 18:46:50	73.207.105.184	73.207.105.184	bSm53A2ZhPvsJywxoC9fM4iKFlk81c
2254	3569	APPROVED	DRAFT	APPROVAL	Bond #3569 has been approved!	0	nobody	nobody	2021-08-05 16:05:12	2021-08-05 16:05:12	178.43.3.234	178.43.3.234	SaCthHBQ2w97AuIZOoMnzk03fXKUPb
2253	3567	PAID	PAYMENT_REQUIRED	PAYMENT	Bond #3567 is paid!	1	nobody	nobody	2021-08-05 14:23:40	2021-08-05 14:23:40	76.189.220.144	76.189.220.144	ltXFQuwqBvamHRGd9ZP1MzKJp6Cy4N
2252	3567	APPROVED	DRAFT	APPROVAL	Bond #3567 has been approved!	0	nobody	nobody	2021-08-05 14:22:46	2021-08-05 14:22:46	76.189.220.144	76.189.220.144	3XMYqt5hPT6Swvn4eomWlfF9EsRrU2
2251	3566	APPROVED	DRAFT	APPROVAL	Bond #3566 has been approved!	0	nobody	nobody	2021-08-05 14:20:47	2021-08-05 14:20:47	76.189.220.144	76.189.220.144	0UY8FqWi693A4IPMlCVBvcwtjzGsbK
2250	3565	APPROVED	DRAFT	APPROVAL	Bond #3565 has been approved!	0	dave	dave	2021-08-04 20:21:27	2021-08-04 20:21:27	108.1.148.185	108.1.148.185	yp3MuUZThzVo57F4nAG8WjvPmCD9Si
2249	3556	PAID	PAYMENT_REQUIRED	PAYMENT	Bond #3556 is paid!	1	nobody	nobody	2021-08-04 19:53:50	2021-08-04 19:53:50	107.77.253.59	107.77.253.59	tdBAbY2E90aoG1DevQJjwHiVhOqWny
2248	3564	APPROVED	DRAFT	APPROVAL	Bond #3564 has been approved!	0	nobody	nobody	2021-08-04 18:34:54	2021-08-04 18:34:54	207.38.199.210	207.38.199.210	uKhNUmtbpJFgWOBAYiRL495vZzsVj3
2247	3563	APPROVED	DRAFT	APPROVAL	Bond #3563 has been approved!	0	nobody	nobody	2021-08-04 18:28:37	2021-08-04 18:28:37	207.38.199.210	207.38.199.210	rZF5mqsgHJYP0NVzhliL6vtpoMb3XI
2246	3556	APPROVED	UNDERWRITING_REVIEW	APPROVAL	Bond #3556 has been approved!	1	ann	ann	2021-08-04 17:27:01	2021-08-04 17:27:01	66.188.78.32	66.188.78.32	V9zbrDlOpTGZwINPJUHc2YviA4fmLs
2245	3559	PAID	PAYMENT_REQUIRED	PAYMENT	Bond #3559 is paid!	1	nobody	nobody	2021-08-04 17:26:36	2021-08-04 17:26:36	24.131.35.101	24.131.35.101	GBpP32Nmlwe89Q1ftMjv6qYJ7LACyE
2244	3559	APPROVED	DRAFT	APPROVAL	Bond #3559 has been approved!	0	nobody	nobody	2021-08-04 17:25:17	2021-08-04 17:25:17	24.131.35.101	24.131.35.101	fKadtnU7gTbx9HGcO1AlVYQFDkLzZq
2243	3558	APPROVED	DRAFT	APPROVAL	Bond #3558 has been approved!	0	nobody	nobody	2021-08-04 16:06:05	2021-08-04 16:06:05	24.131.35.101	24.131.35.101	qoku9ONSdxrZHp8Rfg57j1AvhyIMFn
2242	3557	PAID	PAYMENT_REQUIRED	PAYMENT	Bond #3557 is paid!	1	nobody	nobody	2021-08-04 15:38:02	2021-08-04 15:38:02	52.200.132.208	52.200.132.208	isUNGdCkDMfY6L1IoHntby0F4VmZ9W
2241	3557	APPROVED	DRAFT	APPROVAL	Bond #3557 has been approved!	0	nobody	nobody	2021-08-04 15:36:32	2021-08-04 15:36:32	52.200.132.208	52.200.132.208	ihcPYSgpqJxEMfQAn7FIj5Bywzeok1
2240	3555	APPROVED	DRAFT	APPROVAL	Bond #3555 has been approved!	0	nobody	nobody	2021-08-04 14:33:44	2021-08-04 14:33:44	207.200.7.70	207.200.7.70	vM1KQyj8qats7HYnk3muxeTAWcf6FP
2239	3293	SIGNATURE_REQUIRED	PAY_CHARGE_AGENCY	SIGNATURE	Bond #3293 requires signature(s)	1	lisa	lisa	2021-08-04 14:15:00	2021-08-04 14:15:00	75.177.168.69	75.177.168.69	6f3TZonu9xB827NmXVGAyFOY0DhwjU
2238	3293	PAY_CHARGE_AGENCY	PAYMENT_REQUIRED	PAYMENT	Bond #3293 is paid!	1	lisa	lisa	2021-08-04 14:15:00	2021-08-04 14:15:00	75.177.168.69	75.177.168.69	aQ85CONgVxyvh9iRdJLbZr3ltHTPeI
2237	3293	APPROVED	UNDERWRITING_REVIEW	APPROVAL	Bond #3293 has been approved!	1	lisa	lisa	2021-08-04 14:15:00	2021-08-04 14:15:00	75.177.168.69	75.177.168.69	jwQ2E40z3qH9bY8ulP7mhcI5KGMWnJ
2236	3545	BOND_ISSUE_READY	SIGNATURE_REQUIRED	SIGNATURE	Indeminity for bond #3545 has been uploaded	1	crichter	crichter	2021-08-03 17:54:36	2021-08-03 17:54:36	69.135.82.198	69.135.82.198	cf0On1w9iNXY2amRLyZKMFrjbu3vzA
2235	3545	SIGNATURE_REQUIRED	PAY_CHARGE_AGENCY	SIGNATURE	Bond #3545 requires signature(s)	1	crichter	crichter	2021-08-03 15:06:01	2021-08-03 15:06:01	69.135.82.198	69.135.82.198	EobwladZ4PpkJnuqRQ7WhCjVDvU5OX
2234	3545	PAY_CHARGE_AGENCY	PAYMENT_REQUIRED	PAYMENT	Bond #3545 is paid!	1	crichter	crichter	2021-08-03 15:06:01	2021-08-03 15:06:01	69.135.82.198	69.135.82.198	hPzl7RiLHVqwQv81mbJ3xMkSrKeGcn
2233	3545	APPROVED	DRAFT	APPROVAL	Bond #3545 has been approved!	0	crichter	crichter	2021-08-03 15:06:01	2021-08-03 15:06:01	69.135.82.198	69.135.82.198	ZuYaiJzkmLQ8RXI2D1wG79exWOl63f
2232	3544	PAID	PAYMENT_REQUIRED	PAYMENT	Bond #3544 is paid!	1	nobody	nobody	2021-08-03 13:46:10	2021-08-03 13:46:10	50.225.27.102	50.225.27.102	0cjloG4WyutIr2phT5UHROB1EQ8Mn6
2231	3544	APPROVED	DRAFT	APPROVAL	Bond #3544 has been approved!	0	nobody	nobody	2021-08-03 13:43:10	2021-08-03 13:43:10	50.225.27.102	50.225.27.102	TZvOp5IPEgHGmYnlStUKLdq20MV6aj
2230	3543	APPROVED	DRAFT	APPROVAL	Bond #3543 has been approved!	0	nobody	nobody	2021-08-03 13:41:25	2021-08-03 13:41:25	50.225.27.102	50.225.27.102	xFvsfWX9k0KYuM2bDOotSJLneadpiG
2229	3542	APPROVED	DRAFT	APPROVAL	Bond #3542 has been approved!	0	nobody	nobody	2021-08-03 13:40:01	2021-08-03 13:40:01	50.225.27.102	50.225.27.102	XIfjcLVQ6NelYvb7iq0k9FuHxPdK3w
2228	3541	APPROVED	DRAFT	APPROVAL	Bond #3541 has been approved!	0	nobody	nobody	2021-08-03 13:36:23	2021-08-03 13:36:23	50.225.27.102	50.225.27.102	iYoJGFODl3x1RTuEw8zQgH2v0UsntP
2227	3540	APPROVED	DRAFT	APPROVAL	Bond #3540 has been approved!	0	nobody	nobody	2021-08-03 13:32:33	2021-08-03 13:32:33	50.225.27.102	50.225.27.102	35rDyRGHpYhkSKo2Ea8lNIU16LWctn
2226	3539	PAID	PAYMENT_REQUIRED	PAYMENT	Bond #3539 is paid!	1	ann	ann	2021-08-02 19:11:44	2021-08-02 19:11:44	66.188.78.32	66.188.78.32	Egr50aPx6hwAKVSmy1GkqNI9sZHJWe
2225	3539	APPROVED	UNDERWRITING_REVIEW	APPROVAL	Bond #3539 has been approved!	1	ann	ann	2021-08-02 19:09:49	2021-08-02 19:09:49	66.188.78.32	66.188.78.32	xVUJMGPQyweEdDYcSCuNHkmA95f62O
2224	3538	PAY_CHARGE_AGENCY	PAYMENT_REQUIRED	PAYMENT	Bond #3538 is paid!	1	ann	ann	2021-08-02 19:08:14	2021-08-02 19:08:14	66.188.78.32	66.188.78.32	KARi1pCVaDJqyThe8bYto47ZUlOGXk
2223	3538	APPROVED	UNDERWRITING_REVIEW	APPROVAL	Bond #3538 has been approved!	1	ann	ann	2021-08-02 19:08:05	2021-08-02 19:08:05	66.188.78.32	66.188.78.32	zVsbZ5NJoxRLMwjGB6lAh1vKkcadr7
2222	3537	PAID	PAYMENT_REQUIRED	PAYMENT	Bond #3537 is paid!	1	nobody	nobody	2021-08-02 16:43:03	2021-08-02 16:43:03	73.184.176.114	73.184.176.114	gBqHXJEKwY8C9IPN50er3d17jTZuim
2221	3537	APPROVED	DRAFT	APPROVAL	Bond #3537 has been approved!	0	nobody	nobody	2021-08-02 16:37:56	2021-08-02 16:37:56	73.184.176.114	73.184.176.114	AZHjDT5JBkhFPmKfLvOewQIbS9WM0u
2220	3536	APPROVED	DRAFT	APPROVAL	Bond #3536 has been approved!	0	nobody	nobody	2021-08-02 16:27:54	2021-08-02 16:27:54	178.43.63.102	178.43.63.102	xEUsvzCa9KiXlhBeDkAt8qyHN2LbPd
2219	3535	APPROVED	DRAFT	APPROVAL	Bond #3535 has been approved!	0	nobody	nobody	2021-08-02 16:27:37	2021-08-02 16:27:37	178.43.63.102	178.43.63.102	vaSHuy5zDlAsMZCtRJQkLpexEIoib6
2218	3534	APPROVED	DRAFT	APPROVAL	Bond #3534 has been approved!	0	nobody	nobody	2021-08-02 16:15:03	2021-08-02 16:15:03	178.43.63.102	178.43.63.102	l6bDzWmP9daNLnypj3EwF8ZSkcJqBo
2217	3511	APPROVED	UNDERWRITING_REVIEW	APPROVAL	Bond #3511 has been approved!	1	lisa	lisa	2021-08-02 15:50:04	2021-08-02 15:50:04	75.177.168.69	75.177.168.69	a4zQhLZTPvirStOc7AJbY5XDEnVkKo
2216	3419	BOND_ISSUE_READY	SIGNATURE_REQUIRED	SIGNATURE	Indeminity for bond #3419 has been uploaded	1	ann	ann	2021-08-02 14:06:30	2021-08-02 14:06:30	66.188.78.32	66.188.78.32	ZaBSgsmPHktE53GocT9bxvCdeu2Wy8
2215	3419	SIGNATURE_REQUIRED	PAID	SIGNATURE	Bond #3419 requires signature(s)	1	ann	ann	2021-08-02 14:04:03	2021-08-02 14:04:03	66.188.78.32	66.188.78.32	VpDMJI8Y4wynQh5UCjxBd7aP1S9iWq
2214	3419	PAID	PAYMENT_REQUIRED	PAYMENT	Bond #3419 is paid!	1	ann	ann	2021-08-02 14:04:03	2021-08-02 14:04:03	66.188.78.32	66.188.78.32	lfmNCxZkP52pnHWyEQ8r7d4gsbO6oD
2213	3507	APPROVED	DRAFT	APPROVAL	Bond #3507 has been approved!	0	nobody	nobody	2021-08-01 23:16:37	2021-08-01 23:16:37	108.1.148.185	108.1.148.185	CJon3UhRpgTEHBWsbXeAzc9NGy10ut
2212	3430	BOND_ISSUE_READY	SIGNATURE_REQUIRED	SIGNATURE	Indeminity for bond #3430 has been uploaded	1	leeann	leeann	2021-07-30 20:10:09	2021-07-30 20:10:09	66.188.78.32	66.188.78.32	bNGQsWPxOi4zp52oBfHlh1UXy8wqTD
2211	3430	SIGNATURE_REQUIRED	PAID	SIGNATURE	Bond #3430 requires signature(s)	1	nobody	nobody	2021-07-30 18:44:12	2021-07-30 18:44:12	174.68.139.221	174.68.139.221	4PXyAJdarC0qmixIcTpnWRLu9U6Do1
2210	3430	PAID	PAYMENT_REQUIRED	PAYMENT	Bond #3430 is paid!	1	nobody	nobody	2021-07-30 18:44:12	2021-07-30 18:44:12	174.68.139.221	174.68.139.221	0FQIaOTdMoDlLmbU8ynhPCEgXH1JSc
2209	3423	PAY_CHARGE_AGENCY	PAYMENT_REQUIRED	PAYMENT	Bond #3423 is paid!	1	lisa	lisa	2021-07-30 18:41:43	2021-07-30 18:41:43	75.177.168.69	75.177.168.69	5pMZ8w3gzEvsxFUhOteLoPDBVrRbym
2208	3423	APPROVED	UNDERWRITING_REVIEW	APPROVAL	Bond #3423 has been approved!	1	lisa	lisa	2021-07-30 18:41:43	2021-07-30 18:41:43	75.177.168.69	75.177.168.69	kEgMYByXG36AzaUwbl7mpCROKZhq9D
2207	3430	APPROVED	DRAFT	APPROVAL	Bond #3430 has been approved!	0	nobody	nobody	2021-07-30 18:39:43	2021-07-30 18:39:43	174.68.139.221	174.68.139.221	YGCEloQf8UVLx9HwZXP1k2NOnJrzhF
2206	3428	APPROVED	DRAFT	APPROVAL	Bond #3428 has been approved!	0	nobody	nobody	2021-07-30 18:32:40	2021-07-30 18:32:40	108.1.148.185	108.1.148.185	9FT5ODPcvmXa0zki2nrEeAU17R6VNK`;

    const updates = updatesTSV.split("\n").map((line) => {
      const updateData = line.split("\t")

      return {
        id: updateData[0],
        record_id	: updateData[1],
        status_name	: updateData[2],
        prev_status_name	: updateData[3],
        event_type	: updateData[4],
        message	: updateData[5],
        notify	: updateData[6],
        created_by	: updateData[7],
        updated_by	: updateData[8],
        created_at	: updateData[9],
        updated_at	: updateData[10],
        created_from	: updateData[11],
        updated_from	: updateData[12],
        auth_access_key: updateData[13],
      }
    }).slice(0, 20);

    const logoButton = <Link to="/" className="gig-admin-navbar__logo"><img src="/images/shared/logo.png" style={{height: '2.75rem'}} rel="noreferrer" /></Link>;

    const quickLinksButtons = (
      <>
        {
          this.props.user && this.props.user.agency_id && (
            <Link to="/bonds/find/" className={'btn ' + (window.location.pathname.indexOf('/bonds/find') !== -1 || window.location.pathname.indexOf('/quotes') !== -1 ? 'btn-primary' : 'btn-secondary') + ' align-middle gig-admin-navbar__button'}>
              <i className="fa fa-plus mr-1" /> New application
            </Link>
          )
        }
        {/* <Link to="/tasks/" className={'btn ' + (window.location.pathname.indexOf('/tasks') !== -1 ? 'btn-primary' : 'btn-secondary') + ' align-middle gig-admin-navbar__button'}>
          <i className={Settings.icons.objectTypes.task + ' mr-1'} /> My tasks
        </Link> */}

        <span className="py-2 ml-3 mr-0 border-left border-dotted border-secondary"></span>
      </>
    );

    const mainButtons = (
      <>
        <Link to="/applications/" className={'btn ' + (window.location.pathname.indexOf('/applications') !== -1 ? 'btn-primary' : 'btn-secondary') + ' align-middle gig-admin-navbar__button'}>
          <i className={Settings.icons.objectTypes.order + ' mr-2'} /> Applications/Bonds
        </Link>

        {/* <Link to="/agencies/" className={'btn ' + (window.location.pathname.indexOf('/agencies') !== -1 ? 'btn-primary' : 'btn-secondary') + ' align-middle gig-admin-navbar__button'}>
          <i className={Settings.icons.objectTypes.agency + ' mr-2'} /> Agencies
        </Link>
        <Link to="/mgas/" className={'btn ' + (window.location.pathname.indexOf('/mgas') !== -1 ? 'btn-primary' : 'btn-secondary') + ' align-middle gig-admin-navbar__button'}>
          <i className={Settings.icons.objectTypes.mga + ' mr-2'} /> MGAs
        </Link>

        <Link to="/reports/" className={'btn ' + (window.location.pathname.indexOf('/reports') !== -1 ? 'btn-primary' : 'btn-secondary') + ' align-middle gig-admin-navbar__button'}>
          <i className={Settings.icons.objectTypes.report + ' mr-2'} /> Reports
        </Link>

        <Link to="/inventory/" className={'btn ' + (window.location.pathname.indexOf('/inventory') !== -1 ? 'btn-primary' : 'btn-secondary') + ' align-middle gig-admin-navbar__button'}>
          <i className={Settings.icons.objectTypes.inventory + ' mr-2'} /> Inventory
        </Link> */}

        {
          this.props.user && !this.props.user.agency_id && (
            <Link to="/inquiries/" className={'btn ' + (window.location.pathname.indexOf('/inquiries') !== -1 ? 'btn-primary' : 'btn-secondary') + ' align-middle gig-admin-navbar__button'}>
              <i className="fa fa-comment-alt-smile mr-1" /> Customer support
            </Link>
          )
        }
      </>
    );

    const logoutButton = (
      <div id="gig-admin-navbar__user-dropdown" className="gig-admin-navbar__button dropdown align-middle" style={{display: 'inline-block'}}>
        <div className={'btn btn-secondary dropdown-toggle gig-admin-navbar__button ' + (this.state.menuOpen ? 'gig-admin-navbar__button--selected' : '')} onClick={this.handleMenuToggle}>
          <ProfilePicture className="gig-admin-navbar__profile-picture mr-2" username={this.props.user ? this.props.user.username : ''} /> {this.props.user.username}
        </div>

        <div className="dropdown-menu ml-3" style={{display: this.state.menuOpen ? 'inline-block' : 'none'}}>
          <a className="dropdown-item py-0" href="javascript:void(0)" onMouseDown={this.handleLogoutClick}>Logout</a>
        </div>
      </div>
    );

    const notificationsButton = (
      <div id="" className="gig-admin-navbar__button dropdown align-middle pr-0" style={{display: 'inline-block'}}>
        <div className={'btn btn-secondary dropdown-toggle gig-admin-navbar__button ' + (this.state.notificationsOpen ? 'gig-admin-navbar__button--selected' : '')} onClick={this.handleNotificationPanelToggle}>
          <i className="fad fa-bell" /> ({updates.length})
        </div>

        <div className="dropdown-menu ml-3 gig-admin-navbar__notifications-panel py-2 px-3" style={{display: this.state.notificationsOpen ? 'inline-block' : 'none'}}>
          {
            this.state.notificationsOpen != false && (
              <div className="">
                {
                  updates.map((update) => {
                    const relativeDate = moment.utc(update.created_at).fromNow();
                    const iconClass = Settings.icons.logEntryTypes[update.event_type];
                    const linkToBondRecordPage = `/applications/${update.record_id}/`;

                    return (
                      <Panel style={{height: '78px'}} noPadding className="py-2 px-3 mb-3 border-0">
                        <div className="float-left">
                          <i className={`fad ${iconClass} h1 text-primary mt-0 mt-3 ml-3 mr-4`} />
                        </div>
                        <div className="float-left">
                          <strong>Application #{update.record_id}</strong>
                          <small className="ml-1">
                            {
                              update.prev_status_name && update.prev_status_name != update.status_name ? (
                                <><Badge variant="secondary" className="text-white">{update.prev_status_name}</Badge><i className="fad fa-arrow-right ml-2 mr-2" /></>
                              ) : ''
                            }
                            <Badge variant="secondary" className="text-white">{update.status_name}</Badge>
                          </small><br />
                          <big className="font-italic m-0">{update.message || 'No message'}</big>
                          <div><small>{relativeDate} by {update.created_by}</small></div>
                        </div>

                        <Link to={linkToBondRecordPage} onMouseDown={(e) => this.handleBondRecordPageClick(linkToBondRecordPage)}>
                          <Button variant="light" className="float-right mt-3">view application <i className="fad fa-arrow-right ml-1" /></Button>
                        </Link>

                        <div className="clearfix" />
                      </Panel>
                    )
                  })
                }
              </div>
            )
          }
        </div>
      </div>
    );

    return (
      <>
        <header className="gig-admin-site-header">
          <div className="gig-admin-navbar">
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <div className="float-left my-2 mr-3">
                    { logoButton }
                  </div>
                  <div className="float-left my-2">
                    {quickLinksButtons}

                    {mainButtons}
                  </div>

                  <div className="my-2 float-right">
                    {logoutButton}
                  </div>

                  <div className="my-2 float-right">
                    {/* {notificationsButton} */}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </header>

        { this.props.children }

        <footer className="gig-admin-footer mt-4 mb-0" style={{backgroundColor: '#fff', borderTop: '2px solid #ddd'}}>
          <Container fluid>
            <Row>
              <Col xs={12}>
                <p className="px-0 py-3 m-0 text-right"><small className="text-muted">SBM admin v.002</small></p>
              </Col>
            </Row>
          </Container>
        </footer>

        {
          window.location.hostname.indexOf('localhost') != -1 && (
            <PageListPanel />
          )
        }
      </>
    );
  }
}

export default withRouter(SharedPageLayout);