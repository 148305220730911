import React from 'react';

// settings + utils
import DataComponent from '../../shared/mixins/DataComponent';
import StatusComponent from '../../shared/mixins/StatusComponent';

import { Container, Row, Col } from 'react-bootstrap';

// base components
import mixin from '@thss-anthros/mixins';
import Panel from '../../lib/thss/Panel';

// component deps
import BondFormStep from './BondFormStep';

/* steps are all similar
  each step has:
  - an name-based ID. IDs are used to refer to data related to this step.
  - status, each step could be fetching, ready, focused, focused, error, etc.
  - 1 or more JSON Form schemas, which are used to print and validate the form, and to display data
  - 1 form layout per schema, which are used to layout form fields defined in the schemas
  - data container to hold form data
  - data container to hold utility options specified while filling out form
*/

class BondForm extends mixin(React.Component, [StatusComponent]) {
  constructor(props) {
    super(props);

    this.ANIMATION_DURATION = 350;
    this.TOOLTIP_DELAY = 0; // this.ANIMATION_DURATION * 1.2;

    this.state = {
      ...this.state,

      currStepIndex: -1,

      switchingSteps: false,

      formDataByType: {},
      numRequiredByType: {},

      lastGTMTrigger: '' // avoid sending same GTM event twice
    };
  }

  componentDidMount() {
    // init from data
    if (this.props.initialData) {
      this.initFromData(this.props.initialData);
    }

    // skip to initial step
    const stepIndex = this.props.skipTo !== undefined ? this.props.skipTo : 0;
    this.setCurrStep(stepIndex);
  }

  __setFormData = (formData, typeName, callback=null) => {
    this.setState((prevState) => {
      prevState.formDataByType[typeName] = formData;

      return prevState;
    }, () => {
      if (callback) callback(formData, typeName, this.state.numRequiredByType[typeName]);
    });
  }
  __getFormData = (typeName) => {
    return this.state.formDataByType[typeName];
  }

  getSteps = () => {
    return this.props.steps || [];
  }
  getNumSteps = () => {
    return this.getSteps().length;
  }

  getSchemaStoreKey = (typeName) => {
    return `${typeName}_schema`;
  }
  getFormDataStoreKey = (typeName) => {
    return typeName;
  }

  setCurrStep = (stepIndex, callback) => {
    if (!this.props.steps[stepIndex]) {
      console.error('NO STEP AT INDEX', stepIndex);

      return;
    }

    // first, set "switchingSteps" to true, to trigger tooltips to close
    this.setState({
      switchingSteps: true
    }, () => {
      // then, actually animate panels and switch step
      window.setTimeout(() => {
        // handle GTM event
        let lastGTMTriggerName = this.state.lastGTMTrigger;
        const triggerName = this.props.steps[stepIndex].gtmTriggerName || '';
        if (triggerName != lastGTMTriggerName) {
          if (window.dataLayer !== undefined) {
            window.dataLayer.push({'event': triggerName});

            lastGTMTriggerName = triggerName;
          } else {
            console.warn('WARNING: GTM is undefined, will not trigger event');
          }
        }

        // update state
        this.setState({
          currStepIndex: stepIndex,
          switchingSteps: false,
          lastGTMTrigger: lastGTMTriggerName
        }, () => {
          if (this.props.onStepChange) {
            this.props.onStepChange(this.state.currStepIndex)
          }
          if (callback) callback();
        });
      }, this.ANIMATION_DURATION + 250);
    })
  }

  getTypeNames = () => {
    let typeNames = [];

    this.getSteps().forEach((step) => {
      const stepTypes = step.types;

      stepTypes.forEach((typeOptions) => {
        typeNames.push(typeOptions.typeName);
      })
    });
  }

  // calculates number of empty required fields
  getNumRequired = (typeName, isArray=false, arrayIndex=null, refresh=false) => {
    let numRequired = 0;

    if (refresh) {
      // calculate from scratch
      let schema = null;
      this.props.steps.forEach((stepOptions) => {
        stepOptions.types.forEach((typeOptions) => {
          if (typeOptions.typeName == typeName) {
            schema = typeOptions.formSchema;
          }
        })
      });
      if (!schema) {
        console.error('do NOT have type schema while trying to calculate num required');

        return Number.MAX_SAFE_INTEGER;
      }

      // get an array of all field groups to check.
      // if it's not an array or an array index is specified, it's only one group, otherwise it's more
      // always treat as array to simplify
      let formDataElems = null;
      if (isArray) {
        const currItems = this.__getFormData(typeName);

        formDataElems = arrayIndex !== null ? [currItems[arrayIndex]] : currItems;
      } else {
        const elems = this.__getFormData(typeName);

        formDataElems = elems ? [elems] : [];
      }

      const requiredFields = schema.required;

      // if no form data elems, all required
      if (!formDataElems || Array.isArray(formDataElems) && formDataElems.length == 0) {
        return requiredFields.length;
      }
      formDataElems.forEach((formData) => {
        // if no data, all required
        if (!formData || Object.keys(formData).length === 0) {
          numRequired += requiredFields.length;

          return;
        }

        requiredFields.forEach((fieldName) => {
          if (formData[fieldName] === undefined || formData[fieldName] === null) {
            numRequired += 1;
          }
        })
      });
    } else {
      // if a result was previously cached, return that. Otherwise, calculate from scratch
      if (this.state.numRequiredByType[typeName] !== undefined) {
        return this.state.numRequiredByType[typeName];
      } else {
        return this.getNumRequired(typeName, isArray, arrayIndex, true);
      }
    }

    if (isNaN(numRequired)) {
      console.warn(`WARNING: getNumRequired for ${typeName} is NAN`);
    }

    return numRequired;
  }

  handleStepSkip = (stepIndex) => {
    // first, reset data
    const steps = this.getSteps();

    const stepOptions = steps.find((step, i) => i === stepIndex);

    let numProcessed = 0;
    stepOptions.types.forEach((typeOptions, i) => {
      this.handleFormChange(null, typeOptions.typeName, false, 0, false, () => {
        numProcessed++;

        if (numProcessed == stepOptions.types.length) {
          // exec callback
          if (this.props.onStepSkip) {
            this.props.onStepSkip(stepIndex);
          }
        }
      });
    });
  }
  handleStepRestore = (stepIndex) => {
    if (this.props.onStepRestore) {
      this.props.onStepRestore(stepIndex);
    }
  }

  handleEditClick = (stepIndex) => {
    this.setCurrStep(stepIndex);
  }

  handleStepSubmit = (stepIndex) => {
    const nextStepIndex = stepIndex + 1;

    if (nextStepIndex == this.getNumSteps()) {
      this.handleFormSubmit();
    } else {
      this.setCurrStep(nextStepIndex);

      if (this.props.onStepSubmit) {
        this.props.onStepSubmit(stepIndex);
      }
    }
  }

  // each form will trigger this function, save data grouped by form schema—optionally inside an array
  handleFormChange = (formData, typeName, isArray=false, arrayIndex=0, arrayUnset=false, callback=null) => {
    // store form data
    let finalData;
    if (isArray) {
      // if saving to array, get curr values first, then replace using index index
      finalData = this.__getFormData(typeName) || [];
      finalData[arrayIndex] = formData;

      // if unset, remove item
      if (arrayUnset) {
        finalData.splice(arrayIndex, 1);
      }
    } else {
      // otherwise, just save form data
      finalData = formData;
    }

    const storeKey = this.getFormDataStoreKey(typeName);
    this.__setFormData(finalData, storeKey, () => {
      // get errors/num required
      const numRequired = this.getNumRequired(typeName, isArray, arrayUnset == false ? arrayIndex : arrayIndex - 1, true);

      // exec onChange
      if (this.props.onChange) {
        if (callback) callback();

        this.props.onChange(finalData, typeName, numRequired);
      }

      // update required fields count
      this.setState((prevState) => {
        prevState.numRequiredByType[typeName] = numRequired;

        return prevState;
      }, () => {
        if (callback) callback();
      });
    });
  }

  handleFormSubmit = (e) => {
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  }

  initFromData = (initialData) => {
    if (!initialData) {
      return;
    }

    const steps = this.getSteps();

    Object.keys(steps).forEach((stepIndex) => {
      const stepOptions = steps[stepIndex];
      const ON_CHANGE_DELAY = 50; // add delay to make sure parent component has time to handle change

      stepOptions.types.forEach((typeOptions, i) => {
        window.setTimeout(() => {
          const formData = initialData[typeOptions.typeName];

          const isArray = stepOptions.adjustableQuantityTypeNames && stepOptions.adjustableQuantityTypeNames.indexOf(typeOptions.typeName) !== -1;

          if (formData !== undefined) {
            if (!isArray) {
              this.handleFormChange(formData, typeOptions.typeName);
            } else {
                const maxArrayItems = stepOptions.adjustableQuantityLimit || formData.length;

                for (let i=0; i<maxArrayItems; i++) {
                  const itemData = formData[i];

                  if (itemData !== undefined) {
                    this.handleFormChange(itemData, typeOptions.typeName, true, i, false);
                  }
                }
            }
          }
        }, i * ON_CHANGE_DELAY);
      })
    });
  }

  render() {
    // global data
    const { product, wizard } = this.props;

    const progress = this.__getStatus('personal_info') === this.STATUS_FETCHING ? 0 : (100 / (this.getSteps().length + 1) * (this.state.currStepIndex + 1));

    const colors = this.props.colors ? this.props.colors : {
      text: '#FFFFFF',
      primary: '#0079F0',
      darker: '#0079F0',
      lighter: '#eaeaea'
    };

    const steps = this.getSteps();

    return (
      <>
        <Container fluid className="p-0 m-0">
          <Row noGutters>
            <Col xs={12}>
              {
                steps.map((stepOptions, stepIndex) => {
                  // global info
                  const switchingSteps = this.state.switchingSteps;

                  // basic step info
                  const stepPos = stepIndex + 1;
                  const stepName = `step_${stepPos}`;
                  const stepTypes = stepOptions.types;
                  const adjustableQuantityTypeNames = stepOptions.adjustableQuantityTypeNames;
                  const adjustableQuantityLimit = stepOptions.adjustableQuantityLimit;
                  const skip = stepOptions.skip;
                  const optional = stepOptions.optional;
                  const stepTitle = stepOptions.title || `Step #${stepPos}`;
                  const stepIntroDescr = wizard ? stepOptions.introDescr : '';
                  const tooltipTitle = wizard ? stepOptions.tooltipTitle : '';
                  const tooltipText = wizard ? stepOptions.tooltipText : '';
                  const tooltipComponent = stepOptions.tooltipComponent || null;

                  // step status
                  const focused = !this.props.submitting && !this.props.submitted && (!wizard || this.state.currStepIndex === stepIndex);
                  const fetching = focused && stepOptions.fetching || this.__getStatus(stepName) == this.STATUS_FETCHING;
                  const error = this.__getStatus(stepName) == this.STATUS_ERROR;
                  const ready = !fetching && Math.min(...stepTypes.map(typeOptions => this.getSchemaStoreKey(typeOptions.typeName) ? 1 : 0)); // if all types have schema
                  const disabled = wizard && this.state.currStepIndex < stepIndex;

                  const completed = this.props.submitted || (wizard && this.state.currStepIndex > stepIndex);
                  const numRequired = typeof stepOptions.numRequiredOverride != 'undefined' ? stepOptions.numRequiredOverride : stepTypes.reduce((total, typeOptions) => { // total of all required across types in step
                    const stepReq = this.getNumRequired(typeOptions.typeName, typeOptions.has_many, null);

                    return total += stepReq;
                  }, 0);

                  return (
                    <BondFormStep
                      switchingSteps={switchingSteps}

                      stepOptions={stepOptions}
                      stepTypes={stepTypes}

                      adjustableQuantityTypeNames={adjustableQuantityTypeNames}
                      adjustableQuantityLimit={adjustableQuantityLimit}

                      stepName={stepName}
                      stepIndex={stepIndex}
                      stepPos={stepPos}

                      optional={optional}
                      skip={skip}

                      stepTitle={stepTitle}
                      stepIntroDescr={stepIntroDescr}
                      tooltipTitle={tooltipTitle}
                      tooltipText={tooltipText}
                      tooltipComponent={tooltipComponent}

                      fetching={fetching}
                      error={error}
                      ready={ready}
                      disabled={disabled}
                      focused={focused}
                      completed={completed}
                      numRequired={numRequired}

                      colors={colors}

                      onSkip={this.handleStepSkip}
                      onRestore={this.handleStepRestore}

                      onEditClick={wizard ? this.handleEditClick : null}
                      onChange={this.handleFormChange}
                      onSubmit={this.handleStepSubmit}
                    />
                  );
                })
              }
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default BondForm;