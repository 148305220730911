import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Row, Col, Button, Alert, Badge } from 'react-bootstrap';

import Settings from '../../config.js';

import ValignMiddle from '../../components/lib/thss/ValignMiddle';

// prints the appropriate stuff according to the bond's status
class BondStatusTransitionsPanel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      statusDropdownOpen: false
    };
  }

  handleSubmit = (transition) => {
    if (!window.confirm(`Are you sure you want to ${String(transition.label).toLowerCase()}?`)) {
      return;
    }

    this.props.onSubmit(transition);
  }

  render() {
    const { status, bondRecord, product } = this.props;
    if (!status) {
      console.error('no bond record status in panel');

      return 'NO BOND_RECORD STATUS';
    }

    const { group_name, name, label, title, descr } = status;

    const bondStatusIconClass = Settings.bondStatusOptions[name].iconClass;
    const bondStatusColor = Settings.bondStatusOptions[name].colorVariant;

    return (
      <div>
        <Alert className="px-0 py-2" style={{backgroundColor: bondStatusColor, cursor: 'pointer'}} onClick={() => this.setState({statusDropdownOpen: !this.state.statusDropdownOpen})}>
          <Container>
            <Row>
              <Col xs={1}>
                <ValignMiddle>
                  <i className={bondStatusIconClass + ' h1'} />
                </ValignMiddle>
              </Col>
              <Col xs={10}>
                <h2>{title}</h2>
                <p>{descr}</p>
              </Col>
              <Col xs={1}>
                {
                  bondRecord.status_transitions && bondRecord.status_transitions.length > 0 && (
                    <ValignMiddle>
                      <i className={`fad ${this.state.statusDropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'} h3 float-right`} style={{position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', cursor: 'pointer'}} />
                    </ValignMiddle>
                  )
                }
              </Col>
            </Row>
          </Container>
        </Alert>

        {
          bondRecord.status_transitions && bondRecord.status_transitions.length > 0 && (
            <>
              <div className="position-absolute card p-4" style={{fontSize: '110%', display: this.state.statusDropdownOpen ? 'block' : 'none', marginTop: '-1em', zIndex: 99999999}}>
                {
                  bondRecord.status_transitions.map((transition, i) => {
                    const transitionName = transition.transition_name;
                    const transitionOptions = Settings.bondStatusTransitions[transitionName];
                    if (!transitionOptions) {
                      throw Error('UNKNOWN STATUS TRANSITION: ' + transitionName);
                    }

                    const statusData = Settings.bondStatusOptions[transition.to];
                    let targetStatusColor = statusData ? statusData.colorVariant : 'red';
                    let targetStatusIconClass = statusData ? statusData.iconClass : '';

                    const n = i + 1;
                    let loc = transitionOptions.page;

                    // apply transformations
                    loc = loc.replace('{customerWebsiteURL}', Settings.CUSTOMER_WEBSITE_URL);
                    loc = loc.replace('{recordId}', bondRecord.id);
                    loc = loc.replace('{bondRecordAccessKey}', bondRecord.auth_access_key)
                    loc = loc.replace('{productId}', bondRecord.product_id);

                    const button = <Button variant={targetStatusColor}>{transition.label}<i className="fad fa-arrow-right ml-2" /></Button>;

                    return (
                      <>
                        <Row noGutters>
                          <Col xs={1}>
                            <ValignMiddle>
                              <span className="float-left mr-3"><Badge variant="secondary">{n}</Badge></span>
                            </ValignMiddle>
                          </Col>
                          <Col xs={7}>
                            <ValignMiddle className="text-left">
                              {transition.descr}
                            </ValignMiddle>
                          </Col>
                          <Col xs={4}>
                            <ValignMiddle className="text-right">
                              {
                                loc ? (
                                  loc.indexOf('http') !== -1 ? (
                                    <a href={loc}>
                                      { button }
                                    </a>
                                  ) : (
                                    <Link to={loc}>
                                      { button }
                                    </Link>
                                  )
                                ) : (
                                  <Button variant="secondary" onClick={(e) => this.handleSubmit(transition)}><i className={`${targetStatusIconClass} mr-2`} />{transition.label}</Button>
                                )
                              }
                            </ValignMiddle>
                          </Col>
                        </Row>

                        {n != bondRecord.status_transitions.length && <hr />}
                      </>
                    );
                  })
                }
              </div>
            </>
          )
        }
      </div>
    );
  }
}

export default BondStatusTransitionsPanel;