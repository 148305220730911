import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import FieldGroup from './FieldGroup';

class ObjectTypeDataEditorFieldGroup extends React.Component {
  render() {
    let props = {...this.props, viewmode: false};

    return <FieldGroup {...props} />
  }
}

export default ObjectTypeDataEditorFieldGroup;