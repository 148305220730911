import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Row, Col, Alert } from 'react-bootstrap';

import Form from "@rjsf/core";
import RadioWidget from '@rjsf/core/dist/cjs/components/widgets/RadioWidget';
import TextWidget from '@rjsf/core/dist/cjs/components/widgets/TextWidget';
import ObjectTypeDataEditorFieldGroup from './layout/ObjectTypeDataEditorFieldGroup';
import DateField from './fields/DateField';

import moment from 'moment';
import 'moment-timezone';

class ObjectTypeDataEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChange = (refForm, errorSchema) => {
    if (JSON.stringify(refForm.formData) == JSON.stringify(this.props.data)) {
      // this looks like a bug in RJSF, onChange gets called even if data doesn't actually change
      return;
    }

    const formData = refForm.formData;
    const errors = refForm.errors.map((error) => {
      const fieldName = error.property.replace('.', '');
      return {
        type: error.name,
        fieldName: fieldName,
        message: error.stack.replace(error.property, fieldName)
      };
    });

    if (this.props.onChange) {
      return this.props.onChange(formData, errors);
    }
  }

  handleSubmit = (a, b) => {
    console.error('Form submit should not happen');

    return false;
  }

  handleError = (a, b) => {
    console.error('Form ERROR');
    console.dir(a);
    console.dir(b);
  }

  render() {
    const data = this.props.data;
    const schema = this.props.schema;
    let uiSchema = this.props.uiSchema;

    if (!schema || !uiSchema) {
      return <Alert as="p" variant="danger" className="mt-4"><i className="fa fa-exclamation-triangle" /> No form schema/ui schema</Alert>;
    }

    if (this.props.layout !== undefined) {
      uiSchema['ui:field'] = 'layout';
      uiSchema['ui:layout'] = this.props.layout;
    }

    const transformErrors = errors => {
      return errors.map(error => {
        // use error messages from JSON schema if any
        if (error && error.schema && error.schema.messages && error.schema.messages[error.name]) {
          return {
            ...error,
            message: error.schema.messages[error.name]
          };
        }

        return error;
      });
    };

    return (
      <Form
        schema={schema}
        uiSchema={uiSchema}

        noHtml5Validate
        liveValidate={true}
        showErrorList={false}
        ErrorList={null}
        transformErrors={transformErrors}

        formData={data}

        fields={{
          layout: ObjectTypeDataEditorFieldGroup
        }}

        widgets={{
          CheckboxWidget: (props) => {
            return (
              <div>
                <label className="control-label" htmlFor={props.id}>{props.label} {props.required && <span className="required">*</span>}</label>

                <RadioWidget {...props} />
              </div>
            )
          },
          DateWidget: (props) => {
            return (
              <div>
                <DateField value={props.value} onChange={props.onChange} />
              </div>
            );
          }
        }}

        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        onError={this.handleError}
      >
        {' ' /* Hide submit button */}
      </Form>
    );
  }
}

export default ObjectTypeDataEditor;