// core
import React from 'react';
import { Link } from 'react-router-dom';

// settings + utils
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';

import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';

// lib
import { DataTable, Column } from 'primereact/datatable';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova/theme.css';
import 'primeicons/primeicons.css';

// shared
import BaseListPage from '../../pages/lib/BaseListPage';
import Spinner from '../../components/lib/thss/Spinner';
import NoRecordsMessage from '../../components/shared/NoRecordsMessage';

import ButtonsToolbar from '../../components/shared/ButtonsToolbar';
import FiltersToolbar from '../../components/shared/filters/FiltersToolbar';
import MainHeading from '../../components/shared/MainHeading';
import FilterRestoreNotice from '../../components/shared/filters/FilterRestoreNotice';
import ListPageWrapper from '../../components/shared/ListPageWrapper';

// page-specific/etc.
import DataGrid from '../../components/lib/react-data-grid/lib/DataGrid';
import '../../components/lib/react-data-grid/dist/react-data-grid.css';

import StatesFilter from '../../components/shared/filters/StatesFilter';
import MgasFilter from '../../components/shared/filters/MgasFilter';

class MgasListPage extends BaseListPage {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state
    };
  }

  render() {
    return (
      <>
        <h1>MGAs</h1>
      </>
    );
  }
}

export default MgasListPage;