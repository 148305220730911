import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

class PageContentWrapper extends React.Component {
  render() {
    return (
      <div className="gig-admin-page-content mt-4">
        {this.props.children}
      </div>
    )
  }
}

export default PageContentWrapper;