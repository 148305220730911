import React from 'react';

import Settings from '../../../config';

import { Badge } from 'react-bootstrap';

import tinycolor from 'tinycolor2';

export default class BondStatusLabel extends React.PureComponent {
  render() {
    const { statusName, groupName, statusLabel, className, style} = this.props;

    if (!Settings.bondStatusOptions[statusName]) {
      console.error('no status with that name (will dump name)', statusName);

      return 'N/A';
    }

    const iconClass = Settings.bondStatusOptions[statusName].iconClass;
    const colorVariant = Settings.bondStatusOptions[statusName].colorVariant;
    const textColor = tinycolor(colorVariant).isDark() ? '#FFF' : 'inherit';

    return <Badge  className={`m-0 px-2 py-2 ${className}`} style={{...style, backgroundColor: colorVariant, color: textColor}}><i className={`${iconClass} mr-2`} />{statusLabel || statusName}</Badge>
  }
}