import React from 'react';

import { Card } from 'react-bootstrap';

import SlideDownTransition from './SlideDownTransition';

class Panel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: Boolean(props.collapsed)
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.collapsed != this.state.collapsed) {
      this.setState({
        collapsed: nextProps.collapsed
      });
    }
  }

  handleHeaderClick = () => {
    // if there's a callback, override behavior, otherwise just set to !collapsed
    if (typeof this.props.onHeaderClick != 'undefined') {
      return this.props.onHeaderClick();
    }

    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    const heightStyle = this.props.maxHeight ? {maxHeight: this.props.maxHeight + 'px', overflow: 'auto'} : {};
    const borderStyle = this.props.focused ? {} : {};

    return (
      <Card className={'' + (this.props.className ? ' ' + this.props.className : '')} style={this.props.style ? {...this.props.style, ...heightStyle, ...borderStyle} : {...heightStyle, ...borderStyle}}>
        {
          (this.props.title || this.props.headerRenderer === 'function') && (
            <Card.Header className="position-relative" style={{cursor: 'pointer'}} onClick={this.handleHeaderClick}>
              {
                typeof this.props.headerRenderer === 'function' ? (
                  this.props.headerRenderer(this.props.title)
                ) : (
                  <h4 className="m-0 text-dark">
                    {this.props.title}
                  </h4>
                )
              }

              {
                this.props.collapsable && (
                  <i className={`fad ${this.state.collapsed ? 'fa-chevron-down' : 'fa-chevron-up'} h4 text-secondary`} style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '0.75em'}} />
                )
              }
            </Card.Header>
          )
        }

        <SlideDownTransition open={!this.state.collapsed}>
          <Card.Body className={this.props.noPadding ? 'p-0' : ''} style={heightStyle}>
            {this.props.children}
          </Card.Body>
        </SlideDownTransition>
      </Card>
    );
  }
}

export default Panel;
