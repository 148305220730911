import React from 'react';

import { Alert } from 'react-bootstrap';

class Tooltip extends React.PureComponent {
  constructor(props) {
    super(props);

    this.defaultColor = '#158CBA';
  }

  render() {
    const  tooltipStyle = {
      position: 'relative',
      zIndex: 9999,

      width: '100%',

      color: this.props.colors.text,
      backgroundColor: this.props.colors.primary,
      borderColor: this.props.colors.darker
    };

    const stemStyle = {
      position: 'relative',
      top: '1px',
      left: 0,
      transform: 'translateY(-100%)',

      margin: '0 0 0 5px',
      padding: 0,

      height: '20px',
      width: '20px',

      fontSize: '20px',
      textAlign: 'left',

      borderColor: this.props.colors.darker + ' !important'
    };

    return (
      <Alert className="px-3 py-0" style={this.props.placeholder ? {...tooltipStyle, opacity: 0.15, filter: 'grayscale(100%)'} : {...tooltipStyle}}>
        <p style={stemStyle}>
          <i className="fas fa-triangle" style={{
            fontSize: '16px',
            color: this.props.colors.primary,
            textShadow: '0 -4px 0px ' + this.props.colors.darker,
          }} />
        </p>
        <div>
          <div style={{opacity: this.props.placeholder ? 0  : 100}}>
            {this.props.badge ? <span className="badge badge-light" style={{position: 'absolute', top: '10px', right: '10px'}}>{this.props.badge}</span> : ''}
            {this.props.children}
          </div>
        </div>
      </Alert>
    );
  }
}

export default Tooltip;