import React from 'react';
import { Link } from 'react-router-dom';

import Auth from '../../services/Auth';

class Sitemap extends React.Component {
  render() {
    return (
      <ul className="m-0 p-0">
        <li>01/25 - <Link to="/">HOME</Link></li>
        <li>Products
          <ul>
          <li>02/25 - <Link to="/bonds/">PRODUCT LIST</Link></li>
          <li>20/25 - <Link to="/bonds/find/">NEW APPLICATION</Link></li>
          </ul>
        </li>
        <li>BondRecords
          <ul>
            <li>03/25 - BOND_RECORD DETAILS
              <ul>
                <li>CREATE: <Link to="/bonds/01t2G000006XdnYQAS/quotes/-/new/?XHT675834">GA title bond</Link></li>
                <li>CREATE: <Link to="/bonds/01t2G000006XfL7QAK/quotes/-/new/?XHT675834">GA public adjuster</Link></li>
                <li>VIEW: <Link to="/applications/66//">BondRecord # 66</Link></li>
              </ul>
            </li>
            <li>06/25 - <Link to="/applications/">BOND_RECORD LIST</Link></li>
            <li><s>07/25 - BOND_RECORD LIST #2</s></li>
            <li>04/25 - <mark>ISSUE BOND</mark></li>
            <li>05/25 - <mark>RIDER</mark></li>
            <li>08/25 - <mark>BOND_RECORD DETAILS</mark></li>
            <li><s>09/25 - BOND_RECORD DETAILS #2</s></li>
          </ul>
        </li>
        <li>Agencies
          <ul>
            <li>10/25 - <Link to="/agencies/">AGENCY LIST</Link></li>
            <li>11/25 - <Link to="/agencies/-/new/">ADD AGENCY</Link></li>
            <li>12/25 - <Link to="/agencies/1//">AGENCY DETAILS</Link>
              <ul>
                <li><Link to="">12A - AGENCY DETAILS</Link></li>
                <li><Link to="">12B - AGENCY USERS</Link></li>
                <li><Link to="">12C - AGENCY USER DETAILS</Link></li>
              </ul>
            </li>
          </ul>
        </li>
        <li>MGAs
          <ul>
            <li>13/25 - <Link to="/mgas/">MGA LIST</Link></li>
            <li>14/25 - <Link to="/mgas/-/new/">ADD MGA</Link></li>
            <li>15/25 - <Link to="/mgas/1//">MGA DETAILS</Link></li>
          </ul>
        </li>
        <li>Etc.
          <ul>
            <li>16/25 - <Link to="/reports/">REPORTS</Link></li>
            <li>17/25 - <a href="javascript:void(0);" onClick={(e) => {Auth.setToken(''); window.location.reload();}}>LOGIN</a></li>
            <li>18/25 - <mark>PASSWORD RESET</mark></li>
            <li>19/25 - <mark>USER SIGNUP</mark></li>
            <li>21/25 - <Link to="/inventory">INVENTORY</Link></li>
            <li><s>22/25 - RESERVED</s></li>
            <li><s>23/25 - RESERVED</s></li>
            <li><s>24/25 - RESERVED</s></li>
            <li><s>25/25 - RESERVED</s></li>
          </ul>
        </li>
      </ul>
    );
  }
}

export default Sitemap;