import React from 'react';

import {Container, Row, Col, Button } from 'react-bootstrap';

import { FileIcon, defaultStyles } from 'react-file-icon';
import ValignMiddle from '../lib/thss/ValignMiddle';

class FileUploadForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChange = (e) => {
    const files = Array.from(e.target.files);
    if (!files || !files.length) {
      return;
    }

    const file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      if (this.props.onChange) {
        this.props.onChange(event.target.result, file);
      }
    };
  }

  handleSubmit = () => {
    if (this.props.working) {
      return;
    }

    if (this.props.onSubmit) {
      this.props.onSubmit(this.props.typeName, null);
    }
  }

  handleCancel = () => {
    if (this.props.onChange) {
      this.props.onChange('', null);
    }
  }

  render() {
    let ext = '';
    let { formData, saving, success, error } = this.props;
    if (formData) {
      ext = formData.filename ? formData.filename.split('.').pop() : '';
    }

        // file = {
    //   name: 'test.pdf',
    //   size: 20690
    // }

    return (
      <div className="gig-admin-file-upload-form">
        <ValignMiddle center>
          {
            formData ? (
              <div className={'gig-admin-file-upload-form__upload-preview' + (saving ? ' gig-admin-file-upload-form__upload-preview--busy' : '')}>
                <ValignMiddle>
                  <div className="gig-admin-file-upload-form__upload-preview-box">
                    {
                      success && <h2 className="m-0 p-0 text-success" style={{fontSize: '45px'}}><i className="fa fa-check" /></h2>
                    }
                    {
                      error && <h2 className="m-0 p-0 text-danger" style={{fontSize: '45px'}}><i className="fa fa-exclamation-triangle" /></h2>
                    }
                    {
                      !success && !error && (
                        <>
                          <span className="gig-admin-file-upload-form__upload-preview-icon-container mr-2">
                            <FileIcon extension={ext} {...defaultStyles[ext]} />
                          </span>
                          <span className="mr-2"><span className="gig-admin-file-upload-form__upload-preview-filename">{formData.filename}</span>, <span className="gig-admin-file-upload-form__upload-preview-file-size">{(formData.file_size/1024/1024).toFixed(2)} MB</span></span>
                          <hr />
                          <Button className={saving ? 'disabled' : ''} onClick={this.handleSubmit}>Submit</Button> or <a href="javascript:void(0)" onClick={this.handleCancel}>cancel</a>
                        </>
                      )
                    }
                  </div>
                </ValignMiddle>
              </div>
            ) : (
              <div className="form-group bg-light m-0 p-0 gig-admin-file-upload-form__form">
                <input type="file" className="btn form-control" multiple="" onChange={this.handleChange} />
              </div>
            )
          }
        </ValignMiddle>
      </div>
    );
  }
}

export default FileUploadForm;