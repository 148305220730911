import React from 'react';
import {Link} from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from '../../components/lib/thss/Panel';

class ButtonsToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let related = [];
    if (this.props.related && this.props.related.length) {
      related = this.props.related.map((link) => {
        var linkClass = '';
        var iconClass = '';

        if (link.type) {
          // eslint-disable-next-line default-case
          switch (link.type) {
            case 'primary':
            case 'important':
              linkClass = 'btn-primary';
              break;
            case 'success':
              linkClass = 'btn-success';
              iconClass = 'fa fa-check';
              break;
            case 'saving':
              linkClass = 'btn-secondary disabled';
              iconClass = 'fa fa-spinner fa-spin';
              break;
            case 'danger':
            case 'error':
              linkClass = 'btn-danger';
              iconClass = 'fa fa-exclamation-triangle';
              break;
              case 'save':
                linkClass = 'btn-primary';
                iconClass = 'fa fa-save';
                break;
              case 'cancel':
                linkClass = 'btn-secondary';
                iconClass = 'fa fa-times';
                break;
            case 'edit':
              linkClass = 'btn-primary';
              iconClass = 'fa fa-pencil';
              break;
            case 'add':
              linkClass = 'btn-primary';
              iconClass = 'fa fa-plus';
              break;
            case 'continue':
              linkClass = 'btn-primary';
              iconClass = 'fa fa-arrow-right';
              break;
            case 'delete':
            case 'remove':
            case 'trash':
              linkClass = 'btn-danger';
              iconClass = 'fa fa-trash';
              break;
            case '':
            case 'default':
              linkClass = 'btn-secondary';
          }
        } else {
          linkClass = 'btn-secondary';
        }
        // override icon if provided
        if (link.icon) {
          iconClass = link.icon;
        }

        if (link.href && !link.onClick) {
          return (
            <Link className={'btn ' + linkClass + ' mr-2' + (link.animation ? ' animated ' + link.animation : '')} to={link.href}>
              {iconClass ? <i className={iconClass + (link.label ? ' mr-2' : '')} /> : ''}
              {link.animation}
              {link.label}
            </Link>
          );
        }
        if (link.onClick) {
          return (
            <a className={'btn ' + linkClass + (link.animation ? ' animated ' + link.animation : '') + ' mr-2'} href="javascript:void(0);" onClick={link.onClick}>
              {iconClass ? <i className={iconClass + ' mr-2'} /> : ''}
              {link.label}
            </a>
          );
        }
        if (!link.href && !link.onClick) {
          return (
            <span className={(link.animation ? ' animated ' + link.animation : '') + 'mr-2'} style={{cursor: 'default'}}>
              {iconClass ? <i className={iconClass + ' mr-2'} /> : ''}
              {link.label}
            </span>
          );
        }
      });
    }

    return (
      <div className="d-inline-block align-middle">
        {related}
      </div>
    );
  }
}

export default ButtonsToolbar;