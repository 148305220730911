import React from 'react';

import { MultiSelect } from 'primereact/multiselect';

class BondRecordTypesFilter extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange = (types) => {
    if (this.props.onChange) this.props.onChange(types);
  }

  render() {
    if (!this.props.types || !Array.isArray(this.props.types)) {
      console.warn('No bond record types, return early');

      return '';
    }

    return (
      <>
        {
          this.props.label && <p className="mt-0 mb-1 text-muted"><small>{this.props.label}</small></p>
        }

        <MultiSelect
          optionLabel="label"
          optionValue="value"
          placeholder="—"
          value={this.props.selected}
          options={this.props.types ? this.props.types.map((type) => {
            return {
              label: type.label,
              value: type.name
            };
          }) : []}
          filter={false}
          maxSelectedLabels={0}
          selectedItemTemplate={(option) => option ? option + ', ' : ''}
          selectedItemsLabel={(() => {
            const MAX_SHOWN = 10;

            if (!this.props.selected || !this.props.selected.length) {
              return 'Select type';
            }

            if (this.props.selected && this.props.types && this.props.selected.length == this.props.types.length) {
              return this.props.allLabel || 'All types';
            }

            let label = '';
            for (let i=0; i<this.props.selected.length; i++) {
              const name = this.props.selected[i];
              const elem = this.props.types.find((elem) => elem.name == name)

              if (i < MAX_SHOWN) {
                if (i) label += ', ';
                label += elem ? elem.label : '';
              } else {
                label += ' + ' + (this.props.selected.length - MAX_SHOWN) + ' more...';

                break;
              }
            };


            return label;
          })()}
          onChange={(e) => this.handleChange(e.value)}

          style={{width: '100%'}}
        />
      </>
    );
  }
}

export default BondRecordTypesFilter;