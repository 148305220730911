import React from 'react';

import { Container, Row, Col, Button, Badge} from 'react-bootstrap';
import ValignMiddle from '../../../components/lib/thss/ValignMiddle';
import ProfilePicture from './ProfilePicture';
import Address from './Address';

class Person extends React.PureComponent {
  render() {
    const { data } = this.props;
    return (
      <div className="w-100 position-relative">
        {
          this.props.showPicture === undefined || this.props.showPicture == true && (
            <div className="float-left mr-2">
              <ProfilePicture username={data.username || ''} className="pr-1 pb-2" />
            </div>
          )
        }
        <div className="float-left">
          <h4 className="mt-0">{data.first_name} {data.middle_name} {data.last_name}</h4>
            <p className="mb-0">
              <span className="mr-4"><i className="fad fa-envelope mr-1" />{data.email ? <a href={`mailto:${data.email}`}>{data.email}</a> : 'N/A'}</span>
              <span className="mr-4"><i className="fad fa-phone mr-1" />{data.phone ? <a href={`tel:${data.phone}`}>{data.phone}</a> : 'N/A'}</span>
            </p>

            { data.fax && <p className="mt-1"><span className="mr-4"><i className="fad fa-fax mr-1" />{data.fax ? <a href={`fax:${data.fax}`}>{data.fax}</a> : 'N/A'}</span></p> }
            { data.ssn && <p className="mt-1"><Badge variant="secondary">SSN</Badge> {data.ssn}</p> }
        </div>

        <div className="clearfix" />

        <div className="mt-2">
          <Address data={data} />
        </div>
      </div>
    )
  }
}

export default Person;
















