import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from '../../components/lib/thss/Panel';

import Spinner from '../../components/lib/thss/Spinner';

class MainHeading extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <h1 className="m-0 d-inline-block align-middle mr-4">
          {this.props.icon ? <i className={this.props.icon + ' mr-2'} /> : ''}
          {this.props.title}
        </h1>

        {this.props.subtitle && <h2 className="text-muted mb-0">{this.props.subtitle}</h2>}
        {this.props.descr && <p>{this.props.descr}</p>}

        {
          this.props.children && (
            <div className="d-inline-block align-middle">
              {this.props.children}
            </div>
          )
        }
        <div className="clearfix" />
      </div>
    );
  }
}

export default MainHeading;