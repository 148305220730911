import React from 'react';

import { Container, Row, Col, Button, Badge} from 'react-bootstrap';
import ValignMiddle from '../../components/lib/thss/ValignMiddle';
import Address from '../shared/elems/Address';

class BondIndemnitor extends React.PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div className="w-100 position-relative">
        <div className="w-100 float-left">
          <h4 className="w-100 mt-0">
            {data.first_name} {data.middle_name} {data.last_name}

            <span className="float-right"><small>Score:</small> <Badge variant="secondary">{data.fico_score}</Badge></span>
          </h4>

          <p className="mb-0">
            <span className="mr-4"><i className="fad fa-envelope mr-1" />{data.email ? <a href={`mailto:${data.email}`}>{data.email}</a> : 'N/A'}</span>
            <span className="mr-4"><i className="fad fa-phone mr-1" />{data.phone ? <a href={`tel:${data.phone}`}>{data.phone}</a> : 'N/A'}</span>
          </p>

          { data.fax && <p className="mt-1"><span className="mr-4"><i className="fad fa-fax mr-1" />{data.fax ? <a href={`fax:${data.fax}`}>{data.fax}</a> : 'N/A'}</span></p> }
        </div>

        <div className="clearfix" />

        <div className="mt-2">
          <Address data={data} />
        </div>

        <div className="mt-2">
          <p className="mt-1">SSN: {data.ssn}</p>
          <p className="mt-1">% owner: {data.ownership_percentage}</p>
          <p className="mt-1">Past dues: <Badge variant={data.past_dues_amount ? 'danger' : 'success'}>{data.past_dues_amount ? `$ ${data.past_dues_amount}` : 'none'}</Badge></p>
          <p className="mt-1">
            <span className="mr-2">
              {
                data.is_us_citizen ? (
                  <Badge variant="success"><i className="fad fa-flag-usa mr-2" />US citizen</Badge>
                ) : (
                  <Badge variant="danger"><i className="fad fa-exclamation-triangle mr-2" />Non-US citizen</Badge>
                )
              }
            </span>
            <span className="mr-2">
              {
                !data.is_felon ? (
                  <Badge variant="success"><i className="fad fa-check mr-2" />Not a felon</Badge>
                ) : (
                  <Badge variant="danger"><i className="fad fa-exclamation-triangle mr-2" />Felon</Badge>
                )
              }
            </span>
            <span className="mr-2">
              {
                !data.has_previous_claim ? (
                  <Badge variant="success"><i className="fad fa-check mr-2" />No previous claim</Badge>
                ) : (
                  <Badge variant="danger"><i className="fad fa-exclamation-triangle mr-2" />Ha previous claim</Badge>
                )
              }
            </span>
          </p>
          <p className="mt-1">
            <span className="mr-2">
              {
                data.no_bankruptcy ? (
                  <Badge variant="success"><i className="fad fa-check mr-2" />No bankruptcy</Badge>
                ) : (
                  <Badge variant="danger"><i className="fad fa-exclamation-triangle mr-2" />Bankruptcy</Badge>
                )
              }
            </span>
            <span className="mr-2">
              {
                data.no_derogatory_items ? (
                  <Badge variant="success"><i className="fad fa-check mr-2" />No derogatory items</Badge>
                ) : (
                  <Badge variant="danger"><i className="fad fa-exclamation-triangle mr-2" />Derogatory items</Badge>
                )
              }
            </span>
            <span className="mr-2">
              {
                data.no_past_due_child_support ? (
                  <Badge variant="success"><i className="fad fa-check mr-2" />No past due child support</Badge>
                ) : (
                  <Badge variant="danger"><i className="fad fa-exclamation-triangle mr-2" />Past due child support</Badge>
                )
              }
            </span>
          </p>
        </div>
      </div>
    )
  }
}

export default BondIndemnitor;
















