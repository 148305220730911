const Settings = {
  API_URL: process.env.REACT_APP_API_URL,
  CUSTOMER_WEBSITE_URL: process.env.REACT_APP_CUSTOMER_WEBSITE_URL,

  AUTHORIZE_NET_PAYMENT_ENDPOINT: process.env.REACT_APP_AUTHORIZE_NET_PAYMENT_ENDPOINT,
  AUTHORIZENET_LOGIN_ID: process.env.REACT_APP_AUTHORIZENET_LOGIN_ID,
  AUTHORIZENET_CLIENT_KEY: process.env.REACT_APP_AUTHORIZENET_CLIENT_KEY,

  BOND_STATUS_UNDERWRITING: 'UNDERWRITING_REVIEW',

  RELATIONSHIP_TYPE_ONE_TO_ONE: 'one/one',
  RELATIONSHIP_TYPE_ONE_TO_MANY: 'one/many',

  NOBODY_USER_USERNAME: 'nobody',

  icons: {
    objectTypes: {
      task: 'fad fa-check',
      mga: 'fad fa-building',
      agency: 'fad fa-store',
      product: 'fad fa-file-certificate',
      bond_record: 'fad fa-file-certificate',
      bond_business: 'fad fa-store',
      bond_applicant: 'fad fa-male',
      bond_indemnitor: 'fad fa-user-shield',
      draft: 'fad fa-file',
      inventory: 'fad fa-archive',
      report: 'fad fa-chart-line',
      updates: 'fad fa-stream',
      notes: 'fad fa-comment-alt-lines',
      uploads: 'fad fa-cloud-upload',
      assignments: 'fad fa-tasks'
    },
    logEntryTypes: {
      APPROVAL: 'fad fa-check',
      PAYMENT: 'fad fa-dollar-sign',
      UPLOAD: 'fad fa-cloud-upload',
      SIGNATURE: 'fad fa-file-signature',
      CANCELLATION: 'fad fa-ban'
    },
    misc: {
      business: 'fad fa-store',
      person: 'fad fa-male',
      indemnitor: 'fad fa-user-shield'
    }
  },

  bondStatusOptions: {
    DRAFT: {
      iconClass: 'fad fa-file',
      colorVariant: '#BFBFBF'
    },

    CANCELLATION_ONGOING: {
      iconClass: 'fad fa-backspace',
      colorVariant: '#BFBFBF'
    },
    CANCELLED_FLAT: {
      iconClass: 'fad fa-times',
      colorVariant: '#BFBFBF'
    },
    INACTIVE: {
      iconClass: 'fad fa-snooze',
      colorVariant: '#BFBFBF'
    },
    VOID: {
      iconClass: 'fad fa-ban',
      colorVariant: '#BFBFBF'
    },
    NOT_RENEWED: {
      iconClass: 'fad fa-check-double fontawesome-slashed',
      colorVariant: '#BFBFBF'
    },
    REMARKET_REJECTED: {
      iconClass: 'fad fa-exchange fontawesome-slashed',
      colorVariant: '#BFBFBF'
    },
    UNDERWRITING_CLOSED: {
      iconClass: 'fad fa-user-slash',
      colorVariant: '#BFBFBF'
    },
    VOID_DUPLICATE: {
      iconClass: 'fad fa-copy fontawesome-slashed',
      colorVariant: '#BFBFBF'
    },

    COMPLETE: {
      iconClass: 'fad fa-check',
      colorVariant: '#4472C4'
    },
    REINSTATED: {
      iconClass: 'fad fa-redo-alt',
      colorVariant: '#4472C4'
    },
    RENEWED: {
      iconClass: 'fad fa-check-double',
      colorVariant: '#4472C4'
    },

    BOND_ISSUE_READY: {
      iconClass: 'fad fa-file-check',
      colorVariant: '#00B050'
    },
    BOND_ISSUED: {
      iconClass: 'fad fa-file-certificate',
      colorVariant: '#00B050'
    },
    SIGNATURE_REQUIRED: {
      iconClass: 'fad fa-file-signature',
      colorVariant: '#00B050'
    },

    APPROVED: {
      iconClass: 'fad fa-thumbs-up',
      colorVariant: '#FF944B'
    },
    PAYMENT_REQUIRED: {
      iconClass: 'fad fa-receipt',
      colorVariant: '#FF944B'
    },

    RENEWAL_INFO_REQUIRED: {
      iconClass: 'fad fa-comment-alt-exclamation',
      colorVariant: '#FFD965'
    },
    RENEWAL_PENDING: {
      iconClass: 'fad fa-hourglass-half',
      colorVariant: '#FFD965'
    },

    PAID: {
      iconClass: 'fad fa-dollar-sign',
      colorVariant: '#A5A5A5'
    },
    PAY_CHARGE_AGENCY: {
      iconClass: 'fad fa-dollar-sign',
      colorVariant: '#A5A5A5'
    },

    REMARKET: {
      iconClass: 'fad fa-exchange',
      colorVariant: '#FF7C80'
    },
    REMARKET_INFO_REQUIRED: {
      iconClass: 'fad fa-comment-alt-exclamation',
      colorVariant: '#FFC9C9'
    },

    UNDERWRITING_REVIEW: {
      iconClass: 'fad fa-user-clock',
      colorVariant: '#FF7C80'
    },
    UNDERWRITING_INFO_REQUIRED: {
      iconClass: 'fad fa-comment-alt-exclamation',
      colorVariant: '#FFC9C9'
    },
  },

  // to change status of bond records, sometimes we need to visit a page rather than simply call the endpoint. Specify that here.
  // NOTE: {customerWebsiteURL} gets replaced with CUSTOMER_WEBSITE_URL, {recordId} with the bond record ID, {bondRecordAccessKey} with the auth access key for the bond record, {productId} with the product ID
  // TODO: maybe this can be implemented with a high-order component. not that much of a difference though
  /*

  draft -> underwriting: OK

  */
  bondStatusTransitions: {// TODO: any way to make these come from the API..?
    //////////////////// draft -> underwriting /////////////////////

    // from draft, bond can go into UNDERWRITING_REVIEW first, and go through a workflow that could lead to the bond being APPROVED, REMARKET, etc.
    require_underwriting: {
      page: ''
    },

    // invalid bond
    mark_invalid: {
      page: ''
    },
    void_duplicate: {
      page: ''
    },

    // want, and info needed. We request info (UNDERWRITING_INFO_REQUIRED), and we could reject because we don't want, or because info was not provided
    underwriting_request_info: {
      page: ''
    },
    underwriting_info_received: {
      page: ''
    },
    underwriting_reject: {
      page: ''
    },

    // everything OK, APPROVE bond after UNDERWRITING_REVIEW workflow
    underwriting_approve: {
      page: ''
    },

    // don't want, REMARKET
    remarket: {
      page: ''
    },
    remarket_request_info: {
      page: ''
    },
    remarket_info_received: {
      page: ''
    },
    market_found: {
      page: ''
    },
    remarket_reject: {
      page: ''
    },

    //////////////////// draft -> approved /////////////////////
    // from DRAFT, bond can be APPROVED right away (or, go to UNDERWRITING_REVIEW, see below)
    approve_draft: {
      page: '/bonds/{productId}/quotes/{recordId}/'
    },

    //////////////////// pay /////////////////////
    // if a bond was finally APPROVED, it goes to PAYMENT_REQUIRED. After paying it can go to BOND_ISSUE_READY if no signatures required, or SIGNATURE_REQUIRED
    require_payment: {
      page: ''
    },

    // if approved but no one pays for it, it goes to INACTIVE. If after following up with customer customer wants it again, it can go back to PAYMENT_REQUIRED and we keep going
    make_inactive: {
      page: ''
    },
    make_active: {
      page: ''
    },

    // bond can get paid via CC payment (PAID) or pre-approved anticipating ACH sweep (PAY_CHARGE_AGENCY)
    pay_cc: {
      page: ''
    },
    pay_charge_agency: {
      page: ''
    },

    //////////////////// payment -> ready to issue (signature required) /////////////////////

    require_indemnity: {
      page: ''
    },
    indemnity_uploaded: {
      page: ''
    },

    //////////////////// payment -> ready to issue /////////////////////
    require_indemnity: {
      page: ''
    },

    //////////////////// issue bond! /////////////////////
    bond_issue_bond: {
      page: ''
    },

    //////////////////// complete /////////////////////
    // bond has been issued and the process is complete.
    complete: {
      page: ''
    },

    //////////////////// cancel /////////////////////
    // at this point though, the customer could want to cancel the bond. At this point we handle cancelling (NOTE: "CANCELLED_FLAT" means that cancelling can be done right away)
    cancel_flat: {
      page: ''
    },
    cancel: {
      page: ''
    },

    ///////////////////// etc. /////////////////////
    renewal_pending: {
      page: ''
    },
    renewal_request_info: {
      page: ''
    },
    reinstated: {
      page: ''
    },
    reinstated_notice: {
      page: ''
    },
    trigger_renewal: {
      page: ''
    },
    not_renewed: {
      page: ''
    },
  }
};

export default Settings;
