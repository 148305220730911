import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

class PageHeaderWrapper extends React.Component {
  render() {
    return (
      <div className="p-0 m-0 gig-admin-page-header shadow-sm">
        {this.props.children}
      </div>
    );
  }
}

export default PageHeaderWrapper;