// core
import React from 'react';
import { Link } from 'react-router-dom'

// settings + utils
import moment from 'moment';
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';
import pluralize from 'pluralize';

import { Container, Row, Col, Button, Alert, Badge, Tooltip, Nav, Tabs, Tab } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';
import ProfilePicture from '../../components/shared/elems/ProfilePicture';

// lib
import NumberFormat from 'react-number-format';
import BaseDetailsPage from '../../pages/lib/BaseDetailsPage';
import Spinner from '../../components/lib/thss/Spinner';
import ObjectTypeItemsPanel from '../../components/lib/thss/json-schema-ui/ObjectTypeItemsPanel';

// shared
import PanelHeaderWrapper from '../../components/shared/layout/PanelHeaderWrapper';
import PageContentWrapper from '../../components/shared/layout/PageContentWrapper';
import ValignMiddle from '../../components/lib/thss/ValignMiddle';
import SlideDownTransition from '../../components/lib/thss/SlideDownTransition';
import MainHeading from '../../components/shared/MainHeading';
import ObjectTypePanel from '../../components/lib/thss/json-schema-form/ObjectTypePanel';
import TimestampLabel from '../../components/shared/elems/TimestampLabel';

// page-specific/etc.
import Person from '../../components/shared/elems/Person';
import Business from '../../components/shared/elems/Business';
import BondIndemnitor from '../../components/bonds/BondIndemnitor';
import BondFilesPanel from '../../components/bonds/BondFilesPanel';
import BondNotesPanel from '../../components/bonds/BondNotesPanel';
import BondAssignmentsPanel from '../../components/bonds/BondAssignmentsPanel';
import BondStatusTransitionsPanel from '../../components/bonds/BondStatusTransitionsPanel.js';
import BondLogEntry from '../../components/shared/elems/BondLogEntry';
import { relativeTimeThreshold } from 'moment-timezone';

class BondRecordDetailsPage extends BaseDetailsPage {
  constructor(props) {
    super(props);

    this.COLLAPSABLE_STATUS_BY_TYPE_CACHE_KEY = 'sbm:BondRecordDetailsPage:collapsedStatusByType';

    const collapsedStatusByType = JSON.parse(localStorage.getItem(this.COLLAPSABLE_STATUS_BY_TYPE_CACHE_KEY)) || {};
    const showBondDataByType = {
      'bond_public_note': false,
      'bond_private_note': false,
      'bond_public_file': false,
      'bond_private_file': false,
      'bond_assignment': false
    };
    this.state = {
      ...this.state,

      showBondDataByType: showBondDataByType,
      collapsedStatusByType: collapsedStatusByType
    };
  }

  __init = (props, callback) => {
    const { id } = this.__getParams(props);

    // if page ID changed, reload data
    if (!this.__getPageData('bond_record') || this.__getPageData('bond_record').id != id) {
      this.__setStatus(this.STATUS_FETCHING);

      this.__fetchAll(id, () => {
        this.__setStatus(this.STATUS_READY);

        if (callback) callback();
      });
    }
  }

  __afterInit = () => {
    const bondRecord= this.__getPageData('bond_record');
    if (!bondRecord) {
      throw Error('Unable to retrieve bond record');
    }

    this.__setPageData(bondRecord.product, 'product', () => {
      this.setState({
        showStatusAlert: true
      });
    });

    // handle custom fields form
    this.__setFormSchemaOverride(bondRecord.product_form_schema, 'bond_mso_custom_fields');
    let layout = [];
    Object.keys(bondRecord.product_form_schema.properties).forEach((propertyName) => {
      layout.push({[propertyName]: {xs: 12}});
    });
    this.__setFormLayoutOverride(layout, 'bond_mso_custom_fields');
  }

  __refreshData = () => {
    this.__abortAll();

    const { id } = this.__getParams(this.props);

    this.__setStatus(this.STATUS_FETCHING);

    this.__fetchAll(id, () => {
      this.__setStatus(this.STATUS_READY);
    });
  }

  __fetchAll = (id, callback) => {
    const chainedCallback = () => {
      // refresh related types w/ bond toggle
      Object.entries(this.state.showBondDataByType).forEach(([key, value]) => {
        this.fetchRelatedItems(key, value);
      });

      callback();
    };

    this.__fetchObjectTypeItems('bond_record', id, chainedCallback, Object.keys(this.state.showBondDataByType));
  }

  handleStatusTransition = (transition) => {
    const { id } = this.__getParams(this.props);

    this.__setStatus(this.STATUS_FETCHING);

    const method = 'PUT';
    const endpoint = `${Settings.API_URL}/api/v1/bond-records/${id}/status`;
    const payload = {transition_name: transition.transition_name};

    this.__fetch(method, endpoint, payload, {
      headers: {
        'Authorization': 'Bearer ' + Auth.getToken()
      }
    }, (response, error) => {
      if (error || !response || !response.ok) {
        console.error('ERROR while saving in UDP', error, response);

        this.__setStatus(this.STATUS_ERROR);
        this.__setErrors([...this.__getErrors(), {
          message: response ? response.error : error
        }]);

        window.setTimeout(() => {
          this.__setStatus(this.STATUS_READY);
        }, 1500);

        return;
      }

      this.__refreshData();
    });
  }

  handleTypeItemsHeaderClick = (typeName) => {
    const collapsedStatusByType = {
      ...this.state.collapsedStatusByType,

      [typeName]: !Boolean(this.state.collapsedStatusByType[typeName])
    };

    this.setState({
      collapsedStatusByType: collapsedStatusByType
    }, () => {
      localStorage.setItem(this.COLLAPSABLE_STATUS_BY_TYPE_CACHE_KEY, JSON.stringify(collapsedStatusByType));
    });
  }

  fetchShowBondDataByType = (type) => {
    return this.state.showBondDataByType[type];
  };

  handleShowBondChange = (type, showBond) => {
    this.fetchRelatedItems(type, showBond);

    this.setState(prevState => ({
      showBondDataByType: {
        ...prevState.showBondDataByType,
        [type]: showBond
      }
    }));
  };

  fetchRelatedItems = (relatedTypeName, showBondData, callback) => {
    const done = () => {
      if (callback) callback();
    }

    const bondRecord = this.__getPageData('bond_record');
    if (!bondRecord) {
      return;
    }

    const relatedTypeData = this.__getTypeDataByName(relatedTypeName, 'bond_record');
    if (!relatedTypeData) {
      throw Error('unknown related type');
    }

    const defaultHeaders = {
      'Authorization': 'Bearer ' + Auth.getToken()
    };
    const defaultOptions = {
      headers: defaultHeaders
    };

    if (!relatedTypeData.endpoints) {
      throw Error('no endpoints for type ' + relatedTypeName);
    }

    let endpointData = relatedTypeData.has_many ? relatedTypeData.endpoints.list : relatedTypeData.endpoints.show;
    if (endpointData === undefined) {
      throw Error('no endpoint data for type ' + relatedTypeName);
    }

    let endpoint = endpointData.url;
    if (endpoint) {
      const params = [];

      if (bondRecord.bond_id) {
        params.push(`bond_id=${bondRecord.bond_id}`);
      }

      endpoint = endpoint.replace('{refId}', bondRecord.id);

      if (params.length) {
        endpoint += (endpoint.includes('?') ? '&' : '?') + params.join('&');
      }

      const call = {
        method: 'GET',
        endpoint: endpoint,
        payload: {},
        options: {...defaultOptions},
        callback: (response, error) => {
          if (error || !response || !response.ok) {
            this.__setStatus(this.STATUS_ERROR);
            this.__setErrors([...this.__getErrors() || [], {
              message: response ? response.message : (error ? error.message || error.toString() : 'unknown error' )
            }]);

            return;
          }

          const data = response.data[endpointData.data_key];
          this.__setPageData(data, relatedTypeName);
          this.__setFormData(data, relatedTypeName);
        }
      };
      this.__fetchMultiple([call], (responses, errors) => {
        done();
      });
    } else {
      done();
    }
  }

  getTitle = (bondRecord) => {
    const tokens = [];

    // Set the bond number as the prefix
    if (bondRecord.bond_number) {
      tokens.push(`BOND #${bondRecord.bond_number}`);
    }

    if (bondRecord.type_name !== 'BOND') {
      tokens.push(`${bondRecord.type_name} #${bondRecord.id}`);
    }

    return tokens.join(' - ');
  };

  render() {
    const getFileCabinetPanel = (page) => (
      <>



      </>
    );

    const getBondPanel = (page) => (
      <>
        {/* Handle bond record data (use single component since it's not array)  */}
        <Panel className="mb-4" collapsable={false}>
          <PanelHeaderWrapper sticky={false}>
            <Container>
              <Row>
                <Col xs={7}>
                  <h4 className="m-0 mt-2">Application</h4>
                </Col>
                <Col xs={5}>
                  <ValignMiddle className="text-right">
                    {
                      page.props.appData.permissions.object_types.find(type => type.name == 'bond_record').permissions.update && (
                        <>
                          {
                            !page.__isItemFocused('bond_record') && <Button variant="light" className={page.__isItemFocused() ? 'disabled' : ''} onClick={(e) => page.__handleToggleEdit('bond_record', recordId)}><i className="fad fa-pencil mr-2" />Edit</Button>
                          }
                          {
                            page.__isItemFocused('bond_record', recordId) && (
                              <>
                                {
                                  page.__getStatus() == page.STATUS_BUSY && <Spinner message="Saving changes..." />
                                }
                                {
                                  page.__getStatus() == page.STATUS_SUCCESS && <><i className="fad fa-check text-success" /> Changes saved</>
                                }
                                {
                                  page.__getStatus() == page.STATUS_READY &&  (
                                    <>
                                      <Button variant="primary" onClick={(e) => page.__handleSave('bond_record', recordId, null, null)}><i className="fad fa-save mr-2" />Save changes</Button>
                                      <span className="ml-1">or <a href="javascript:void(0)" onClick={(e) => page.__handleToggleEdit('', '')}>cancel</a></span>
                                    </>
                                  )
                                }
                              </>
                            )
                          }
                        </>
                      )
                    }
                  </ValignMiddle>
                </Col>
              </Row>
            </Container>
          </PanelHeaderWrapper>

          <ObjectTypePanel
            typeName="bond_record"
            schema={page.__getFormSchema('bond_record')}
            layout={page.__getFormLayout('bond_record')}

            data={page.__isItemFocused('bond_record', recordId) ? page.__getFormData('bond_record') : page.__getPageData('bond_record')}

            ready={page.__getStatus() != page.STATUS_FETCHING}
            focused={page.__isItemFocused('bond_record', recordId)}

            onChange={(formData, errors) => page.__setFormData(formData, 'bond_record')}
          />
        </Panel>
      </>
    );

    const getSecondaryPanel = (typeName, options, bondRecord, page) => {
      // see if we should return early
      if (!bondRecord) {
        return '';
      }
      if (typeName == 'bond_business' && bondRecord && !bondRecord.is_business) {
        return '';
      }
      if (['bond_indemnitors_global_options', 'bond_indemnitor'].indexOf(typeName) !== -1 && bondRecord && !bondRecord.is_credit_required) {
        return '';
      }

      const typeData = page.__getTypeDataByName(typeName, 'bond_record');
      if (!typeData) {
        return <p className="text-danger">Type {typeName} cannot be found</p>;
      }

      // get basic info about type
      const label = typeData.label;
      const schema = page.__getFormSchema(typeName, 'bond_record');
      const layout = page.__getFormLayout(typeName, 'bond_record');
      const min = typeData.min;
      const max = typeData.max;

      // get status information
      const fetching = page.__getStatus() == page.STATUS_FETCHING;
      const saving = page.__getFocusedItemTypeName() == typeName && page.__getStatus() == page.STATUS_BUSY;
      const success = page.__getFocusedItemTypeName() == typeName && page.__getStatus() == page.STATUS_SUCCESS;
      const error = ''; // TODO: handle errors here

      // get options
      const { collapsable } = options;

      return (
        <>
          <h3>{label}</h3>

          <ObjectTypeItemsPanel
            className="mb-4"
            typeName={typeName}
            label=""
            schema={schema}
            layout={layout}

            data={page.__getPageData(typeName)}
            formData={page.__getFormData(typeName)}

            min={min}
            max={max}
            disableEditing={typeName == 'bond_payment'}
            expandAdd={false}

            focus={{
              typeName: page.__getFocusedItemTypeName(),
              itemId: page.__getFocusedItemId()
            }}

            fetching={fetching}
            saving={saving}
            success={success}
            error={error}

            itemRenderer={
              [
                'bond_applicant',
                'bond_indemnitor',
                'bond_business'
              ].indexOf(typeName) === -1 ? null : (
                (myTypeName, mySchema, myLayout, myItemData, isEditing, isFetching) => {
                if (!myItemData) {
                  console.warn('WARNING: no item data while getting sidebar item');

                  return <p>WARNING: no item data while getting sidebar item</p>;
                }

                switch (myTypeName) {
                  case 'bond_applicant':
                    return <Person data={myItemData} showPicture={false} />;
                  case 'bond_business':
                    return <Business data={myItemData} />;
                  case 'bond_indemnitor':
                    return <BondIndemnitor data={myItemData} />;
                  default:
                    return 'UNKNOWN TYPE';
                }
              })
            }

            collapsable={collapsable}
            collapsed={page.state.collapsedStatusByType[typeName]}
            onHeaderClick={page.handleTypeItemsHeaderClick}

            onToggleEdit={page.props.appData.permissions.object_types.find(type => type.name == typeName).permissions.update ? page.__handleToggleEdit : null}
            onChange={page.__setFormData}
            onSave={(typeName, itemId) => page.__handleSave(typeName, itemId, 'bond_record', recordId, () => null)}
          />
        </>
      );
    };

    const { id } = this.__getParams();

    const bondRecord = this.__getPageData('bond_record');
    const recordId = bondRecord ? bondRecord.id : '';
    const product = this.__getPageData('product');

    const hasIndemnitors = this.__getPageData('bond_indemnitor') && this.__getPageData('bond_indemnitor').length > 0;

    return (
      <>
        <PageContentWrapper>
          <Container fluid>
            <Row>
              <Col>
                  {/* Header */}
                  <Panel>
                    <Container fluid className="pt-3 pb-2">
                      {
                        product && (
                          <>
                            <Row noGutters>
                              <Col xs={7}>
                                <MainHeading
                                  icon={bondRecord.bond_number ? Settings.bondStatusOptions['BOND_ISSUED'].iconClass : Settings.bondStatusOptions['DRAFT'].iconClass}
                                  title={this.getTitle(bondRecord)}
                                  subtitle={(
                                    <>
                                      <span className="d-block h3">{bondRecord.product_state_code} {product.name}
                                        {
                                          !this.props.user.agency_id && (
                                            <a href={product.salesforce_product_link} target="_blank" className="align-top"
                                              title="View product in Salesforce">
                                              <i className="fad fa-external-link fa-xs ml-3" />
                                            </a>
                                          )
                                        }
                                      </span>
                                      <span className="d-block h3 text-dark mt-3"><i className={`${bondRecord.is_business ? Settings.icons.misc.business : Settings.icons.misc.person} mr-2`} />{`${bondRecord.principal_name}`}</span>
                                    </>
                                  )}
                                  descr=""
                                />

                                <p>
                                  Submitted by: <strong>{bondRecord.created_by}</strong> on <TimestampLabel dateOnly={false} timeOnly={false} utc={bondRecord.created_at} /><br />
                                  Assigned to:
                                  {
                                    !bondRecord.assigned_to && (
                                      <span className="text-muted ml-1" style={{height: '3em'}}>
                                        <i className="fad fa-user-slash mr-2" />nobody
                                      </span>
                                    )
                                  }
                                  {
                                    bondRecord.assigned_to == Settings.NOBODY_USER_USERNAME && (
                                      <span className="text-success" style={{height: '3em'}}>
                                        <i className="fad fa-check ml-1" />no action required
                                      </span>
                                    )
                                  }
                                  {
                                    bondRecord.assigned_to && bondRecord.assigned_to != Settings.NOBODY_USER_USERNAME && (
                                      <span className="ml-1" style={{height: '3em'}}>
                                        <strong>{bondRecord.assigned_to}</strong>
                                      </span>
                                    )
                                  }
                                </p>
                              </Col>
                              <Col xs={5}>
                                {/* Status */}
                                <SlideDownTransition open={this.state.showStatusAlert}>
                                  <BondStatusTransitionsPanel status={this.__getPageData('bond_status')} bondRecord={bondRecord} product={product} onSubmit={this.handleStatusTransition} />
                                </SlideDownTransition>
                              </Col>
                            </Row>
                          </>
                        )
                      }
                    </Container>
                  </Panel>

                  {/* Errors */}
                  {
                    this.__hasErrors() && (
                      <>
                        <Alert variant="danger">
                          <h2>Sorry, something went wrong</h2>
                          {
                            this.__getErrors().length > 0 && (
                              <>
                                <p>The following error message(s) can be used to diagnose the problem:</p>
                                <ul>
                                  {
                                    this.__getErrors().map((error) => {
                                      return <li>{error.message}</li>
                                    })
                                  }
                                </ul>
                              </>
                            )
                          }
                        </Alert>
                      </>
                    )
                  }
              </Col>
            </Row>
          </Container>

          <Container fluid className="mt-4">
            <Row>
              <Col xs={7}>
                {
                  !bondRecord ? (
                    <p><Spinner message="Waiting for data..." /></p>
                  ) : (
                    <Tab.Container defaultActiveKey="bond">
                      <div className="" style={{backgroundColor: '#eaeaea'}}>
                        <Nav variant="tabs" className="">
                          <Nav.Item>
                            <Nav.Link eventKey="bond" className="pl-4 pr-4 pt-3 pb-3" style={{borderBottom: '5px solid #fff', marginBottom: '-5px'}}>
                              <i className={`${Settings.icons.objectTypes.product} mr-2`} />Bond
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="principal" className="pl-4 pr-4 pt-3 pb-3" style={{borderBottom: '5px solid #fff', marginBottom: '-5px'}}>
                              <i className={`${bondRecord.is_business ? Settings.icons.objectTypes.bond_business : Settings.icons.objectTypes.bond_applicant} mr-2`} />Principal
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="indemnitors" className="pl-4 pr-4 pt-3 pb-3" style={{borderBottom: '5px solid #fff', marginBottom: '-5px'}}>
                              <i className={`${Settings.icons.objectTypes.bond_indemnitor} mr-2`} />Indemnitors ({!this.__getPageData('bond_indemnitor') ? 0 : this.__getPageData('bond_indemnitor').length})
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="billing_shipping" className="pl-4 pr-4 pt-3 pb-3" style={{borderBottom: '5px solid #fff', marginBottom: '-5px'}}>
                              <i className="fad fa-money-check-alt mr-2" />Billing & shipping
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="system_info" className="pl-4 pr-4 pt-3 pb-3" style={{borderBottom: '5px solid #fff', marginBottom: '-5px'}}>
                              <i className="fad fa-info-square mr-2" />System info
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                      <Tab.Content className="card p-4">
                        <Tab.Pane eventKey="principal">
                          {
                            [
                              'bond_applicant',
                              'bond_business',
                              'bond_risk_assessment_options'
                            ].map((typeName) => {
                              return getSecondaryPanel(typeName, { collapsed: true }, bondRecord, this);
                            })
                          }
                        </Tab.Pane>
                        <Tab.Pane eventKey="indemnitors">
                          {
                            hasIndemnitors ? (
                              <>
                                {
                                  [
                                    'bond_indemnitor'
                                  ].map((typeName) => {
                                    return getSecondaryPanel(typeName, { collapsed: true }, bondRecord, this);
                                  })
                                }
                              </>
                            ) : (
                              <p className="h3 text-muted text-center mt-5"><i className="fad fa-ban mr-2" />No indemnitors</p>
                            )
                          }
                        </Tab.Pane>
                        <Tab.Pane eventKey="bond">
                          {
                            product && (
                              <>
                                <Row>
                                  <Col xs={4}>
                                    <img className="w-100 border" src={bondRecord.product.bond_preview_url.replace('stagedownloads.ezsuretybonds.com', 'downloads.ezsuretybonds.com')} />
                                  </Col>
                                  <Col xs={8}>
                                    <h3>
                                      {bondRecord.product_state_code} {product.name}
                                      {
                                        !this.props.user.agency_id && (
                                          <a href={product.salesforce_product_link} target="_BLANK" className="h4 text-primary ml-3" title="View product in Salesforce">
                                            <i className="fad fa-external-link" />
                                          </a>
                                        )
                                      }
                                    </h3>

                                    <p>
                                      <big>
                                        Penalty: <Badge variant="light">{bondRecord.bond_penalty_amount ? <><NumberFormat value={bondRecord.bond_penalty_amount} displayType={'text'} thousandSeparator={true} prefix={'$'} /></> : 'N/A'}</Badge><br />
                                        Premium: <Badge variant="light">{bondRecord.bond_premium_amount ? <><NumberFormat value={bondRecord.bond_premium_amount} displayType={'text'} thousandSeparator={true} prefix={'$'} /></> : 'N/A'}</Badge><br />
                                        Effective: <Badge variant="light">{bondRecord.effective_date ? <TimestampLabel dateOnly={true} timeOnly={false} utc={bondRecord.effective_date} /> : 'N/A'}</Badge> {bondRecord.expiration_date && <> - <Badge variant="light"><TimestampLabel dateOnly={true} timeOnly={false} utc={bondRecord.expiration_date} /></Badge></>}
                                      </big>
                                    </p>
                                  </Col>
                                </Row>

                                <hr />
                              </>
                            )
                          }

                          {
                            getBondPanel(this)
                          }
                          {
                            [
                              'bond_mso_custom_fields'
                            ].map((typeName) => {
                              return getSecondaryPanel(typeName, { collapsed: true }, bondRecord, this);
                            })
                          }
                        </Tab.Pane>
                        <Tab.Pane eventKey="billing_shipping">
                          {
                            [
                              'bond_payment',
                              'bond_shipping_options'
                            ].map((typeName) => {
                              return getSecondaryPanel(typeName, { collapsed: true }, bondRecord, this);
                            })
                          }
                        </Tab.Pane>
                        <Tab.Pane eventKey="system_info">
                          {
                            [
                              'bond_rating_details'
                            ].map((typeName) => {
                              return getSecondaryPanel(typeName, { collapsed: true }, bondRecord, this);
                            })
                          }
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  )
                }
              </Col>
              <Col xs={5}>
                <BondNotesPanel
                  className=""
                  style={null}

                  typeData={this.__getTypeDataByName('bond_public_note', 'bond_record')}

                  data={this.__getPageData('bond_public_note')}
                  formData={this.__getFormData('bond_public_note')}

                  showBondData={this.fetchShowBondDataByType('bond_public_note')}
                  onShowBondChange={(e) => this.handleShowBondChange('bond_public_note', e)}

                  focus={{
                    typeName: 'bond_public_note',
                    itemId: null
                  }}

                  fetching={this.__getStatus() == this.STATUS_FETCHING}
                  saving={(this.__getFormData('bond_public_note') && this.__getFormData('bond_public_note').length > (this.__getPageData('bond_public_note') ? this.__getPageData('bond_public_note').length : 0)) && this.__getStatus() == this.STATUS_BUSY}
                  success={(this.__getFormData('bond_public_note') && this.__getFormData('bond_public_note').length > (this.__getPageData('bond_public_note') ? this.__getPageData('bond_public_note').length : 0)) && this.__getStatus() == this.STATUS_SUCCESS}
                  error=""

                  onToggleEdit={null}
                  onChange={(formData, typeName) => this.__setFormData(formData, typeName, true, this.__getPageData('bond_public_note') ? this.__getPageData('bond_public_note').length : 0, false, null)}
                  onSave={(typeName, itemId) => this.__handleSave(typeName, itemId, 'bond_record', recordId, () => null)}

                  user={this.props.user}
                  permissions={this.props.appData.permissions.object_types.find(type => type.name == 'bond_public_note').permissions}
                />

                <BondNotesPanel
                  className="mt-4"
                  style={{filter: 'invert(0.8)'}}

                  typeData={this.__getTypeDataByName('bond_private_note', 'bond_record')}

                  data={this.__getPageData('bond_private_note')}
                  formData={this.__getFormData('bond_private_note')}

                  showBondData={this.fetchShowBondDataByType('bond_private_note')}
                  onShowBondChange={(e) => this.handleShowBondChange('bond_private_note', e)}

                  focus={{
                    typeName: 'bond_private_note',
                    itemId: null
                  }}

                  fetching={this.__getStatus() == this.STATUS_FETCHING}
                  saving={(this.__getFormData('bond_private_note') && this.__getFormData('bond_private_note').length > (this.__getPageData('bond_private_note') ? this.__getPageData('bond_private_note').length : 0)) && this.__getStatus() == this.STATUS_BUSY}
                  success={(this.__getFormData('bond_private_note') && this.__getFormData('bond_private_note').length > (this.__getPageData('bond_private_note') ? this.__getPageData('bond_private_note').length : 0)) && this.__getStatus() == this.STATUS_SUCCESS}
                  error=""

                  onToggleEdit={null}
                  onChange={(formData, typeName) => this.__setFormData(formData, typeName, true, this.__getPageData('bond_private_note') ? this.__getPageData('bond_private_note').length : 0, false, null)}
                  onSave={(typeName, itemId) => this.__handleSave(typeName, itemId, 'bond_record', recordId, () => null)}

                  user={this.props.user}
                  permissions={this.props.appData.permissions.object_types.find(type => type.name == 'bond_private_note').permissions}
                />

                <BondAssignmentsPanel
                  className="mt-4"
                  style={null}

                  typeData={this.__getTypeDataByName('bond_assignment', 'bond_record')}

                  data={this.__getPageData('bond_assignment')}
                  formData={this.__getFormData('bond_assignment')}

                  showBondData={this.fetchShowBondDataByType('bond_assignment')}
                  onShowBondChange={(e) => this.handleShowBondChange('bond_assignment', e)}

                  focus={{
                    typeName: 'bond_assignment',
                    itemId: null
                  }}

                  fetching={this.__getStatus() == this.STATUS_FETCHING}
                  saving={(this.__getFormData('bond_assignment') && this.__getFormData('bond_assignment').length > (this.__getPageData('bond_assignment') ? this.__getPageData('bond_assignment').length : 0)) && (this.__getStatus() == this.STATUS_BUSY || this.__getStatus() == this.STATUS_FETCHING)} // TODO: use keyed
                  success={(this.__getFormData('bond_assignment') && this.__getFormData('bond_assignment').length > (this.__getPageData('bond_assignment') ? this.__getPageData('bond_assignment').length : 0)) && this.__getStatus() == this.STATUS_SUCCESS} // TODO: use keyed status for this
                  error=""

                  onToggleEdit={null}
                  onToggleEdit={null}
                  onChange={(formData, typeName) => this.__setFormData(formData, typeName, true, this.__getPageData('bond_assignment') ? this.__getPageData('bond_assignment').length : 0, false, null)}
                  onSave={(typeName, itemId) => this.__handleSave(typeName, itemId, 'bond_record', recordId, () => null)}

                  user={this.props.user}
                  teammates={this.props.appData.permissions.team_usernames}
                  underwriter={this.props.appData.permissions.underwriter_username}

                  accessKey={bondRecord ? bondRecord.auth_access_key : ''}
                />
              </Col>
            </Row>
          </Container>

         <Container fluid>
            <Row>
              <Col xs={6}>
                <BondFilesPanel
                  className="mt-4"
                  style={null}

                  typeData={this.__getTypeDataByName('bond_public_file', 'bond_record')}

                  data={this.__getPageData('bond_public_file')}
                  formData={this.__getFormData('bond_public_file')}

                  showBondData={this.fetchShowBondDataByType('bond_public_file')}
                  onShowBondChange={(e) => this.handleShowBondChange('bond_public_file', e)}

                  focus={{
                    typeName: 'bond_public_file',
                    itemId: null
                  }}

                  fetching={this.__getStatus() == this.STATUS_FETCHING}
                  saving={(this.__getFormData('bond_public_file') && this.__getFormData('bond_public_file').length > (this.__getPageData('bond_public_file') ? this.__getPageData('bond_public_file').length : 0)) && (this.__getStatus() == this.STATUS_BUSY || this.__getStatus() == this.STATUS_FETCHING)} // TODO: use keyed
                  success={(this.__getFormData('bond_public_file') && this.__getFormData('bond_public_file').length > (this.__getPageData('bond_public_file') ? this.__getPageData('bond_public_file').length : 0)) && this.__getStatus() == this.STATUS_SUCCESS} // TODO: use keyed status for this
                  error=""

                  onToggleEdit={null}
                  onChange={(formData, typeName) => {
                    this.__setFormData(formData, typeName, true, this.__getPageData('bond_public_file') ? this.__getPageData('bond_public_file').length : 0, false, null)
                  }}
                  onSave={(typeName, itemId) => this.__handleSave(typeName, itemId, 'bond_record', recordId, () => null)}

                  user={this.props.user}
                  accessKey={bondRecord ? bondRecord.auth_access_key : ''}
                  permissions={this.props.appData.permissions.object_types.find(type => type.name == 'bond_public_file').permissions}
                />
              </Col>
              <Col xs={6}>
                <BondFilesPanel
                  className="mt-4"
                  style={{filter: 'invert(0.8)'}}

                  typeData={this.__getTypeDataByName('bond_private_file', 'bond_record')}

                  data={this.__getPageData('bond_private_file')}
                  formData={this.__getFormData('bond_private_file')}

                  showBondData={this.fetchShowBondDataByType('bond_private_file')}
                  onShowBondChange={(e) => this.handleShowBondChange('bond_private_file', e)}

                  focus={{
                    typeName: 'bond_private_file',
                    itemId: null
                  }}

                  fetching={this.__getStatus() == this.STATUS_FETCHING}
                  saving={(this.__getFormData('bond_private_file') && this.__getFormData('bond_private_file').length > (this.__getPageData('bond_private_file') ? this.__getPageData('bond_private_file').length : 0)) && this.__getStatus() == this.STATUS_BUSY}
                  success={(this.__getFormData('bond_private_file') && this.__getFormData('bond_private_file').length > (this.__getPageData('bond_private_file') ? this.__getPageData('bond_private_file').length : 0)) && this.__getStatus() == this.STATUS_SUCCESS}
                  error=""

                  onToggleEdit={null}
                  onChange={(formData, typeName) => this.__setFormData(formData, typeName, true, this.__getPageData('bond_private_file') ? this.__getPageData('bond_private_file').length : 0, false, null)}
                  onSave={(typeName, itemId) => this.__handleSave(typeName, itemId, 'bond_record', recordId, () => null)}

                  user={this.props.user}
                  accessKey={bondRecord ? bondRecord.auth_access_key : ''}
                  permissions={this.props.appData.permissions.object_types.find(type => type.name == 'bond_private_file').permissions}
                />
              </Col>
            </Row>
          </Container>

          <Container fluid>
            <Row>
              <Col xs={12}>
                  {
                    this.__getPageData('bond_log_entry') && (
                      <>
                        <h2 className="gig-admin-section-title float-left mt-0 mb-4"><i className={Settings.icons.objectTypes.updates + ' mr-2'} />
                          Latest updates
                        </h2>

                        <div className="clearfix" />
                        {
                          this.__getPageData('bond_log_entry').map((update) => {
                            return <BondLogEntry entry={update} />
                          })
                        }
                      </>
                    )
                  }

              </Col>
            </Row>
          </Container>
        </PageContentWrapper>
      </>
    );
  }
}

export default BondRecordDetailsPage;