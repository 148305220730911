import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from '../../../components/lib/thss/Panel';

class FilterRestoreNotice extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className={'animated ' + (this.props.shown ? 'fadeInDown' : 'fadeOutUp')}>
        <Row>
          <Col xs={12}>
          <div className="popover fade bs-popover-top show" style={{position: 'absolute', top: '-55px', left: '15px'}}>
            <div className="arrow" style={{left: '50px'}}></div>

            <div className="popover-body">
              <i className="fa fa-check text-success" style={{fontSize: '20px', float: 'left', marginRight: '12px', marginTop: '0'}} /> Filters restored
            </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FilterRestoreNotice;