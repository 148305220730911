// core
import React from 'react';
import { Link } from 'react-router-dom'

// settings + utils
import Settings from '../../../config.js';
import Auth from '../../../services/Auth.js';

import { Container, Row, Col, Button, Alert, Card, Badge } from 'react-bootstrap';
import Panel from '../../../components/lib/thss/Panel';

// lib
import NumberFormat from 'react-number-format';
import BaseDetailsPage from '../../../pages/lib/BaseDetailsPage';
import Spinner from '../../../components/lib/thss/Spinner';
import ValignMiddle from '../../../components/lib/thss/ValignMiddle';
import SlideDownTransition from '../../../components/lib/thss/SlideDownTransition';

// shared
import PageHeaderWrapper from '../../../components/shared/layout/PageHeaderWrapper';
import PageContentWrapper from '../../../components/shared/layout/PageContentWrapper';
import ButtonsToolbar from '../../../components/shared/ButtonsToolbar';
import FiltersToolbar from '../../../components/shared/filters/FiltersToolbar';
import Address from '../../../components/shared/elems/Address';

import MainHeading from '../../../components/shared/MainHeading';
import FilterRestoreNotice from '../../../components/shared/filters/FilterRestoreNotice';
import ListPageWrapper from '../../../components/shared/ListPageWrapper';

// page-specific/etc.
import PaymentForm from '../../../components/bonds/BondPaymentForm';
import BondForm from '../../../components/bonds/bond-form/BondForm';
import Progress from '../../../components/bonds/bond-form/Progress';
import BondPdfViewer from '../../../components/bonds/BondPdfViewer';
import ResponsiveEmbed from 'react-responsive-embed';
import ErrorPanel from '../../../components/shared/ErrorPanel';

class BondRecordIssuancePage extends BaseDetailsPage {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      shippingMethodId: 'USPS',
      shippingAddressId: null,

      paymentSubmitting: false,
      paymentErrorMessage: '',
      paymentTransactionId: null
    };
  }

  __init = (props, callback) => {
    this.fetchData(() => {
      this.__setStatus(this.STATUS_READY);
      if (callback) callback();
    });
  }

  fetchData = (callback) => {
    this.__setStatus(this.STATUS_FETCHING);

    const { recordId } = this.props.match.params;
    if (!recordId) {
      throw Error('no bond record ID');
    }

    const defaultOptions = {
      headers: {
        'Authorization': 'Bearer ' + Auth.getToken()
      }
    };

    // fetch bond record first
    this.__fetch('GET', `${Settings.API_URL}/api/v1/bond-records/${recordId}`, {}, defaultOptions)
      .then((response) => {
        this.__setPageData(response.data.record, 'bond_record');
        this.__setPageData(response.data.record.product, 'product');

        const productId = response.data.product_id;
        if (!productId) {
          throw Error('no product ID');
        }

        // // fetch product
        // this.__fetch('GET', `${Settings.API_URL}/api/v1/products/${productId}`, {}, defaultOptions)
        //   .then((response) => {
        //     this.__setPageData(response.data.product, 'product');

        //     if (callback) callback();
        //   })
        //   .catch((error) => {
        //     console.error('PRODUCT ERROR!', error);
        //     this.__setErrors([
        //       {
        //         message: 'cannot retrieve product'
        //       }
        //     ])
        //     if (callback) callback();
        //   });

        // fetch customer addresses

        // fetch agency addresses
      })
      .catch((error) => {
        this.__setErrors([
          {
            message: 'cannot retrieve bond record'
          }
        ])
        if (callback) callback();
      });
  }

  // bond flags/calculated values
  getBondData = (propertyName, defaultValue) => {
    const bondRecord = this.__getPageData('bond_record');
    let value;

    if (bondRecord) {
      value = bondRecord[propertyName];
    } else {
      value = defaultValue;
    }

    return value;
  }

  isAgent = () => {
    return false; // TODO: get from user role
  }

  isBondUnderwriting = () => {
    return this.getBondData('status_name', null) == Settings.BOND_STATUS_UNDERWRITING;
  }
  getBondPenaltyAmount = () => {
    return parseInt(this.getBondData('bond_penalty_amount', 0));
  }
  getBondPremiumAmount = () => {
    return parseInt(this.getBondData('bond_premium_amount', 0));
  }
  getExpirationDate = ()  => {
    return this.getBondData('expiration_date', '');
  }
  getBondRate = () => {
    return parseInt(this.getBondData('bond_premium_percent_rate', 0));
  }
  getBondNumber = () => {
    return this.getBondData('bond_number', 'N/A');
  }
  getShippingCosts = () => {
    for (let i=0; i<this.props.appData.shippingMethods.length; i++) {
      const shippingMethod = this.props.appData.shippingMethods[i];

      if (shippingMethod.id === this.state.shippingMethodId) {
        return parseInt(shippingMethod.price);
      }
    }

    return '';
  }

  getAgencyCommissionRate = () => {
    return 0;
  }
  getAgencyCommissionAmount = () => {
    return this.getAgencyCommissionRate() ? this.getBondPremiumAmount() / 100 * this.getAgencyCommissionRate() : 0;
  }

  getAgentAddresses = () => {
    if (this.isAgent()) {
      // TODO: get addresses from agency
    } else {
      return [];
    }
  }
  getCustomerAddresses = () => {
    return []; // you should get this from the applicant object
  }

  handleSelectAddress = (addressId) => {
    this.setState({
      shippingAddressId: addressId
    });
  }

  handleShippingChange = (e) => {
    const shippingMethodId = e.currentTarget.value;

    this.setState({
      shippingMethodId
    });
  }

  handlePaymentFormSubmit = (formData) => {
    this.setState({
      paymentTransactionId: -1 // TODO: enable real payments again
    });

    this.setState({
      paymentSubmitting: true,
      paymentErrorMessage: ''
    }, () => {
      const expMonth = formData.expiry.split('/')[0];
      const expYear = '20' + formData.expiry.split('/')[1];
      const exp = expYear + '-' + expMonth;

      const ccPayload = {
        createTransactionRequest: {
            merchantAuthentication: {
                name: Settings.AUTHORIZENET_LOGIN_ID,
                transactionKey: Settings.AUTHORIZENET_CLIENT_KEY
            },
            refId: this.state.bondRecordData.item_number,
            transactionRequest: {
                transactionType: 'authCaptureTransaction',
                amount: (parseFloat(this.state.bondRecordData.premium) + this.state.shippingFee).toFixed(2),
                payment: {
                    creditCard: {
                        cardNumber: formData.number.split(' ').join(''),
                        expirationDate: exp,
                        cardCode: formData.cvc
                    }
                },
                lineItems: {
                    lineItem: {
                        itemId: this.props.bond.sbm_ref_code.substr(0, 5),
                        name: this.props.bond.name.substr(0, 25) + ' [...]', // limit is 31 chars
                        description: '',
                        quantity: 1,
                        unitPrice: parseFloat(this.state.bondRecordData.premium).toFixed(2)
                    }
                },
                shipping: {
                    amount: parseFloat(this.state.shippingFee).toFixed(2),
                    name: this.state.shippingMethod,
                    description: ''
                },
                poNumber: '',
                customer: {
                    id: ''
                },
                billTo: {
                    firstName: formData.name.split(' ')[0],
                    lastName: formData.name.split(' ')[1],
                    company: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: ''
                },
                shipTo: {},
                customerIP: '',
                transactionSettings: {},
                userFields: {}
            }
        }
      };
      const ccEndpoint = Settings.AUTHORIZE_NET_PAYMENT_ENDPOINT;

      fetch(ccEndpoint, {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ccPayload)
      })
      .then(res => res.json())
      .then((json) => {
        // handle errors
        let errors = [];
        if (json.transactionResponse == undefined) {
          errors.push('Error from Authorize.NET API—please contact us');
        } else {
          if (json.transactionResponse.errors !== undefined && json.transactionResponse.errors) {
            errors = errors.concat(json.transactionResponse.errors.map((error) => {
              return error.errorText;
            }));
          }
          if (!json.transactionResponse.transId) {
            errors.push('No transaction ID');
          }
        }

        if (errors.length) {
          this.setState({
            paymentSubmitting: false,
            paymentErrorMessage: errors.join(', ')
          }, () => {
            window.setTimeout(() => {
              this.setState({
                paymentErrorMessage: ''
              });
            }, 2000);
          });

          return;
        }

        // payment OK, handle next steps
        this.setState({
          paymentSubmitting: false,
          paymentErrorMessage: '',

          step4PaymentSuccess: true
        });

        // send GTM event
        // NOTE: we have a transaction ID so we're reasonably sure the payment went through. We could wait until the system double-checks the payment and the bond gets issued, but we'd risk never capturing the event if there are errors with that
        window.dataLayer.push({
          'event': 'purchase',
          "transactionId": json.transactionResponse.transId,
          "transactionAffiliation": "EZSB website",
          "transactionTotal": parseFloat(ccPayload.createTransactionRequest.transactionRequest.amount),
          "transactionTax": 0,
          "transactionShipping": parseFloat(ccPayload.createTransactionRequest.transactionRequest.shipping.amount),
          "transactionProducts": [
            {
              "sku": ccPayload.createTransactionRequest.transactionRequest.lineItems.lineItem.itemId,
              "name": ccPayload.createTransactionRequest.transactionRequest.lineItems.lineItem.name,
              "category": 'Bonds',
              "quantity": parseInt(ccPayload.createTransactionRequest.transactionRequest.lineItems.lineItem.quantity),
              "price": parseFloat(ccPayload.createTransactionRequest.transactionRequest.lineItems.lineItem.unitPrice)
            }
          ]
        });

        // issue bonds (include slight delay to make it look better)
        window.setTimeout(() => {
          this.setState({
            step4PaymentWindowShown: false,

            step4IssueSubmitting: true,
            step4IssueErrorMessage: '',
          }, () => {
            const payload = {
              shipping_method: this.state.shippingMethod,
              shipping_fee: this.state.shippingFee,

              transaction_id: json.transactionResponse.transId
            };
            const endpoint = Settings.API_URL + 'issuances/' + this.state.bondRecordData.issuance_code;
            fetch(endpoint, {
              method: 'POST',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload)
            })
            .then(res => res.json())
            .then((json) => {
              if (!json.ok || !json.data.issuance_data.bond_number) {
                this.setState({
                  step4IssueSubmitting: false,

                  step4IssueErrorMessage: json.error
                });

                return false
              } else {
                this.setState({
                  step4IssueSubmitting: false,
                  step4IssueErrorMessage: '',

                  issuanceData: json.data.issuance_data
                }, () => {
                  this.goToStep(5);
                })
              }
            });
          });
        }, 1750);
      });
    });
  }

  shippingOK = () => {
    return this.state.shippingMethodId && this.state.shippingAddressId;
  }
  paymentOK = () => {
    return this.state.paymentTransactionId;
  }
  isReadyToIssue = () => {
    return this.state.shippingMethodId && this.state.shippingAddressId && (this.isAgent() || this.state.paymentTransactionId);
  }

  handleIssueBond = () => {
    alert('Will issue bond');
  }

  render() {
    const { recordId, productId } = this.props.match.params;

    const product = this.__getPageData('product');
    const bondRecord = this.__getPageData('bond_record');
    const bondRecordFormData = this.__getFormData('bond_payload');
    const bondRecordFormSchema = this.__getFormSchema('bond_payload');
    const bondRecordFormLayout = null; // Settings.formLayouts.bond_record;

    const sample = this.__getPageData('pdf_sample');

    const penaltyAmount = this.getBondPenaltyAmount();
    const bondRate = this.getBondRate();
    const premiumAmount = this.getBondPremiumAmount();
    const shippingCost = this.getShippingCosts();
    const agencyCommissionAmount = this.getAgencyCommissionAmount();
    const agencyCommissionRate = this.getAgencyCommissionRate();
    const customerTotal = premiumAmount + shippingCost;
    const withdrawalAmount = premiumAmount + shippingCost - agencyCommissionAmount;

    const agentAddresses = this.getAgentAddresses();
    const customerAddresses = this.getCustomerAddresses();

    if (!bondRecord || !product) {
      return '';
    }

    return (
      <>
        <PageHeaderWrapper>
          <Container fluid className="pt-3 pb-3">
            <Row>
              <Col xs={12}>
                <MainHeading
                  icon={Settings.icons.objectTypes.product}
                  title={`Application # ${bondRecord.bond_number}/${bondRecord.id}`}
                  subtitle={`Niccolo TEST, ${product.name}`}
                  descr=""
                />

                <p className="mt-2" style={{fontSize: '110%'}}>
                  <span className="badge badge-pill badge-secondary mr-2"><i className="fad fa-info mr-2" />{this.getBondData('status_name')}</span>
                </p>
              </Col>
            </Row>
          </Container>
        </PageHeaderWrapper>

        <PageContentWrapper>
          {
            this.isBondUnderwriting() && (
              <Container fluid>
                <Row>
                  <Col xs={12}>
                    <Panel className="mb-4">
                      <h1>Your bond went to underwriting!</h1>
                      {
                        this.isAgent() ? (
                          <p>Please wait for admin to handle</p>
                        ): (
                          <p><Link to={`/applications/${bondRecord.id}/`}><Button variant="primary">Go to application</Button></Link> to continue.</p>
                        )
                      }
                    </Panel>
                  </Col>
                </Row>
              </Container>
            )
          }
          {
            this.isBondUnderwriting() == false && (
              <>
                <Container fluid>
                  <Row>
                    <Col xs={7}>
                      <Panel className="mb-4">
                        <h2 className="mt-0"><i className="fad fa-truck mr-1" /> Shipping</h2>

                        <Row>
                          <Col xs={6}>
                            <p><strong>Ship to agent:</strong></p>
                            {
                              agentAddresses.length ? (
                                agentAddresses.map((address) => {
                                  return (
                                    <Row>
                                      <Col xs={1}>
                                        <ValignMiddle><input type="radio" /></ValignMiddle></Col>
                                      <Col xs={11}><Address data={address} /></Col>
                                    </Row>
                                  );
                                })
                              ) : (
                                <p className="text-muted"><i className="fad fa-ban mr-2" />no addresses/not an agent</p>
                              )
                            }
                          </Col>
                          <Col xs={6}>
                            <p><strong>Ship to customer:</strong></p>
                            {
                              customerAddresses.map((address) => {
                                const selected = this.state.shippingAddressId == address.id;

                                return (
                                  <div className={'p-2 rounded' + (selected ? ' alert-success' : '')} style={{cursor: 'pointer'}} onClick={(e) => this.handleSelectAddress(address.id)}>
                                    <Row>
                                      <Col xs={1}>
                                        <ValignMiddle><input type="radio" checked={selected} /></ValignMiddle></Col>
                                      <Col xs={11}><Address data={address} /></Col>
                                    </Row>
                                  </div>
                                );
                              })
                            }
                          </Col>
                        </Row>

                        <h3>Shipping method</h3>
                        <div style={{maxWidth: '300px'}}>
                          <select className="form-control" value={this.state.shippingMethodId} onChange={this.handleShippingChange}>
                            {
                              this.props.appData.shippingMethods.map((shippingMethod) => {
                                return (
                                  <option value={shippingMethod.id}>{shippingMethod.name} ({shippingMethod.price === 0 ? 'free' : '$' + shippingMethod.price})</option>
                                );
                              })
                            }
                          </select>
                        </div>
                      </Panel>

                      <Panel className="mb-4">
                        <h2><i className="fad fa-money-check-alt mr-1" /> Payments and commissions</h2>

                        <Row noGutters>
                          <Col xs={6}>
                            <big className="text-muted">Premium to customer:</big>
                          </Col>
                          <Col xs={6} className="text-right">
                            <big>{premiumAmount ? '$' + premiumAmount : 'N/A'}</big>
                          </Col>
                        </Row>
                        <Row noGutters>
                          <Col xs={6}>
                            <big className="text-muted">Shipping cost (via {this.state.shippingMethodId}):</big>
                          </Col>
                          <Col xs={6} className="text-right">
                            <big>${shippingCost}</big>
                          </Col>
                        </Row>
                        <hr />
                        <Row noGutters>
                          <Col xs={6}>
                            <big className="text-muted">Total to customer:</big>
                          </Col>
                          <Col xs={6} className="text-right">
                            <big>{customerTotal ? '$' + customerTotal : 'N/A'}</big>
                          </Col>
                        </Row>

                        <Row noGutters>
                          <Col xs={12}>
                            <big>Your agency will receive a commission of: <big className="badge badge-success">{agencyCommissionAmount ? '$' + agencyCommissionAmount : 'N/A'}</big> <span className="text-muted">{agencyCommissionRate ? '(' + agencyCommissionRate + '%' + ')' : ''}</span></big>
                          </Col>
                        </Row>

                        {
                          this.isAgent() == false && (
                            <>
                              <hr />

                              <h3 className="mt-4 mb-4">Pay via customer's credit card</h3>

                              <SlideDownTransition open={!this.state.paymentTransactionId}>
                                <PaymentForm
                                  amount={(parseFloat(customerTotal)).toFixed(2)}
                                  status={this.state.paymentSubmitting ? 'busy' : (this.state.step4PaymentSuccess ? 'success' : '')}
                                  error={this.state.paymentErrorMessage}

                                  onSubmit={this.handlePaymentFormSubmit}
                                />
                              </SlideDownTransition>

                              <SlideDownTransition open={this.state.paymentTransactionId}>
                                <Alert variant="success"><i className="fad fa-check mr-2" />Thank you!</Alert>
                              </SlideDownTransition>
                            </>
                          )
                        }
                      </Panel>


                        <Panel>
                          <h2 className="mt-0"><i className={Settings.icons.objectTypes.product + ' mr-1'} /> Issue bond</h2>
                          {
                            this.isAgent() ? (
                              <big>Issuing this bond will charge your agency: <big className="badge badge-warning">{withdrawalAmount ? '$' + withdrawalAmount : 'N/A'}</big></big>
                            ) : (
                              <big>ACH sweep report will show charge for: <big className="badge badge-warning">{withdrawalAmount ? '$' + withdrawalAmount : 'N/A'}</big></big>
                            )
                          }

                          <hr />

                          <SlideDownTransition open={!this.shippingOK()}>
                            <Alert variant="info"><i className="fad fa-exclamation-triangle mr-2" />Please select shipping method/address to continue</Alert>
                          </SlideDownTransition>
                          <SlideDownTransition open={!this.paymentOK()}>
                            <Alert variant="info"><i className="fad fa-exclamation-triangle mr-2" />Please charge customer to continue</Alert>
                          </SlideDownTransition>

                          <SlideDownTransition open={this.isReadyToIssue()}>
                            <p>
                              <button className="btn btn-lg btn-primary" onClick={this.handleIssueBond}>Issue bond</button>
                            </p>
                          </SlideDownTransition>
                        </Panel>
                    </Col>
                    <Col xs={5}>
                      <img src={`${Settings.API_URL}/api/v1/bond-records/${bondRecord.id}/files/public/${bondRecord.pdf_bond_sample_file_id}?access_key=${bondRecord.access_key}&format=pdf`} width="100%" height="auto" />
                    </Col>
                  </Row>
                </Container>
              </>
            )
          }
        </PageContentWrapper>
      </>
    );
  }
}

export default BondRecordIssuancePage;