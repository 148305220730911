import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import FieldGroup from './FieldGroup';

class ObjectTypeDataViewerFieldGroup extends React.Component {
  render() {
    let props = {...this.props, viewmode: true};

    return <FieldGroup {...props} />
  }
}

export default ObjectTypeDataViewerFieldGroup;