import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from '../../../components/lib/thss/Panel';

import { MultiSelect } from 'primereact/multiselect';

class MgasFilter extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange = (mgas) => {
    if (this.props.onChange) this.props.onChange(mgas);
  }

  render() {
    if (!this.props.mgas) {
      return '';
    }

    return (
      <>
        {
          this.props.label && <p className="mt-0 mb-1 text-muted"><small>{this.props.label}</small></p>
        }

        <MultiSelect
          optionLabel='label'
          optionValue='value'
          placeholder="—"
          value={this.props.selected}
          options={this.props.mgas ? this.props.mgas.map((mga) => {
            return {
              label: mga.business_name,
              value: mga.id
            };
          }) : []}
          filter={false}
          maxSelectedLabels={0}
          selectedItemTemplate={(option) => option ? option + ', ' : ''}
          selectedItemsLabel={(() => {
            const MAX_SHOWN = 10;

            if (!this.props.selected || !this.props.selected.length) {
              return 'Select MGA';
            }

            if (this.props.selected && this.props.mgas && this.props.selected.length == this.props.mgas.length) {
              return this.props.allLabel || 'All MGAs';
            }

            let label = '';
            for (let i=0; i<this.props.selected.length; i++) {
              const id = this.props.selected[i];
              const elem = this.props.mgas.find((elem) => elem.id == id)

              if (i < MAX_SHOWN) {
                if (i) label += ', ';
                label += elem ? elem.business_name : '';
              } else {
                label += ' + ' + (this.props.selected.length - MAX_SHOWN) + ' more...';

                break;
              }
            };


            return label;
          })()}
          onChange={(e) => this.handleChange(e.value)}

          style={{width: '100%'}}
        />
      </>
    );
  }
}

export default MgasFilter;