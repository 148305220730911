import React from 'react';

import { Container, Row, Col, Alert } from 'react-bootstrap';
import Panel from './lib/thss/Panel';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: ''
    };
  }

  render() {
    return (
      <>
        {
          this.state.error ? (
            <Alert variant="danger">
              <i className="fa fa-exclamation-triangle" /> {this.props.error}
            </Alert>
          ) : ''
        }

        <form className="form" method="POST" onSubmit={this.props.onSubmit}>
          <div className="form-group">
            <label html-for="username" className="text-left">Username:
              <input type="text" className="form-control mr-2" name="username" />
            </label>
            <br />
            <label html-for="password" className="text-left">Password:
              <input type="password" className="form-control mr-2" name="password" />
            </label>

            <div className="mt-2">
              <button type="submit" className="btn btn-primary"><i className="fa fa-arrow-right mr-1" /> Enter</button>
            </div>
          </div>
        </form>
      </>
    )
  }
}

export default LoginForm;