/* eslint-disable default-case */
// core
import React from 'react';
import { Link } from 'react-router-dom'

// settings + utils
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';

import { Container, Row, Col, Button, Alert, Card, Badge } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';

// lib
import NumberFormat from 'react-number-format';
import BaseDetailsPage from '../../pages/lib/BaseDetailsPage';
import Spinner from '../../components/lib/thss/Spinner';
import ValignMiddle from '../../components/lib/thss/ValignMiddle';

// shared
import PageHeaderWrapper from '../../components/shared/layout/PageHeaderWrapper';
import PageContentWrapper from '../../components/shared/layout/PageContentWrapper';
import PanelHeaderWrapper from '../../components/shared/layout/PanelHeaderWrapper';
import ButtonsToolbar from '../../components/shared/ButtonsToolbar';
import FiltersToolbar from '../../components/shared/filters/FiltersToolbar';

import MainHeading from '../../components/shared/MainHeading';
import FilterRestoreNotice from '../../components/shared/filters/FilterRestoreNotice';
import ListPageWrapper from '../../components/shared/ListPageWrapper';
import SlideDownTransition from '../../components/lib/thss/SlideDownTransition';

// page-specific/etc.
import BondForm from '../../components/bonds/bond-form/BondForm';
import Progress from '../../components/bonds/bond-form/Progress';
import BondPdfViewer from '../../components/bonds/BondPdfViewer';
import ResponsiveEmbed from 'react-responsive-embed';
import ErrorPanel from '../../components/shared/ErrorPanel';

class BondRatingPage extends BaseDetailsPage {
  constructor(props) {
    super(props);

    this.PDF_REFRESH_DELAY = 250;
    this.pdfRefreshTimeout = null;

    this.PRINCIPAL_TYPE_INDIVIDUAL = 'ind';
    this.PRINCIPAL_TYPE_BUSINESS = 'biz';

    const IS_PREVIEW = this.props.isPreview;

    this.state = {
      ...this.state,

      productFormSchema: null,

      busy: false,

      currBondFormStepIndex: 0,

      pdfFetching: false,

      bondFormSubmitting: false,
      bondFormSubmitted: false,

      skippedSteps: [],

      principalType: IS_PREVIEW ? this.PRINCIPAL_TYPE_INDIVIDUAL : '' // default to individual if previewing quote
    };

    this.__setFormData(this.getBondFormInitialData(), 'bond_record');
  }

  __init = (props, callback) => {
    this.fetchData(() => {
      this.__setStatus(this.STATUS_READY);
      if (callback) callback();
    });
  }

  fetchData = (callback) => {
    const afterProduct = (productId, defaultOptions, callback) => {
      let calls = [];
      let endpoint = '';
      let payload = {};

      // fetch data for specific product
      endpoint = Settings.API_URL + '/api/v1/products/' + productId;
      calls.push({
        method: 'GET',
        endpoint: endpoint,
        payload: {},
        options: {...defaultOptions},
        callback: (response, error) => {
          if (error || !response || !response.ok) {
            console.warn('WARNING: retrieving product failed (this might be a false alarm, though, will not throw error)', response); // TODO: why is this being called multiple times and fails?

            return;
          }

          this.__setPageData(response.data.product, 'product');
          this.__setStatus(this.STATUS_READY);

          this.fetchPdfSample();
        }
      });

      // fetch custom fields
      this.__setFormSchemaOverride(null, 'bond_mso_custom_fields', () => {
        endpoint = Settings.API_URL + '/api/v1/products/' + productId + '/forms/current/schema';
        calls.push({
          method: 'GET',
          endpoint: endpoint,
          payload: {},
          options: {...defaultOptions},
          callback: (response, error) => {
            if (error || !response || !response.ok) {
              console.error('Not OK FEESD', response);

              this.__setStatus(this.STATUS_ERROR);
              this.__setErrors([...this.__getErrors(), {
                message: response ? response.error : error
              }]);

              return;
            }

            this.__setFormSchemaOverride(response.data.schema, 'bond_mso_custom_fields');
          }
        });

        this.__fetchMultiple(calls, (responses, errors) => {
          if (!this.__hasErrors()) {
            if (callback) callback();
          }
        });
      });
    }

    this.__setStatus(this.STATUS_FETCHING);

    const productId = this.props.match.params.productId;
    const recordId = typeof this.props.match.params.recordId == 'undefined' ? '' : String(this.props.match.params.recordId).replace('-', '');

    const defaultOptions = {
      headers: {
        'Authorization': 'Bearer ' + Auth.getToken()
      }
    };

    if (!recordId) {
      // new bond record
        if (!productId) {
          throw Error('no product ID');
        }

        afterProduct(productId, defaultOptions);
    } else {
      // load existing bond record
      this.__fetch('GET', `${Settings.API_URL}/api/v1/bond-records/${recordId}`, {}, defaultOptions, (response, error) => {
        if (error || !response || !response.ok) {
          console.error('ERROR while trying to get application', error, response);

          this.__setStatus(this.STATUS_ERROR);
          this.__setErrors([...this.__getErrors(), {
            message: response ? response.error : error
          }]);

          return;
        }

        this.__setPageData(response.data.record, 'bond_record');

        const productId = response.data.record.product_id;
        afterProduct(productId, defaultOptions, callback);
      });
    }
  }

  getPDFSamplePayload = (product, formData) => {
    // const getPayloadValue = (formData, typeName, propName) => {
    //   if (!formData) {
    //     return '';
    //   }

    //   if (!formData || !formData[typeName]|| !formData[typeName][propName]) {
    //     return '';
    //   }

    //   return String(formData[typeName][propName]);
    // };

    // const productId = product.id;
    // let payload = {
    //   product_id: productId,
    //   bond_details: {
    //     applicant_first_name: getPayloadValue(formData, 'bond_applicant', 'first_name'),
    //     applicant_last_name: getPayloadValue(formData, 'bond_applicant', 'last_name'),
    //     applicant_name: String(getPayloadValue(formData, 'bond_applicant', 'first_name') + ' ' + getPayloadValue(formData, 'bond_applicant', 'middle_name') + ' ' + getPayloadValue(formData, 'bond_applicant', 'last_name')).replace('  ', ' ').trim()
    //   },
    //   form: {
    //     bond_name: product.name,
    //     bond_state: product.state.code,
    //     bond_term: String(this.getBondTerm()),

    //     bond_premium_amount: String(this.getBondPremiumAmount()),
    //     bond_penalty_amount: String(this.getBondPenaltyAmount()),
    //     bond_rate: String(this.getBondRate()),
    //     bond_number: String(this.getBondNumber()),

    //     applicant_name: String(getPayloadValue(formData, 'bond_applicant', 'first_name') + ' ' + getPayloadValue(formData, 'bond_applicant', 'middle_name') + ' ' + getPayloadValue(formData, 'bond_applicant', 'last_name')).replace('  ', ' ').trim(),
    //     applicant_first_name: getPayloadValue(formData, 'bond_applicant', 'first_name'),
    //     applicant_middle_name: getPayloadValue(formData, 'bond_applicant', 'middle_name'),
    //     applicant_last_name: getPayloadValue(formData, 'bond_applicant', 'last_name'),
    //     applicant_email: getPayloadValue(formData, 'bond_applicant', 'email'),
    //     applicant_phone: getPayloadValue(formData, 'bond_applicant', 'phone'),

    //     applicant_address_street: getPayloadValue(formData, 'bond_applicant', 'mailing_street'),
    //     applicant_address_city: getPayloadValue(formData, 'bond_applicant', 'mailing_city'),
    //     applicant_address_zip: getPayloadValue(formData, 'bond_applicant', 'mailing_zip'),
    //     applicant_address_county: '', // TODO: handle this
    //     applicant_address_state: getPayloadValue(formData, 'bond_applicant', 'mailing_state'),

    //     business_type: getPayloadValue(formData, 'bond_business', 'business_type_id'), // TODO: any way to get the name?
    //     business_name: getPayloadValue(formData, 'bond_business', 'business_name'),
    //     applicant_dba: getPayloadValue(formData, 'bond_business', 'business_dba'),

    //     obligee_name: getPayloadValue(formData, '', ''),
    //     obligee_address_zip: getPayloadValue(formData, '', ''),
    //     obligee_address_city: getPayloadValue(formData, '', ''),
    //     obligee_address_state: getPayloadValue(formData, '', ''),
    //     obligee_address_street: getPayloadValue(formData, '', ''),

    //     expiry_date: this.getExpirationDate(),
    //     effective_date: getPayloadValue(formData, 'bond_options', 'effective_date'),
    //     issue_date: null
    //   },

    //   draft: true,
    //   electronic: true
    // };

    // return payload;
  }

  fetchPdfSample = () => {
    // this.setState({
    //   pdfFetching: true
    // }, () => {
    //   const product = this.__getPageData('product');
    //   const formData = this.__getFormData('form_data_by_type');

    //   if (!product) {
    //     console.warn('No product while trying to get PDF sample', product);
    //     this.setState({
    //       pdfFetching: false
    //     });

    //     return;
    //   }

    //   const productId = product.id;
    //   const endpoint = 'https://gig-api-stage.ue.r.appspot.com/api/v1/products/01t2G000006XdnYQAS/render'; // Settings.API_URL + '/api/v1/samples';

    //   let payload = this.getPDFSamplePayload(product, formData);

    //   const options = {
    //     headers: {
    //       // 'Authorization': 'Bearer ' + Auth.getToken()
    //       'X-Auth-Key': '707aa0f6-e61f-466a-8879-3d7a003b17f9',
    //       'X-Auth-Secret': 'QDmrtTHgmpDzDm3PCn6qJSSNhaxTQFrB'
    //     }
    //   };

    //   this.__fetch('POST', endpoint, JSON.stringify(payload), options, (response, error) => {
    //     this.setState({
    //       pdfFetching: false
    //     });

    //     if (error || !response || !response.ok) {
    //       console.error('Not OK 643D', response);

    //       // this.__setStatus(this.STATUS_ERROR);
    //       // this.__setErrors([...this.__getErrors(), {
    //       //   message: response ? response.error : error
    //       // }]);

    //       return;
    //     }

    //     // save items
    //     this.__setPageData(response.data.sample, 'pdf_sample');
    //   });
    // });
  }

  // global bond record steps
  getCurrBondRecordStep = () => {
    return this.getCurrBondFormStep();
  }
  getNumBondRecordSteps = () => {
    return this.getNumBondFormSteps();
  }
  getBondProgress = () => {
    return Math.min(Math.ceil(this.getCurrBondRecordStep() / this.getNumBondRecordSteps() * 100), 100);
  }

  // bond form steps
  handlePrincipalTypeSelect = (principalType) => {
    const IS_PREVIEW = this.props.isPreview;

    const formDataByType = this.__getFormData('form_data_by_type');
    if (formDataByType) {
      formDataByType['bond_applicant'] = IS_PREVIEW ? this.getBondFormInitialData()['bond_applicant'] : null;
      formDataByType['bond_business'] = IS_PREVIEW ? this.getBondFormInitialData()['bond_business'] : null;
    }

    this.handleBondFormStepChange(0, () => {
      this.__setFormData(formDataByType, 'form_data_by_type', false, 0, false, () => {
        this.setState({
          principalType: principalType
        });
      });
    });
  }
  getNumBondFormSteps = () => {
    return 5;
  }
  getCurrBondFormStepIndex = () => {
    return this.state.currBondFormStepIndex;
  }
  getCurrBondFormStep = () => {
    return this.getCurrBondFormStepIndex() + 1;
  }

  // bond flags/calculated values
  getBondData = (propertyName, defaultValue) => {
    const bondRecord = this.__getPageData('bond_record');
    let value;

    if (bondRecord) {
      value = bondRecord[propertyName];
    } else {
      value = defaultValue;
    }

    return value;
  }
  isCreditRequired = () => {
    const creditBond = this.__getPageData('product') && this.__getPageData('product').credit_bond;

    return creditBond || this.getBondData('is_credit_required', false);
  }
  isBondUnderwriting = () => {
    return this.getBondData('is_underwriting', false);
  }
  getBondPenaltyAmount = () => {
    return this.getBondData('bond_penalty_amount', 0);
  }
  getBondPremiumAmount = () => {
    return this.getBondData('bond_premium_amount', 0);
  }
  getBondTerm = () => {
    return this.getBondData('standard_expiration_term_months', 0);
  }
  getExpirationDate = ()  => {
    return this.getBondData('expiration_date', '0000-00-00');
  }
  getBondRate = () => {
    return this.getBondData('bond_premium_percent_rate', 0);
  }
  getBondNumber = () => {
    return this.getBondData('bond_number', 'DRAFT');
  }

  // handle change/saving, etc.
  handleBondFormChange = (formData, typeName, numRequired=0) => {
    window.clearTimeout(this.pdfRefreshTimeout);

    let formDataByType = this.__getFormData('form_data_by_type') || {};
    formDataByType[typeName] = formData;

    this.__setFormData(formDataByType, 'form_data_by_type', false, 0, false, () => {
      this.pdfRefreshTimeout = window.setTimeout(() => {
        this.fetchPdfSample();
      }, this.PDF_REFRESH_DELAY);
    });
  }

  handleBondFormStepChange = (nextStepIndex, callback) => {
    const done = () => {
        this.setState({
          busy: false,

          currBondFormStepIndex: nextStepIndex
        }, () => {
          if (callback) callback();
        });
    };

    const currStepIndex = this.state.currBondFormStepIndex;

    if (nextStepIndex <= currStepIndex) {
      // going back to edit, no reason to update data
      done();

      return;
    }

    this.setState({
      busy: true
    }, () => {
      this.handleBondFormSave(false, () => {
        done();
      });
    });
  }

  handleBondFormStepSkip = (stepIndex) => {
    // find step options and reset data
    this.setState((prevState) => {
      prevState.skippedSteps.push(stepIndex);

      return prevState;
    });
  }
  handleBondFormStepRestore = (stepIndex) => {
    this.setState((prevState) => {
      prevState.skippedSteps = prevState.skippedSteps.filter(n => n != stepIndex);

      return prevState;
    });
  }

  handleBondFormStepSubmit = (stepIndex) => {
  }

  handleBondFormSave = (submit, callback) => {
    this.__setStatus(this.STATUS_READY);
    this.__setErrors([]);

    const formData = Object.assign({
      bond_applicant: null,
      bond_business: null,
      bond_risk_assessment_options: null,
      bond_mso_custom_fields: null,
      // bond_indemnitors_global_options: null,
      bond_indemnitor: [],
      bond_options: null,
    }, this.__getFormData('form_data_by_type'));

    submit && this.__setStatus(this.STATUS_FETCHING);

    const product = this.__getPageData('product');
    const productId = product.id;

    let method = '';
    let endpoint = '';

    const isQuote = Boolean(this.state.recordId) == false;

    if (isQuote) {
      method = 'POST';
      endpoint = submit ? Settings.API_URL + '/api/v1/bond-records' : Settings.API_URL + '/api/v1/quotes';
    } else {
      method = 'PUT';
      endpoint = Settings.API_URL + '/api/v1/bond-records/' + this.state.recordId;
    }

    // make sure indemnitors are always an array
    if (formData['bond_indemnitor'] && !Array.isArray(formData['bond_indemnitor'])) {
      formData['bond_indemnitor'] = [formData['bond_indemnitor']];
    }

    const bondPayload = {
      ref_code: 'SBM-AGENTS',

      product_id: productId,

      is_business: formData['bond_business'] !== null,

      form_data_by_type: formData,

      rate: true,
      apply: submit,
      docs: submit,

      bond_marketing_data: {}
    };

    // first, handle bond data
    this.__fetch(method, endpoint, JSON.stringify(bondPayload), {
      headers: {
        'Authorization': 'Bearer ' + Auth.getToken()
      }
    }, (response, error) => {
      const done = (response, error) => {
        if (!response) {
          console.error('no response (will dump respose/error)', response, error);

          return;
        }

        if (isQuote) {
          this.__setPageData(response.data.quote, 'bond_record');
        } else {
          this.__setPageData(response.data.record, 'bond_record');
        }

        submit && this.__setStatus(this.STATUS_SUCCESS);

        if (callback) callback();
      };

      if (error || !response || !response.ok) {
        this.__setStatus(this.STATUS_ERROR);
        this.__setErrors([...this.__getErrors(), {
          message: response ? response.error : error
        }]);

        done(response, error);

        return;
      }

      if (!this.state.recordId) {
        this.setState({
          recordId: isQuote ? '' : response.data.record.id
        }, () => {
          done(response, error);
        });
      } else {
        done(response, error);
      }
    });

    // if submitting, we're done...
    if (submit) {
      return;
    }

    // ...otherwise, create PDF preview
    const pdfPreviewPayload = {
      ...bondPayload,

      rate: false,
      apply: false,
      docs: true
    };

    this.setState({
      pdfFetching: true
    });

    this.__fetch(method, endpoint, JSON.stringify(pdfPreviewPayload), {
      headers: {
        'Authorization': 'Bearer ' + Auth.getToken()
      }
    }, (response, error) => {
      this.setState({
        pdfFetching: false
      });

      if (error || !response || !response.ok) {
        console.error('ERROR while getting PDF preview (will dump respose/error)', response, error);

        return;
      }

      this.__setPageData(response.data.bond_sample_pdf, 'bond_sample_pdf');
    });
  }

  handleBondFormSubmit = () => {
    this.setState({
      bondFormSubmitting: true
    }, () => {
      this.handleBondFormSave(true, () => {
        this.setState({
          bondFormSubmitting: false
        }, () => {
          // if errors, let user know
          if (this.__hasErrors()) {
            alert('Application has errors, cannot continue');
            return;
          }

          // otherwise, mark as submitted
          this.setState({
            bondFormSubmitted: true
          });
        });
      })
    })
  }

  render() {
    const { recordId, pageMode, productId } = this.props.match.params;
    const product = this.__getPageData('product');
    const sample = this.__getPageData('pdf_sample');
    const EZSBLIKE_FORM = true;
    const IS_PREVIEW = this.props.isPreview;

    let principalStepSettings = null;
    const tooltipComponent = (
      <p className="mt-2 mb-4">
        <Button variant="light" className="mr-2" onClick={(e) => this.handlePrincipalTypeSelect(this.PRINCIPAL_TYPE_BUSINESS)}><i className={`${Settings.icons.misc.business} mr-2`} />Business</Button>
        <Button variant="light" onClick={(e) => this.handlePrincipalTypeSelect(this.PRINCIPAL_TYPE_INDIVIDUAL)}><i className={`${Settings.icons.misc.person} mr-2`} />Individual</Button>
      </p>
    );

    switch (this.state.principalType) {
      case this.PRINCIPAL_TYPE_BUSINESS:
        principalStepSettings = {
          fetching: this.state.busy,
          optional: false,
          skip: this.state.skippedSteps.indexOf(1) != -1,

          title: 'Principal information (business)',
          introDescr: this.state.skippedSteps.indexOf(1) == -1 ? 'Enter the business name exactly how it needs to appear on the bond. DBA is only required if it is part of the official business name. Otherwise, you should leave this field blank.' : '',
          tooltipTitle: this.state.skippedSteps.indexOf(1) == -1 ? 'If applicable, enter business information' : 'This step has been skipped',
          tooltipText: this.state.skippedSteps.indexOf(1) == -1 ? 'Enter business information to continue. This information will also be used on the bond if needed.' : '',

          types: [
            {
              typeName: 'bond_business',
              formSchema: this.props.appData.objectTypesByName['bond_business'].schema,
              formLayout: this.props.appData.objectTypesByName['bond_business'].formLayout,
              formData: this.__getFormData('form_data_by_type') ? this.__getFormData('form_data_by_type')['bond_business'] : {}
            },
          ],
          adjustableQuantityTypeNames: [],
          adjustableQuantityLimit: 100,

          gtmTriggerName: '', // 'GA Event - Bond Step 1 Section 2/6'
        };
        break;
      case this.PRINCIPAL_TYPE_INDIVIDUAL:
        principalStepSettings = {
          fetching: this.state.busy,
          optional: false,
          skip: false,

          title: 'Principal information (individual)',
          introDescr: 'Enter the name exactly how it needs to appear on the bond.',
          tooltipTitle: 'Enter customer\'s personal information',
          tooltipText: 'To get started with your bond, enter the customer\'s personal information. This information will also be used on the bond if needed.',

          types: [
            {
              typeName: 'bond_applicant',
              formSchema: this.props.appData.objectTypesByName['bond_applicant'].schema,
              formLayout: this.props.appData.objectTypesByName['bond_applicant'].formLayout,
              formData: this.__getFormData('form_data_by_type') ? this.__getFormData('form_data_by_type')['bond_applicant'] : {}
            }
          ],
          adjustableQuantityTypeNames: [],
          adjustableQuantityLimit: 100,

          gtmTriggerName: '', // 'GA Event - Bond Step 1 Section 1/6',
        };
        break;
      default:
        principalStepSettings = {
          fetching: this.state.busy,
          optional: false,
          skip: false,

          title: 'Principal information',
          introDescr: '',
          tooltipTitle: 'Is this bond for a business or an individual?',
          tooltipText: '',
          tooltipComponent: tooltipComponent,

          types: [],
          adjustableQuantityTypeNames: [],
          adjustableQuantityLimit: 100,

          numRequiredOverride: !this.state.principalType ? 1 : 0,

          gtmTriggerName: '', // 'GA Event - Bond Step 1 Section 1/6',
        };

        // business info

    }

    return (
      <>
        <PageContentWrapper>
          <Container fluid>
            <Row>
              <Col xs={7}>
                <Panel>
                  <PanelHeaderWrapper sticky={true}>
                    <Container fluid className="pt-3 pb-3">
                      <h1>{IS_PREVIEW}</h1>
                      {
                        product && (
                          <>
                            <Row>
                              <Col xs={9}>
                                <MainHeading
                                  icon={Settings.icons.objectTypes.product}
                                  title={product.name}
                                  subtitle=""
                                  descr=""
                                />
                              </Col>
                              <Col xs={3}>
                                <ValignMiddle>
                                  <div className="text-right">
                                    {
                                      this.state.busy && <Spinner message="Working, please wait..." />
                                    }
                                  </div>
                                </ValignMiddle>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <p className="mt-2" style={{fontSize: '110%'}}>
                                  <span className="badge badge-pill badge-secondary mr-2"><i className="fa fa-flag-usa mr-2" />{product.state.name}</span>
                                  {product.obligee && <span className="badge badge-pill badge-secondary mr-2"><i className="fa fa-university mr-2" />{product.obligee.name}</span>}
                                  {<span className="badge badge-pill badge-secondary mr-2"><i className="fa fa-bolt mr-2" />Instant issue</span>}
                                </p>

                                <p>
                                  <big>{this.isCreditRequired() ? <Badge variant="warning"><i className="fad fa-exclamation-triangle mr-1" />credit required</Badge> : <Badge variant="success"><i className="fad fa-check mr-1" />no credit required</Badge>}</big>
                                  <big className="ml-3"><Badge variant={this.getBondPenaltyAmount() ? 'info' : 'secondary'}><i className="fad fa-dollar-sign mr-1" /><small style={{zoom: 0.85}}>penalty amount:</small> {this.getBondPenaltyAmount() ? <><NumberFormat value={this.getBondPenaltyAmount()} displayType={'text'} thousandSeparator={true} prefix={'$'} /></> : 'N/A'}</Badge></big>
                                  <big className="ml-3">{this.isBondUnderwriting() ? <Badge variant="warning"><i className="fad fa-exclamation-triangle mr-1" />underwriting</Badge> : ''}</big>
                                </p>
                              </Col>
                            </Row>
                          </>
                        )
                      }
                      {
                        this.__hasErrors() && (
                          <Row>
                            <Col xs={12}>
                              <ErrorPanel errors={this.__getErrors()} />
                            </Col>
                          </Row>
                        )
                      }
                    </Container>
                  </PanelHeaderWrapper>

                  {
                    product && this.__getFormSchema('bond_mso_custom_fields') && (
                      <>
                        {
                          this.state.bondFormSubmitted && (
                            <Alert variant="success">
                              <h2><i className="fad fa-check mr-1" />Thank you!</h2>
                              <p>Your application has been submitted and will be processed soon.</p>

                              <p><Link className="btn btn-success" to="/applications/">go to applications</Link></p>
                            </Alert>
                          )
                        }
                        {
                          !this.state.bondFormSubmitted && (
                            <>
                              {
                                this.state.currBondFormStepIndex === 0 && (
                                  <p className="text-right">
                                    { this.state.principalType == this.PRINCIPAL_TYPE_INDIVIDUAL && <Button variant="light" className="mr-2" onClick={(e) => this.handlePrincipalTypeSelect(this.PRINCIPAL_TYPE_BUSINESS)}><i className={`${Settings.icons.misc.business} mr-2`} />Switch to business principal</Button> }
                                    { this.state.principalType == this.PRINCIPAL_TYPE_BUSINESS && <Button variant="light" onClick={(e) => this.handlePrincipalTypeSelect(this.PRINCIPAL_TYPE_INDIVIDUAL)}><i className={`${Settings.icons.misc.person} mr-2`} />Switch to individual principal</Button> }
                                  </p>
                                )
                              }

                              <BondForm
                                product={{
                                  id: product.id,
                                  salesforce_id: product.salesforce_id,
                                  name: product.name
                                }}

                                steps={[
                                  principalStepSettings,

                                  // risk assessment
                                  {
                                    fetching: this.state.busy,
                                    optional: false,
                                    skip: false,

                                    title: 'History',
                                    introDescr: '',
                                    tooltipTitle: 'Please answer questions',
                                    tooltipText: 'These fields are required before we can quote your bond.',

                                    types: [
                                      {
                                        typeName: 'bond_risk_assessment_options',
                                        formSchema: this.props.appData.objectTypesByName['bond_risk_assessment_options'].schema,
                                        formLayout: this.props.appData.objectTypesByName['bond_risk_assessment_options'].formLayout,
                                        formData: this.__getFormData('form_data_by_type') ? this.__getFormData('form_data_by_type')['bond_risk_assessment_options'] : {}
                                      },
                                    ],
                                    adjustableQuantityTypeNames: [],
                                    adjustableQuantityLimit: 100,

                                    gtmTriggerName: '', // 'GA Event - Bond Step 1 Section 3/6'
                                  },

                                  // custom fields
                                  {
                                    fetching: this.state.busy,
                                    optional: false,
                                    skip: this.__getFormSchema('bond_mso_custom_fields').properties.length == 0,

                                    title: 'Bond-specific questions',
                                    introDescr: '',
                                    tooltipTitle: 'These fields are required before we can quote your bond',
                                    tooltipText: '',

                                    types: [
                                      {
                                        typeName: 'bond_mso_custom_fields',
                                        formSchema: this.__getFormSchema('bond_mso_custom_fields'),
                                        formLayout: this.props.customFieldsLayout != undefined ? this.props.customFieldsLayout : Object.keys(this.__getFormSchema('bond_mso_custom_fields').properties).map((key) => {
                                          return {
                                            [key]: {md: 12}
                                          };
                                        }),
                                        formData: this.__getFormData('form_data_by_type') ? this.__getFormData('form_data_by_type')['bond_mso_custom_fields'] : {}
                                      },
                                    ],
                                    adjustableQuantityTypeNames: [],
                                    adjustableQuantityLimit: 100,

                                    gtmTriggerName: '', // 'GA Event - Bond Step 1 Section 4/6'
                                  },

                                  // indemnitors
                                  {
                                    fetching: this.state.busy,
                                    optional: false,
                                    skip: this.isCreditRequired() == false,

                                    title: 'Indemnitors',
                                    introDescr: 'You must include all 10% or greater owners and their spouses as an Indemnitor. An indemnitor is anyone who is responsible for losses claimed on the bond.',
                                    tooltipTitle: this.isCreditRequired() ? 'Enter indemnitor information' : 'No action required, you can skip to the next step',
                                    tooltipText: this.isCreditRequired() ? 'By continuing, you are giving us permission to run the customer\'s credit for the purpose of quoting you a surety bond. This is done as a “soft” credit pull, and in most cases will not impact your credit score' : '',

                                    types: [
                                      // {
                                      //   typeName: 'bond_indemnitors_global_options',
                                      //   formSchema: this.props.appData.objectTypesByName['bond_indemnitors_global_options'].schema,
                                      //   formLayout: this.props.appData.objectTypesByName['bond_indemnitors_global_options'].formLayout,
                                      //   formData: this.__getFormData('form_data_by_type') ? this.__getFormData('form_data_by_type')['bond_indemnitors_global_options'] : {}
                                      // },
                                      {
                                        typeName: 'bond_indemnitor',
                                        formSchema: this.props.appData.objectTypesByName['bond_indemnitor'].schema,
                                        formLayout: this.props.appData.objectTypesByName['bond_indemnitor'].formLayout,
                                        formData: this.__getFormData('form_data_by_type') ? this.__getFormData('form_data_by_type')['bond_indemnitor'] : []
                                      }
                                    ],
                                    adjustableQuantityTypeNames: [
                                      'bond_indemnitor'
                                    ],
                                    adjustableQuantityLimit: 100,

                                    gtmTriggerName: '', // 'GA Event - Bond Step 1 Section 5/6'
                                  },

                                  // bond options
                                  {
                                    fetching: this.state.busy,
                                    optional: false,
                                    skip: false,

                                    title: 'Bond options',
                                    introDescr: '',
                                    tooltipTitle: 'Almost there! When should this bond start?',
                                    tooltipText: 'Date can be anything between today and 90 days from now.',

                                    types: [
                                      {
                                        typeName: 'bond_options',
                                        formSchema: this.props.appData.objectTypesByName['bond_options'].schema,
                                        formLayout: this.props.appData.objectTypesByName['bond_options'].formLayout,
                                        formData: this.__getFormData('form_data_by_type') ? this.__getFormData('form_data_by_type')['bond_options'] : {}
                                      },
                                    ],
                                    adjustableQuantityTypeNames: [],
                                    adjustableQuantityLimit: 100,

                                    gtmTriggerName: '', // 'GA Event - Bond Step 1 Section 6/6'
                                  }
                                ]}

                                customFieldsLayout={null}

                                initialData={this.__getPageData('bond_record') || this.getBondFormInitialData()}
                                skipTo={this.getBondFormSkipTo()}

                                wizard={EZSBLIKE_FORM}

                                onChange={this.handleBondFormChange}
                                onStepChange={this.handleBondFormStepChange}
                                onStepSubmit={this.handleBondFormStepSubmit}
                                onStepSkip={this.handleBondFormStepSkip}
                                onStepRestore={this.handleBondFormStepRestore}
                                onSubmit={this.handleBondFormSubmit}

                                submitting={this.state.bondFormSubmitting}
                                submitted={this.state.bondFormSubmitted}
                              />
                            </>
                          )
                        }
                      </>
                    )
                  }
                </Panel>
              </Col>
              <Col xs={5}>
                <div className="gig-admin-application-page__pdf-preview" style={this.state.pdfFetching ? {opacity: 0.35} : {}}>
                  <h2 className="gig-admin-section-title mt-0 mb-2">PDF preview {this.state.pdfFetching && '(updating)'}</h2>

                  {
                    this.__getPageData('bond_sample_pdf') && this.__getPageData('bond_sample_pdf').base64 ? (
                      <BondPdfViewer src={this.__getPageData('bond_sample_pdf') && this.__getPageData('bond_sample_pdf').base64 ? this.__getPageData('bond_sample_pdf').base64 : ''} />
                    ) : (
                      <>
                        { product && <img width="100%" src={product.bond_preview_url} /> }
                      </>
                    )
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </PageContentWrapper>
      </>
    );
  }

  getBondFormSkipTo = () => {
    const STEP_INDEX = 2;

    const IS_PREVIEW = this.props.isPreview;

    return !IS_PREVIEW ? 0 : STEP_INDEX;
  }
  getBondFormInitialData = () => {
    const IS_PREVIEW = this.props.isPreview;

    if (!IS_PREVIEW) {
      return {};
    }

    let initialData = {};
    const skipTo = this.getBondFormSkipTo();
    if (skipTo >= 0) {
      initialData['bond_applicant'] = {
        "first_name": "John",
        "middle_name": "",
        "last_name": "TEST",
        "email": "test@example.com",
        "phone": "555-555-5555",

        "mailing_street": "867 Peachtree St.",
        "mailing_city": "Atlanta",
        "mailing_state": "GA",
        "mailing_zip": "30308"
      };
    }
    if (skipTo >= 1) {
      initialData['bond_business'] = {
        "business_entity_type_id": "INC",
        "business_name": "\"TEST\" INVESTMENTS INC.",
        "business_est_year":1981,
        "business_fein_number": 12345,
        "phone": "555-555-5555",
        "email": "business@example.com",

        "mailing_street": "867 Peachtree St.",
        "mailing_city": "Atlanta",
        "mailing_state": "GA",
        "mailing_zip": "30308"
      };
    }
    if (skipTo >= 2) {
      initialData['bond_risk_assessment_options'] = {
        "is_felon": false,
        "has_previous_claim": false
      };
    }
    if (skipTo >=3) {
      initialData['bond_mso_custom_fields'] = {
        "principal_county":"Dolnośląskie",
        "drivers_license_number":"DLN-12345",
        "vehicle_year":1995,
        "vehicle_make":"Honda",
        "vehicle_identification":"VIN-12345",
        "vehicle_model":"Civic",
        "vehicle_value":20000,
        "vehicle_date_of_purchase":"2021-04-15",
        "state_of_titled_vehicle":"GA",
        "sellerdealers_full_name":"EMILIONO BROWN",
        "sellerdealers_street":"900 NW LOVEJOY ST",
        "sellerdealers_city":"PORTLAND",
        "sellerdealers_state":"GA",
        "sellerdealers_zip":"30500",
        "if_this_is_a_mobile_home":"5555555555",
        "reason_for_lack_of_title":"Title lost",
        "are_there_any_liens_filed":"N",
        "was_this_vehicle_acquired":"N",
        "has_an_insurance_company":"N",
        "is_this_for_a_mobile":"N",
        "do_you_have_a_bill_of_sale":"Y"
      };
    }
    if (skipTo >= 4) {
      // initialData['bond_indemnitors_global_options'] = {
      //   "us_citizens": true
      // };
      initialData['bond_indemnitor'] = [
        {
          "ownership_percentage":"100",
          "is_us_citizen":true,
          "is_felon":false,
          "has_previous_claim":false,
          "first_name":"Paul",
          "last_name":"Burnia",
          "phone":"5555555555",
          "email":"p.burnia@example.com",
          "mailing_street":"19103 TAJAUTA AVE",
          "mailing_city":"CARSON",
          "mailing_state":"CA",
          "mailing_zip":"97209",
          "ssn":"666390456",
          "credit_pull_authorized":true
        }
      ]
    }
    if (skipTo >= 5) {
      initialData['bond_options'] = {
        "effective_date": "2020-09-30"
      };
    }

    return initialData;
  }
}

export default BondRatingPage;