// core
import React from 'react';
import { Link } from 'react-router-dom'

// settings + utils
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';
import pluralize from 'pluralize';

import { Container, Row, Col, Button, Alert, Badge } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';

// lib
import BaseDetailsPage from '../../pages/lib/BaseDetailsPage';
import Spinner from '../../components/lib/thss/Spinner';

// shared
import PageHeaderWrapper from '../../components/shared/layout/PageHeaderWrapper';
import PanelHeaderWrapper from '../../components/shared/layout/PanelHeaderWrapper';
import PageContentWrapper from '../../components/shared/layout/PageContentWrapper';
import ButtonsToolbar from '../../components/shared/ButtonsToolbar';
import ValignMiddle from '../../components/lib/thss/ValignMiddle';
import FiltersToolbar from '../../components/shared/filters/FiltersToolbar';
import SlideDownTransition from '../../components/lib/thss/SlideDownTransition';
import MainHeading from '../../components/shared/MainHeading';
import ObjectTypePanel from '../../components/lib/thss/json-schema-form/ObjectTypePanel';
import FilterRestoreNotice from '../../components/shared/filters/FilterRestoreNotice';
import ListPageWrapper from '../../components/shared/ListPageWrapper';

// page-specific/etc.
import DataGrid from '../../components/lib/react-data-grid/lib/DataGrid';
import '../../components/lib/react-data-grid/dist/react-data-grid.css';
import Address from '../../components/shared/elems/Address';
import Person from '../../components/shared/elems/Person';

class MgasDetailsPage extends BaseDetailsPage {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state
    };
  }

  render() {
    return (
      <>
        <h1>MGA</h1>
      </>
    );
  }
}

export default MgasDetailsPage;