import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from '../../components/lib/thss/Panel';

import ResponsiveEmbed from 'react-responsive-embed';

class BondPdfViewer extends React.Component {
	constructor(props) {
		super(props);

		this.IFRAME_ID = 'gig-BondPdfViewer-' + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

		this.state = {
			x: 0,
			y: 0
		};
	}

  componentDidMount() {
		const wrapper = document.querySelector('.gig-admin-bond-pdf__viewer-wrapper');
		if (!wrapper) {
			return;
		}

		wrapper.addEventListener('mousemove', (e) => {
			this.handlePdfMouseMove(e);
		});
	}

	handleFrameLoad = () => {

	}

	handlePdfMouseMove = (e) => {
		if (!e) return;

		const x = (e.offsetX / window.outerWidth) * 100;
		const y = (e.offsetY / window.outerHeight) * 100;



		this.setState({
			x,
			y
		});
  }

  render() {
		return (
			<ResponsiveEmbed src={this.props.src} ratio='8.5:11' />
		);

    return (
      <div className="gig-admin-bond-pdf__viewer-wrapper">
				<div className="gig-admin-bond-pdf__viewer-mask"></div>

				<div className="gig-admin-bond-pdf__viewer-zoom-wrapper">
        	<iframe border="0" frameborder="0" src={this.props.src} className="gig-admin-bond-pdf__viewer-zoom" onLoad={this.handleFrameLoad} />
				</div>

        <iframe border="0" frameborder="0" className="gig-admin-bond-pdf__viewer-doc" src={this.props.src} />
      </div>
    );
  }
}

export default BondPdfViewer;