// TODO: is there a way/does it make sense to make these come from the API?

import Settings from './config.js';

/*
  types = object types, basically what's on the DB
  related = list of objects that are attached/related to the main object
  as for endpoints, if "has_many" you'll have "show" and "list", otherwise only "show"

  {id} gets replaced with the ID of the main object for that type
  in addition to {id}, {refId} gets replaced with the ID of the ref object in related objects (ex., for agency_address, {refId} will get replaced with the ID for the current agency)
*/

export default {
  agency: {
    type_name: 'agency',
    label: 'Agency',

    pages: {
      list: '/agencies/',
      view: '/agencies/{id}/',
      edit: '/agencies/{id}/',
      delete: ''
    },

    endpoints: {
      list: {
        url: `${Settings.API_URL}/api/v1/agencies`,
        data_key: 'agencies'
      },
      show: {
        url: `${Settings.API_URL}/api/v1/agencies/{id}`,
        data_key: 'agency'
      },
      update: {
        url: `${Settings.API_URL}/api/v1/agencies/{id}`,
        data_key: ''
      }
    },

    min: 0,
    max: null,

    instructions: '',
    warning: '',

    related: [
      {
        type_name: 'agency_address',
        label: 'Agency address',

        endpoints: {
          list: {
            url: `${Settings.API_URL}/api/v1/agencies/{refId}/addresses`,
            data_key: 'addresses'
          },
          show: {
            url: `${Settings.API_URL}/api/v1/agencies/{refId}/addresses/{id}`,
            data_key: 'address'
          },
          create: null,
          update: {
            url: `${Settings.API_URL}/api/v1/agencies/{refId}/addresses/{id}`,
            data_key: ''
          },
          delete: null
        },

        has_many: true,
        min: 1,
        max: 1,

        instructions: '',
        warning: ''
      },
    ]
  },
  // agencies
  // agency: {
  //   label: 'Agency',
  //   pages: {
  //     list: {
  //     view: `/agencies/{id}/`,
  //     edit: `/agencies/{id}/`,
  //     delete: ``,
  //   },
  //   endpoints: {
  //     list: {
  //       url: `${Settings.API_URL}/api/v1/agencies`,
  //       data_key: 'agencies'
  //     },
  //     show: {
  //       url: `${Settings.API_URL}/api/v1/agencies/{id}`,
  //       data_key: 'agency'
  //     },
  //     update: {
  //       url: `${Settings.API_URL}/api/v1/agencies/{id}`,
  //       data_key: ''
  //     }
  //   },
  //   related: [
      // agency_address: {
      //   label: 'Address',
      //   pages: []
      //   endpoints: {
      //     list: {
      //   url: `${Settings.API_URL}/api/v1/agencies/{refId}/addresses`,
      //       show: {
      //      url: `${Settings.API_URL}/api/v1/agencies/{refId}/addresses/{id}`,
      //     },
      //     related: []
      //   },
      //   agency_applicant: {
      //     label: 'Applicant',
      //     pages: []
      //     endpoints: {
      //       list: {
      //      url: `${Settings.API_URL}/api/v1/agencies/{refId}/applicants`,
      //       show: {
      //      url: `${Settings.API_URL}/api/v1/agencies/{refId}/applicants/{id}`,
      //     },
      //     related: []
      //   },
      //   agency_employee: {
      //     label: 'Employee',
      //     pages: []
      //     endpoints: {
      //       list: {
      //      url: `${Settings.API_URL}/api/v1/agencies/{refId}/employees`,
      //       show: {
      //      url: `${Settings.API_URL}/api/v1/agencies/{refId}/employees/{id}`,
      //     },
      //     related: []
      //   },
      //   agency_commission_rate: {
      //     label: 'Commission rate',
      //     pages: []
      //     endpoints: {
      //       list: {
      //    url: `${Settings.API_URL}/api/v1/agencies/{refId}/commissions`,
      //       show: {
      //    url: `${Settings.API_URL}/api/v1/agencies/{refId}/commissions/{id}`,
      //     },
      //     related: []
      //   },
      //   agency_licensed_state: {
      //     label: 'Licensed state',
      //     pages: []
      //     endpoints: {
      //       list: {
      //    url: `${Settings.API_URL}/api/v1/agencies/{refId}/states`,
      //       show: {
      //    url: `${Settings.API_URL}/api/v1/agencies/{refId}/states/{id}`,
      //     },
      //     related: []
      //   },
  //   ]
  // },

  /*
  // // MGAs
  // mga: {
  //   label: 'MGA',
  //   pages: {
  //     list: {
        url: `/mgas/`,
  //     view: `/mgas/{id}/`,
  //     edit: `/mgas/{id}/`,
  //     delete: ``,
  //   },
  //   endpoints: {
  //     list: {
        url: `${Settings.API_URL}/api/v1/mgas`,
  //     show: {
        url: `${Settings.API_URL}/api/v1/mgas/{id}`,
  //   },
  //   related: [
  //     'mga_address',
  //     'mga_applicant',
  //     'mga_employee',
  //     'mga_commission_rate',
  //     // 'mga_licensed_state'
  //   ]
  // },
  // mga_address: {
  //   label: 'Address',
  //   pages: []
  //   endpoints: {
  //     list: {
        url: `${Settings.API_URL}/api/v1/mgas/{refId}/addresses`,
  //     show: {
        url: `${Settings.API_URL}/api/v1/mgas/{refId}/addresses/{id}`,
  //   },
  //   related: []
  // },
  // mga_applicant: {
  //   label: 'Applicant',
  //   pages: []
  //   endpoints: {
  //     list: {
        url: `${Settings.API_URL}/api/v1/mgas/{refId}/applicants`,
  //     show: {
        url: `${Settings.API_URL}/api/v1/mgas/{refId}/applicants/{id}`,
  //   },
  //   related: []
  // },
  // mga_employee: {
  //   label: 'Employee',
  //   pages: []
  //   endpoints: {
  //     list: {
        url: `${Settings.API_URL}/api/v1/mgas/{refId}/employees`,
  //     show: {
        url: `${Settings.API_URL}/api/v1/mgas/{refId}/employees/{id}`,
  //   },
  //   related: []
  // },
  // mga_commission_rate: {
  //   label: 'Commission rate',
  //   pages: []
  //   endpoints: {
  //     list: {
        url: `${Settings.API_URL}/api/v1/mgas/{refId}/commissions`,
  //     show: {
        url: `${Settings.API_URL}/api/v1/mgas/{refId}/commissions/{id}`,
  //   },
  //   related: []
  // },
  */

  // bond records
  bond_record: {
    type_name: 'bond_record',
    label: 'Bond application',

    pages: {
      list: '/applications/',
      view: '/applications/{id}/',
      edit: '/applications/{id}/',
      delete: ''
    },

    endpoints: {
      list: {
        url: `${Settings.API_URL}/api/v1/bond-records`,
        data_key: 'records'
      },
      show: {
        url: `${Settings.API_URL}/api/v1/bond-records/{id}`,
        data_key: 'record'
      },
      update: {
        url: `${Settings.API_URL}/api/v1/bond-records/{id}`,
        data_key: ''
      }
    },

    min: 0,
    max: null,

    instructions: '',
    warning: '',

    related: [
      {
        type_name: 'bond_status',
        label: 'Status',

        has_many: false,
        min: 1,
        max: 1,

        endpoints: {
          show: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/status`,
            data_key: 'status'
          },
          create: null,
          update: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/status`,
            data_key: ''
          },
          delete: null
        }
      },
      {
        type_name: 'bond_applicant',
        label: 'Applicant/Owner',

        endpoints: {
          list: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/applicants`,
            data_key: 'applicants'
          },
          show: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/applicants/{id}`,
            data_key: 'applicant'
          },
          create: null,
          update: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/applicants/{id}`,
            data_key: ''
          },
          delete: null
        },

        has_many: true,
        min: 1,
        max: 1,

        instructions: '',
        warning: ''
      },
      {
        type_name: 'bond_business',
        label: 'Business',

        endpoints: {
          list: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/businesses`,
            data_key: 'businesses'
          },
          show: null,
          create: null,
          update: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/businesses/{id}`,
            data_key: ''
          },
          delete: null
        },

        has_many: true,
        min: 1,
        max: 1,

        instructions: '',
        warning: ''
      },
      {
        type_name: 'bond_risk_assessment_options',
        label: 'Risk assessment',

        endpoints: {
          list: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/options/risk`,
            data_key: 'options'
          },
          show: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/options/risk/{id}`,
            data_key: 'options'
          },
          create: null,
          update: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/options/risk/{id}`,
            data_key: ''
          },
          delete: null
        },

        has_many: true,
        min: 1,
        max: 1,

        instructions: '',
        warning: ''
      },
      // {
      //   type_name: 'bond_indemnitors_global_options',
      //   label: 'Indemnitor options',

      //   endpoints: {
      //     list: {
      //       url: `${Settings.API_URL}/api/v1/bond-records/{refId}/options/indemnitors`,
      //       data_key: 'options'
      //     },
      //     show: null,
      //     create: null,
      //     update: {
      //       url: `${Settings.API_URL}/api/v1/bond-records/{refId}/options/indemnitors/{id}`,
      //       data_key: ''
      //     },
      //     delete: null
      //   },

      //   has_many: true,
      //   min: 1,
      //   max: 4,

      //   instructions: '',
      //   warning: ''
      // },
      {
        type_name: 'bond_indemnitor',
        label: 'Indemnitors',

        endpoints: {
          list: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/indemnitors`,
            data_key: 'indemnitors'
          },
          show: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/indemnitors/{id}`,
            data_key: 'indemnitors'
          },
          create: null,
          update: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/indemnitors/{id}`,
            data_key: ''
          },
          delete: null
        },

        has_many: true,
        min: 1,
        max: 4,

        instructions: '',
        warning: ''
      },
      {
        type_name: 'bond_rating_details',
        label: 'Rating details',

        endpoints: {
          list: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/rating`,
            data_key: 'details'
          },
          show: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/rating/{id}`,
            data_key: 'details'
          },
          create: null,
          update: null,
          delete: null
        },

        has_many: true,
        min: 1,
        max: 1,

        instructions: '',
        warning: ''
      },
      {
        type_name: 'bond_mso_custom_fields',
        label: 'Custom fields',
        pages: [],
        endpoints: {
          list: {// TODO: update endpoint for bond_mso_custom_fields
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/related/bond_mso_custom_fields`,
            data_key: 'bond_mso_custom_fields'
          },
          show: null,
          create: null,
          update: {// TODO: update endpoint for bond_mso_custom_fields
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/related/bond_mso_custom_fields/{id}`,
            data_key: ''
          },
          delete: null
        },

        has_many: true,
        min: 1,
        max: 1,

        instructions: '',
        warning: ''
      },
      {
        type_name: 'bond_public_file',
        label: 'Public files',

        endpoints: {
          list: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/files/public?sort_by=created_at&sort_dir=DESC`,
            data_key: 'files'
          },
          show: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/files/public/{id}`,
            data_key: 'file'
          },
          create: null,
          update: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/files/public/{id}`,
            data_key: ''
          },
          delete: null
        },

        has_many: true,
        min: 1,
        max: 100,

        instructions: '',
        warning: ''
      },
      {
        type_name: 'bond_private_file',
        label: 'Private files',

        endpoints: {
          list: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/files/private`,
            data_key: 'files'
          },
          show: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/files/private/{id}`,
            data_key: 'file'
          },
          create: null,
          update: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/files/private/{id}`,
            data_key: ''
          },
          delete: null
        },

        has_many: true,
        min: 1,
        max: 100,

        instructions: '',
        warning: ''
      },
      {
        type_name: 'bond_public_note',
        label: 'Public notes',

        endpoints: {
          list: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/notes/public`,
            data_key: 'notes'
          },
          show: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/notes/public/{id}`,
            data_key: 'note'
          },
          create: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/notes/public`,
            data_key: ''
          },
          update: null,
          delete: null
        },

        has_many: true,
        min: 1,
        max: 100,

        instructions: '',
        warning: ''
      },
      {
        type_name: 'bond_private_note',
        label: 'Private notes',

        endpoints: {
          list: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/notes/private`,
            data_key: 'notes'
          },
          show: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/notes/private/{id}`,
            data_key: 'note'
          },
          create: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/notes/private`,
            data_key: ''
          },
          update: null,
          delete: null
        },

        has_many: true,
        min: 1,
        max: 100,

        instructions: '',
        warning: ''
      },

      {
        type_name: 'bond_assignment',
        label: 'Assignments',

        endpoints: {
          list: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/assignments`,
            data_key: 'assignments'
          },
          show: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/assignments`,
            data_key: 'assignments'
          },
          create: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/assignments`,
            data_key: ''
          },
          update: null,
          delete: null
        },

        has_many: true,
        min: 1,
        max: 100,

        instructions: '',
        warning: ''
      },

      // bond_options: {
      //   label: 'Bond options',
      //   pages: []
      //   endpoints: {
      //     list: {
      //       url: `${Settings.API_URL}/api/v1/bond-records/{refId}/subtype-data/bond_options`,
      //       data_key: '',
      //     },
      //     show: {
      //       url: `${Settings.API_URL}/api/v1/bond-records/{refId}/subtype-data/bond_options/{id}`,
      //       data_key: ''
      //     }
      //   },
      //   related: []
      // },
      {
        type_name: 'bond_shipping_options',
        label: 'Shipping options',

        endpoints: {
          list: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/shipping`,
            data_key: 'options'
          },
          show: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/shipping/{id}`,
            data_key: 'options'
          },
          create: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/shipping`,
            data_key: ''
          },
          update: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/shipping/{id}`,
            data_key: ''
          },
          delete: null
        },

        has_many: true,
        min: 0,
        max: 1,

        instructions: '',
        warning: ''
      },
      {
        type_name: 'bond_payment',
        label: 'Payment',

        endpoints: {
          list: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/payments`,
            data_key: 'payments'
          },
          show: null,
          create: {
            url: `${Settings.API_URL}/api/v1/bond-records/{refId}/offline-payments`
          },
          update: null,
          delete: null
        },

        has_many: true,
        min: 1,
        max: 100,

        instructions: '',
        warning: ''
      },
      // {
      //   type_name: 'bond_log_entry',
      //   label: 'BondRecord files/uploads',

      //   endpoints: {
      //     list: {
      //       url: `${Settings.API_URL}/api/v1/bond-records/{refId}/related/bond_log_entry`,
      //       data_key: 'bond_log_entry'
      //     },
      //     show: {
      //       url: `${Settings.API_URL}/api/v1/bond-records/{refId}/related/bond_log_entry/{id}`,
      //       data_key: 'file'
      //     },
      //     create: null,
      //     update: null,
      //     delete: null
      //   },

      //   has_many: true,
      //   min: 1,
      //   max: 100,

      //   instructions: '',
      //   warning: ''
      // },
    ]
  }
};



























