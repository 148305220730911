import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from '../../lib/thss/Panel';

class Progress extends React.PureComponent {
  render() {
    const { percentage, currStep, numSteps, colors } = this.props;

    return (
      <div className="progress mt-0 mb-4" style={{position: 'relative', backgroundColor: colors.lighter}}>
        <div className="progress-bar" role="progressbar" style={{width: percentage + '%', backgroundColor: colors.primary}}></div>
        <span style={{position: 'absolute', right: '7px', lineHeight: '1.2em'}}>
          {
            percentage !== 0 && (
              percentage === 100 ? <span style={{color: '#fff'}}>Your bond is ready to be issued!</span> : <span className="animated fadeInRight">Step {currStep} of {numSteps}</span>
            )
          }
        </span>
      </div>
    );
  }
}

export default Progress;