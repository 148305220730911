import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Link
} from 'react-router-dom';

// settings + utils
import Settings from './config.js';
import formLayoutsByType from './config.form_layouts.js';
import typesInfo from './config.types_info.js';
// import CSV from './utils/CSV.js';
import Auth from './services/Auth.js';
import XHR from './utils/XHR';

import { Container, Row, Col } from 'react-bootstrap';

import Panel from './components/lib/thss/Panel';

import { Alert } from 'react-bootstrap';

// base components
import Spinner from './components/lib/thss/Spinner';
import LoginForm from './components/LoginForm';
import SharedPageLayout from './components/shared/layout/SharedPageLayout';
import ValignMiddle from './components/lib/thss/ValignMiddle';

// pages
import Homepage from './pages/home/Homepage';

import ProductListPage from './pages/products/ProductListPage';

import BondRatingPage from './pages/bonds/BondRatingPage';
import BondRecordListPage from './pages/bonds/BondRecordListPage';
import BondRecordDetailsPage from './pages/bonds/BondRecordDetailsPage';
import BondRecordIssuancePage from './pages/bonds/workflows/BondRecordIssuancePage';

import AgenciesListPage from './pages/agencies/AgenciesListPage';
import AgenciesDetailsPage from './pages/agencies/AgenciesDetailsPage';

import MgasListPage from './pages/mgas/MgasListPage';
import MgasDetailsPage from './pages/mgas/MgasDetailsPage';

import TasksPage from './pages/tasks/TasksPage';

import InventoryPage from './pages/inventory/InventoryPage';

import InquiriesListPage from './pages/inquiries/InquiriesListPage';

import ReportsPage from './pages/reports/ReportsPage';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.CACHE_APP_DATA = false;

    this.state = {
      userRetrieved: false,
      userRetrievedError: '',

      currUser: null,
      loginFormError: '',

      permissionsRetrieved: false,
      permissionsRetrievedError: '',

      objectTypesRetrieved: false,
      objectTypesRetrievedError: '',

      statesRetrieved: false,
      statesRetrievedError: '',

      productTypesRetrieved: false,
      productTypesRetrievedError: '',

      bondRecordTypesRetrieved: false,
      bondRecordTypesRetrievedError: '',

      shippingMethodsRetrieved: false,
      shippingMethodsRetrievedError: '',

      bondStatusOptionsRetrieved: false,
      bondStatusOptionsRetrievedError: '',

      appData: {
        objectTypes: [],
        objectTypesByName: {},
        objectTypesOptions: {},

        permissions: [],
        permissionsById: {},

        states: [],
        statesByCode: {},

        productTypes: [],
        productTypesById: {},

        bondRecordTypes: [],
        bondRecordTypesById: {},
        bondRecordTypesByName: {},

        shippingMethods: [],
        shippingMethodsById: {},

        bondStatusGroups: [],
        bondStatusGroupsByName: {},
        bondStatuses: [],
        bondStatusesByName: {}
      },
    };
  }

  componentWillMount() {
    // fetch user + data that needs permissions
    this.fetchCurrUser((user) => {
      if (user == null) {
        console.error('No user returned');

        return;
      }

      this.afterSetUser();
    });

    // fetch anything else below
    this.fetchStates();
    this.fetchProductTypes();
    this.fetchBondRecordTypes();
    this.fetchShippingMethods();
    this.fetchoBondStatusOptions();
  }

  afterSetUser = () => {
    this.fetchObjectTypes();
    this.fetchPermissions();
  }

  fetchCurrUser = (callback) => {
    this.setState({
      fetchingUser: true,
      userRetrievedError: ''
    }, () => {
      return XHR.fetch('GET', Settings.API_URL + '/api/v1/auth/sessions/current', {}, {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken()
        }
      }, (response, error) => {
        if (error) {
          this.setState({
            fetchingUser: false,
            userRetrievedError: 'Cannot log in. Please check your username/password'
          });

          console.error('Connection error while trying to get user');

          Auth.setToken('');

          return callback(null);
        }

        if (!response || response.ok == false) {
          let msg = response === null ? 'no response (connection problem?)!' : response.error;
          console.error(msg);

          Auth.setToken('');

          return callback(null);
        } else {
          const user = response.ok && response.data.user ? response.data.user : null;
          const authData = response.ok && response.data.auth_data ? response.data.auth_data : null;
          const userInfo = {
            id: authData.id,
            username: authData.username,

            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,

            agency_id: user.agency_id || null
          };

          // set user, and only consider fetching done after user is saved to state
          this.setCurrUser(userInfo, () => {
            this.setState({
              fetchingUser: false,
              userRetrieved: true
            }, () => {
              if (callback) return callback(user);
            });
          });
        }
      });
    });
  }

  setCurrUser = (user, callback) => {
    this.setState({
      currUser: user
    }, () => {
      if (callback) return callback();
    });
  }

  getCurrUser = () => {
    return this.state.currUser;
  }

  handleLoginFormSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    const username = form.elements.username.value;
    const password = form.elements.password.value;

    this.setCurrUser(null);

    this.setState({
      userRetrieved: true,
      userRetrievedError: ''
    }, () => {
      if (!username || !password) {
        this.setState({
          loginFormError: 'Username or password missing'
        });

        return false;
      }

      XHR.fetch('POST', Settings.API_URL + '/api/v1/auth/sessions', JSON.stringify({
        username,
        password
      }), {}, (response, error) => {
        this.setState({
          userRetrieved: false
        }, () => {
          if (error || !response || !response.ok) {
            this.setState({
              loginFormError: 'Cannot login, please check your username/password (' + (error ? (error.message ? error.message : error) : 'generic error/no description') + ')'
            });

            return false;
          }

          const token = response.data.access_token;

          // save access token into window.localStorage so that API util can use it
          Auth.setToken(token);

          // refresh page to apply changes
          window.location.reload();
        });
      });
    });
  }

  fetchObjectTypes = () => {
    this.setState((prevState) => {
      prevState.objectTypesRetrievedError = '';

      return prevState;
    }, () => {
      const callback = (types) => {
        this.setState((prevState) => {
          // add data and group by name
          const __getTypeDataByName = (typeName, types, typesInfo) => {
            const type = types.find(type => type.name == typeName);
            if (!type) {
              throw Error(`Cannot find type: ${typeName}`);
            }

            let typeData = {
              name: typeName,
              label: typesInfo[typeName] && typesInfo[typeName].label || `Type #${typeName}`,
              endpoints: typesInfo[typeName] && typesInfo[typeName].endpoints || {},
              pages: typesInfo[typeName] && typesInfo[typeName].pages || [],
              schema: type.schema,
              formLayout: null,
              related: []
            };

            // add layout
            let formLayout = [];
            if (formLayoutsByType[type.name]) {
              formLayout = formLayoutsByType[type.name];
            } else {
              formLayout = {...type.schema.properties};
              Object.keys(type.schema.properties).forEach((propertyName) => {
                formLayout[propertyName] = {
                  xs: 12
                };
              });
            }
            typeData.formLayout = formLayout;

            // hydrate options for related types
            if (typesInfo[typeName] && typesInfo[typeName].related && typesInfo[typeName].related.length) {
              typeData.related = typesInfo[typeName].related.map((options) => {
                const typeName = options.type_name;

                const typeData =  __getTypeDataByName(typeName, types, typesInfo);

                options.schema = typeData.schema;
                options.formLayout = typeData.formLayout;

                return options;
              });
            }

            return typeData;
          };

          let typesByName = {};
          types.forEach((type) => {
            const typeName = type.name;
            const typeData = __getTypeDataByName(typeName, types, typesInfo);

            // add to array
            typesByName[typeName] = typeData;
          });

          prevState.appData.objectTypes = Object.values(typesByName);
          prevState.appData.objectTypesByName = typesByName;

          return prevState;
        }, () => {
          this.setState({
            objectTypesRetrieved: true
          }, () => {
            // console.warn('Object types READY', this.state.appData.objectTypes, JSON.stringify(this.state.appData.objectTypes));
          });
        });
      };

      const OBJECT_TYPES_CACHE_KEY = 'gig:cache:object-types-' + ((new Date()).getFullYear() + '-' + ((new Date()).getMonth() + 1) + '-' + ((new Date()).getDate()));

      window.ldb.get(OBJECT_TYPES_CACHE_KEY, (types) => {
        if (types && this.CACHE_APP_DATA) {
          callback(types);
        } else {
          XHR.fetch('GET', Settings.API_URL + '/api/v1/system/types?offset=0&limit=-1&t=' + (new Date()).getTime(), {}, {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken()
            }
          }, (response, error) => {
            if (error) {
              this.setState((prevState) => {
                prevState.objectTypesRetrievedError = error.message + ' while getting object types';

                return prevState;
              });

              return false;
            }

            if (!response || !response.ok) {
              console.error('cannot get types (response not OK)', response);

              return false;
            }

            // do the stuff
            const types = response.data.types;
            callback(types);

            // save to cache for later use
            window.ldb.set(OBJECT_TYPES_CACHE_KEY, types);
          });
        }
      });
    });
  }

  fetchPermissions = () => {
    this.setState({
      permissionsRetrievedError: ''
    }, () => {
      XHR.fetch('GET', Settings.API_URL + '/api/v1/auth/sessions/current/permissions', {}, {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken()
        }
      }, (response, error) => {
        if (error || !response.data.permissions) {
          this.setState({
            permissionsRetrievedError: '×',

            error: 'Error getting permissions' + (error ? ': ' + error.message : '')
          });

          return false;
        }

        this.setState((prevState) => {
          prevState.appData.permissions = response.data.permissions;

          return prevState;
        }, () => {
          this.setState({
            permissionsRetrieved: true
          });
        });
      });
    });

    return [];
  }

  fetchStates = () => {
    this.setState((prevState) => {
      prevState.statesRetrievedError = '';

      return prevState;
    }, () => {
      const callback = (states) => {
        let statesByCode = {};
        states.forEach((state) => {
          statesByCode[state.code] = state;
        });

        this.setState((prevState) => {
          prevState.appData.states = states;
          prevState.appData.statesByCode = statesByCode;

          return prevState;
        }, () => {
          this.setState({
            statesRetrieved: true
          });
        });
      };

      const STATES_CACHE_KEY = 'gig:cache:states-' + ((new Date()).getFullYear() + '-' + ((new Date()).getMonth() + 1) + '-' + ((new Date()).getDate()));

      window.ldb.get(STATES_CACHE_KEY, (states) => {
        if (states && this.CACHE_APP_DATA) {
          callback(states);
        } else {
          const endpoint = Settings.API_URL + '/api/v1/states';
          XHR.fetch('GET', endpoint + '?offset=0&limit=-1&t=' + (new Date()).getTime(), {}, {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken()
            }
          }, (response, error) => {
            if (error) {
              this.setState((prevState) => {
                prevState.statesRetrievedError = error.message + ' while getting states';

                return prevState;
              });

              return false;
            }

            if (!response || !response.ok) {
              console.error('cannot get states');

              return false;
            }

            // do the stuff
            const states = response.data.states;
            callback(states);

            // save to cache for later use
            window.ldb.set(STATES_CACHE_KEY, states);
          });
        }
      });
    });
  }

  fetchProductTypes = () => {
    this.setState((prevState) => {
      prevState.productTypesRetrievedError = '';

      return prevState;
    }, () => {
      let callback = (types) => {
        let productTypesById = {};
        types.forEach((type) => {
          productTypesById[type.id] = type;
        });

        this.setState((prevState) => {
          prevState.appData.productTypes = types;
          prevState.appData.productTypesById = productTypesById;

          return prevState;
        }, () => {
          this.setState({
            productTypesRetrieved: true
          });
        });
      };

      const PRODUCT_TYPES_CACHE_KEY = 'gig:cache:product-types-' + ((new Date()).getFullYear() + '-' + ((new Date()).getMonth() + 1) + '-' + ((new Date()).getDate()));

      window.ldb.get(PRODUCT_TYPES_CACHE_KEY, (types) => {
        if (types && this.CACHE_APP_DATA) {
          callback(types);
        } else {
          const endpoint = Settings.API_URL + '/api/v1/product-types';
          XHR.fetch('GET', endpoint + '?offset=0&limit=-1&t=' + (new Date()).getTime(), {}, {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken()
            }
          }, (response, error) => {
            if (error) {
              this.setState((prevState) => {
                prevState.productTypesRetrievedError = error.message + ' while getting product types';

                return prevState;
              });

              return false;
            }

            if (!response || !response.ok) {
              console.error('cannot get product types');

              return false;
            }

            // do the stuff
            const types = response.data.types;
            callback(types);

            // save to cache for later use
            window.ldb.set(PRODUCT_TYPES_CACHE_KEY, types);
          });
        }
      });
    });
  }

  fetchBondRecordTypes = () => {
    this.setState((prevState) => {
      prevState.bondRecordTypesRetrievedError = '';

      return prevState;
    }, () => {
      let callback = (types) => {
        let bondRecordTypesById = {};
        let bondRecordTypesByName = {};

        types.forEach((type) => {
          bondRecordTypesById[type.id] = type;
          bondRecordTypesByName[type.name] = type;
        });

        this.setState((prevState) => {
          prevState.appData.bondRecordTypes = types;
          prevState.appData.bondRecordTypesById = bondRecordTypesById;
          prevState.appData.bondRecordTypesByName = bondRecordTypesByName;

          return prevState;
        }, () => {
          this.setState({
            bondRecordTypesRetrieved: true
          });
        });
      };

      const BOND_RECORD_TYPES_CACHE_KEY = 'gig:cache:bond-record-types-' + ((new Date()).getFullYear() + '-' + ((new Date()).getMonth() + 1) + '-' + ((new Date()).getDate()));

      window.ldb.get(BOND_RECORD_TYPES_CACHE_KEY, (types) => {
        if (types && this.CACHE_APP_DATA) {
          callback(types);
        } else {
          const endpoint = Settings.API_URL + '/api/v1/bond-record-types';
          XHR.fetch('GET', endpoint + '?offset=0&limit=-1&t=' + (new Date()).getTime(), {}, {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken()
            }
          }, (response, error) => {
            if (error) {
              this.setState((prevState) => {
                prevState.bondRecordTypesRetrievedError = error.message + ' while getting item types';

                return prevState;
              });

              return false;
            }

            if (!response || !response.ok) {
              console.error('cannot get item types');

              return false;
            }

            // do the stuff
            const types = response.data.types;
            callback(types);

            // save to cache for later use
            window.ldb.set(BOND_RECORD_TYPES_CACHE_KEY, types);
          });
        }
      });
    });
  }

  fetchShippingMethods = () => {
    this.setState((prevState) => {
      prevState.shippingMethodsRetrievedError = '';

      return prevState;
    }, () => {
      const callback = (shippingMethods) => {
        let shippingMethodsById = {};
        shippingMethods.forEach((shippingMethod) => {
          shippingMethodsById[shippingMethod.id] = shippingMethod;
        });

        this.setState((prevState) => {
          prevState.appData.shippingMethods = shippingMethods;
          prevState.appData.shippingMethodsById = shippingMethodsById;

          return prevState;
        }, () => {
          this.setState({
            shippingMethodsRetrieved: true
          });
        });
      };

      const SHIPPING_METHODS_CACHE_KEY = 'gig:cache:shipping-methods-' + ((new Date()).getFullYear() + '-' + ((new Date()).getMonth() + 1) + '-' + ((new Date()).getDate()));

      window.ldb.get(SHIPPING_METHODS_CACHE_KEY, (shippingMethods) => {
        if (shippingMethods && this.CACHE_APP_DATA) {
          callback(shippingMethods);
        } else {
          const endpoint = Settings.API_URL + '/api/v1/shipping-methods';
          XHR.fetch('GET', endpoint, {}, {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken()
            }
          }, (response, error) => {
            if (error) {
              this.setState((prevState) => {
                prevState.shippingMethodsRetrievedError = error.message + ' while getting shipping methods';

                return prevState;
              });

              return false;
            }

            if (!response || !response.ok) {
              console.error('cannot get shipping methods');

              return false;
            }

            // do the stuff
            const shippingMethods = response.data.methods;
            callback(shippingMethods);

            // save to cache for later use
            window.ldb.set(SHIPPING_METHODS_CACHE_KEY, shippingMethods);
          });
        }
      });
    });
  }

  fetchoBondStatusOptions = () => {
    this.setState((prevState) => {
      prevState.bondStatusOptionsRetrievedError = '';

      return prevState;
    }, () => {
      const callback = (bondStatusGroups) => {
        let bondStatusGroupsByName = {};
        let bondStatuses = [];
        let bondStatusesByName = {};

        bondStatusGroups.forEach((bondStatusGroup) => {
          bondStatusGroupsByName[bondStatusGroup.name] = bondStatusGroup;

          const typeStatuses = bondStatusGroup.statuses;
          bondStatuses = bondStatuses.concat(typeStatuses);
          typeStatuses.forEach((typeStatus) => {
            bondStatusesByName[typeStatus.name] = typeStatus;
          })
        });

        this.setState((prevState) => {
          prevState.appData.bondStatusGroups = bondStatusGroups;
          prevState.appData.bondStatusGroupsByName = bondStatusGroupsByName;

          prevState.appData.bondStatuses = bondStatuses;
          prevState.appData.bondStatusesByName = bondStatusesByName;

          return prevState;
        }, () => {
          this.setState({
            bondStatusOptionsRetrieved: true
          });
        });
      };

      const BOND_RECORD_STATUS_OPTIONS_CACHE_KEY = 'gig:cache:bond-status-options-' + ((new Date()).getFullYear() + '-' + ((new Date()).getMonth() + 1) + '-' + ((new Date()).getDate()));

      window.ldb.get(BOND_RECORD_STATUS_OPTIONS_CACHE_KEY, (bondStatusGroups) => {
        if (bondStatusGroups && this.CACHE_APP_DATA) {
          callback(bondStatusGroups);
        } else {
          const endpoint = Settings.API_URL + '/api/v1/bond-statuses/groups';
          XHR.fetch('GET', endpoint, {}, {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken()
            }
          }, (response, error) => {
            if (error) {
              this.setState((prevState) => {
                prevState.bondStatusOptionsRetrievedError = error.message + ' while getting bond record statuses';

                return prevState;
              });

              return false;
            }

            if (!response || !response.ok) {
              console.error('cannot get bond record statuses');

              return false;
            }

            // do the stuff
            const bondStatusGroups = response.data.groups;
            callback(bondStatusGroups);

            // save to cache for later use
            window.ldb.set(BOND_RECORD_STATUS_OPTIONS_CACHE_KEY, bondStatusGroups);
          });
        }
      });
    });
  }

  render() {
    const spinner = <big><Spinner message="Working..." /></big>;

    // If no user logged in, stop and return form to log in
    const currUser = this.getCurrUser();
    if (!currUser) {
      return (
        <div style={{height: '100vh'}}>
          <ValignMiddle className="text-center">
            {
              this.state.fetchingUser ? (
                <>{spinner}</>
              ) : (
                <Container fluid>
                  <Row>
                    <Col xs={4} className="offset-4 text-center">
                      <h1><i className="fad fa-user-secret" style={{color: '#444'}} /></h1>

                      {
                        this.state.loginFormError && <Alert as="p" variant="danger"><i className="fa fa-exclamation-triangle" /> ERROR: {this.state.loginFormError}</Alert>
                      }

                      <LoginForm error={this.state.loginFormError} onSubmit={this.handleLoginFormSubmit} />
                    </Col>
                  </Row>
                </Container>
              )
            }
          </ValignMiddle>
        </div>
      );
    }

    // if retrieving app data, return warning that we're fetching that
    if (
      !this.state.statesRetrieved ||
      !this.state.productTypesRetrieved ||
      !this.state.bondRecordTypesRetrieved ||
      !this.state.shippingMethodsRetrieved ||
      !this.state.bondStatusOptionsRetrieved ||
      !this.state.objectTypesRetrieved ||
      !this.state.permissionsRetrieved
    ) {
      return (
        <div style={{height: '100vh'}}>
          <ValignMiddle>
            {spinner}
          </ValignMiddle>
        </div>
      );
    }

    // if all data is ready, return routes and pass both user and app data
    return (
      <Router>
        <SharedPageLayout
          user={currUser}
          appData={this.state.appData}

          isAgentsPortal={Settings.IS_AGENTS_PORTAL}
        >
          {/* Homepage */}
          <Route
            exact path="/"
            render={(props) => (
              <>
                {
                  Settings.IS_AGENTS_PORTAL ? (
                    <Homepage
                      {...props}

                      user={currUser}
                      appData={this.state.appData}
                    />
                  ) : (
                    <Redirect to="/applications/" />
                  )
                }
              </>
            )}
          />

          {/* Tasks */}
          <Route
            exact path="/tasks"
            render={(props) => (
              <TasksPage
                {...props}
                user={currUser}
                appData={this.state.appData}
              />
            )}
          />

          {/* Bond quotes */}
          <Route
            exact path="/bonds/:productId/quotes/:recordId/:pageMode"
            render={(props) => (
              <BondRatingPage
                {...props}
                user={currUser}
                appData={this.state.appData}
              />
            )}
          />
          <Route
            exact path="/bonds/:productId/preview"
            render={(props) => (
              <BondRatingPage
                {...props}
                user={currUser}
                appData={this.state.appData}
                isPreview={true}
              />
            )}
          />

          {/* Bond issue/underwriting, etc. */}
          <Route
            exact path="/issuances/:recordId"
            render={(props) => (
              <BondRecordIssuancePage
                {...props}
                user={currUser}
                appData={this.state.appData}
              />
            )}
          />

          {/* BondRecords */}
          <Route
            exact path="/applications"
            render={(props) => (
              <BondRecordListPage
                {...props}
                user={currUser}
                appData={this.state.appData}
              />
            )}
          />
          <Route
            exact path="/applications/:id"
            render={(props) => (
              <BondRecordDetailsPage
                {...props}
                user={currUser}
                appData={this.state.appData}
              />
            )}
          />

          {/* Products */}
          <Route
            exact path="/bonds"
            render={(props) => (
              <ProductListPage
                {...props}
                user={currUser}
                appData={this.state.appData}
              />
            )}
          />
          <Route
            exact path="/bonds/find"
            render={(props) => (
              <ProductListPage
                {...props}
                user={currUser}
                appData={this.state.appData}
              />
            )}
          />

          {/* Inventory */}
          <Route
            exact path="/inventory"
            render={(props) => (
              <InventoryPage
                {...props}
                user={currUser}
                appData={this.state.appData}
              />
            )}
          />

          {/* Agencies */}
          <Route
            exact path="/agencies"
            render={(props) => (
              <AgenciesListPage
                {...props}

                user={currUser}
                appData={this.state.appData}
              />
            )}
          />
          <Route
            path="/agencies/:id"
            render={(props) => (
              <AgenciesDetailsPage

                {...props}

                user={currUser}
                appData={this.state.appData}
              />
            )}
          />

          {/* MGAs */}
          <Route
            exact path="/mgas"
            render={(props) => (
              <MgasListPage
                {...props}

                typeName="mga"

                user={currUser}
                appData={this.state.appData}
              />
            )}
          />
          <Route
            path="/mgas/:id"
            render={(props) => (
              <MgasDetailsPage
                {...props}

                user={currUser}
                appData={this.state.appData}
              />
            )}
          />

          {/* Customer support/inquiries */}
          <Route
            exact path="/inquiries"
            render={(props) => (
              <InquiriesListPage
                {...props}
                user={currUser}
                appData={this.state.appData}
              />
            )}
          />

          {/* Reports */}
          <Route
            exact path="/reports"
            render={(props) => (
              <ReportsPage
                {...props}
                user={currUser}
                appData={this.state.appData}
              />
            )}
          />
        </SharedPageLayout>
      </Router>
    );
  }
}

export default App;