// core
import React from 'react';
import { Link } from 'react-router-dom'

// settings + utils
import moment from 'moment';
import Settings from '../../config.js';
import Auth from '../../services/Auth.js';
import pluralize from 'pluralize';

import { Container, Row, Col, Button, Alert, Badge, Tooltip, Form } from 'react-bootstrap';
import Panel from '../../components/lib/thss/Panel';
import ProfilePicture from '../../components/shared/elems/ProfilePicture';

// lib
import NumberFormat from 'react-number-format';
import Spinner from '../../components/lib/thss/Spinner';
import ObjectTypeItemsPanel from '../../components/lib/thss/json-schema-ui/ObjectTypeItemsPanel';

// shared
import PageHeaderWrapper from '../../components/shared/layout/PageHeaderWrapper';
import PanelHeaderWrapper from '../../components/shared/layout/PanelHeaderWrapper';
import PageContentWrapper from '../../components/shared/layout/PageContentWrapper';
import ButtonsToolbar from '../../components/shared/ButtonsToolbar';
import ValignMiddle from '../../components/lib/thss/ValignMiddle';
import FiltersToolbar from '../../components/shared/filters/FiltersToolbar';
import SlideDownTransition from '../../components/lib/thss/SlideDownTransition';
import MainHeading from '../../components/shared/MainHeading';
import ObjectTypePanel from '../../components/lib/thss/json-schema-form/ObjectTypePanel';
import FilterRestoreNotice from '../../components/shared/filters/FilterRestoreNotice';
import ListPageWrapper from '../../components/shared/ListPageWrapper';

// page-specific/etc.
import DataGrid from '../../components/lib/react-data-grid/lib/DataGrid';
import '../../components/lib/react-data-grid/dist/react-data-grid.css';

import ErrorPanel from '../../components/shared/ErrorPanel';

import Person from '../../components/shared/elems/Person';
import Business from '../../components/shared/elems/Business';

import Note from '../../components/shared/elems/Note';
import AddBondNoteForm from '../../components/bonds/AddBondNoteForm';

export default class BondNotesPanel extends React.PureComponent {
  render() {
    const { typeData, data, formData, focus, fetching, saving, success, error, onToggleEdit, onChange, onSave, user, permissions, onShowBondChange } = this.props;

    if (fetching) {
      return '';
    }
    if (!typeData) {
      return <ErrorPanel errors={[{message: 'No type data'}]} />
    }

    const typeName = typeData.type_name;


    // get basic info about type
    const label = typeData.label;
    const schema = typeData.schema;
    const layout = typeData.formLayout;
    const min = typeData.min;
    const max = typeData.max;

    return (
      <>
        <ObjectTypeItemsPanel
          className={this.props.className || ''}
          style={this.props.style || {}}

          headerRenderer={(title) => {
            return (
              <h2 className="m-0 my-2">
                <div style={{display: 'inline-block', width: '1.5em'}}><i className={Settings.icons.objectTypes.notes + ' text-muted'} />
                  { typeName == 'bond_private_note' && <span className="mr-2" style={{zoom: 0.6, marginLeft: '-0.75em'}}><i className="fad fa-lock " /></span> }
                </div>

                {title}

                {/* <span className="sbm-bond__include-bond-checkbox float-right">
                  <Form.Check.Input id={typeName + '_includeBondData'}
                    type="checkbox"
                    checked={!!this.props.showBondData}
                    onChange={(e) => onShowBondChange(e.target.checked)} />

                  <Form.Check.Label for={typeName + '_includeBondData'}>Show all</Form.Check.Label>
                </span> */}
              </h2>
            )
          }}

          typeName={typeName}
          label={label}
          schema={schema}
          layout={layout}

          data={data}
          formData={formData}

          min={min}
          max={max}
          expandAdd={true}

          focus={focus}

          fetching={fetching}
          saving={saving}
          success={success}
          error={error}

          itemRenderer={(myTypeName, mySchema, myLayout, myItemData, isEditing, isFetching) => {
            if (!permissions.view) {
              return <p></p>; {/* just to make it look good */}
            }

            if (!myItemData) {
              console.warn('WARNING: no item data while getting sidebar item');

              return <p>WARNING: no item data while getting sidebar item</p>;
            }

            return <Note data={myItemData} />
          }}

          formRenderer={(typeName, schema, layout, formData, itemId, onChange, onSubmit, saving, success, error) => {
            return (
              <>
                {
                  permissions.create ? (
                    <AddBondNoteForm typeName={typeName} saving={saving} success={success} error={error} onChange={onChange} onSubmit={(formData, typeName) => onSave(typeName, itemId)} user={this.props.user} />
                  ) : (
                    <p><i className="fad fa-info mr-2" />NOTE: you don't have permission to {permissions.view ? 'create' : 'view/create'} {label.toLowerCase()}.</p>
                  )
                }
              </>
            );
          }}

          onToggleEdit={onToggleEdit}
          onChange={onChange}
          onSave={onSave}
        />
      </>
    )
  }
}