import React from 'react';

import { Container, Row, Col, Button, Badge} from 'react-bootstrap';
import ValignMiddle from '../../../components/lib/thss/ValignMiddle';

class Address extends React.PureComponent {
  render() {
    const { data } = this.props;

    return (
      <div>
        <h4 className="mt-0 mb-0">{data.mailing_street}</h4>
        <p className="mt-0 mb-1">{data.mailing_city} {data.mailing_zip} {data.mailing_state}</p>
      </div>
    )
  }
}

export default Address;